import React, { FC } from 'react';

export const IconEdit: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.90063 11.2317H12.4006" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      // eslint-disable-next-line max-len
      d="M9.65063 1.14825C9.89375 0.905135 10.2235 0.768555 10.5673 0.768555C10.7375 0.768555 10.9061 0.802086 11.0634 0.867234C11.2207 0.932383 11.3636 1.02787 11.484 1.14825C11.6043 1.26863 11.6998 1.41154 11.765 1.56882C11.8301 1.7261 11.8637 1.89468 11.8637 2.06492C11.8637 2.23516 11.8301 2.40373 11.765 2.56101C11.6998 2.7183 11.6043 2.86121 11.484 2.98158L3.84508 10.6205L1.40063 11.2316L2.01175 8.78714L9.65063 1.14825Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
