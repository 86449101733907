import React, { FC } from 'react';

export const IconSend: FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  //@ts-ignore slot prop required by market, but not recognized by react type
  <svg slot="icon" className={className} width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3525 1H16.9996V6.64706" stroke="currentColor" />
    <path d="M6.64746 11.3529L17.0004 1" stroke="currentColor" strokeLinejoin="bevel" />
    <path fillOpacity={0} d="M9.47059 2.88232H1V16.5294H15.1176V8.52938" stroke="currentColor" />
  </svg>
);
