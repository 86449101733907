import { range } from 'lodash/fp';
import { StoreDateRangeDto } from '@goparrot/store-v2-sdk';

import { RangeDate } from '../constants';
import { getHour } from './getHour';
import { getMins } from './getMins';

export const getDisabledHours = (dataset?: StoreDateRangeDto[], index?: number, field?: string) => () => {
  let result: number[] = [];
  if (!dataset || index === undefined || field === undefined) return result;
  const currItem = dataset[index];
  const prevItem = dataset[index - 1];
  const nextItem = dataset[index + 1];
  // start time for current time interval
  if (RangeDate.FROM === field) {
    result = result.concat(range(getHour(currItem.to) + 1, 24));
    // if has previous interval, disable start hours for current from 0 to previous interval end time
    if (true === !!prevItem) {
      const startRange = 59 === getMins(prevItem.to) ? getHour(prevItem.to) + 1 : getHour(prevItem.to);
      result = result.concat(range(0, startRange));
    }
  }
  // end time for current time interval
  if (RangeDate.TO === field) {
    result = result.concat(range(0, getHour(currItem.from)));
    // if has next interval, disable end hours for current from next interval start time to 24
    if (true === !!nextItem) {
      const startRange = 0 === getMins(nextItem.from) ? getHour(nextItem.from) : getHour(nextItem.from) + 1;
      result = result.concat(range(startRange, 24));
    }
  }
  return result;
};
