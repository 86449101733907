import React, { FC } from 'react';
import BaseSelect, { ActionMeta, NamedProps, OptionTypeBase, GroupTypeBase } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { BaseSelectComponents } from './BaseSelectComponents';
import { OutlineSelectComponents } from './OutlineSelectComponents';
import { SelectValueType, SelectOptionType, SelectOptionGroupType } from './types';
import './Select.css';

type AsyncSelectProps = AsyncProps<SelectOptionType>;
type BaseSelectProps = Pick<NamedProps<SelectOptionType, boolean>, Exclude<keyof NamedProps, 'value' | 'onChange' | 'isDisabled'>>;
type Variant = 'base' | 'outline';

export type SelectProps = {
  // base - to be used within FormField
  // outline - for standalone usage
  variant?: Variant;
  // used to display the text above selected options in multiselect mode
  // defaults to "{options.length} options selected"
  renderMultiValueLabel?: (options: SelectOptionType[]) => string;
  value?: SelectValueType | SelectValueType[];
  onChange(value: SelectValueType | SelectValueType[] | null, actionMeta?: ActionMeta<OptionTypeBase>): void;
  selectedValuesMaxCount?: number;
  noContainerPadding?: boolean;
  isAsync?: boolean;
  placeholderColor?: string;
  selectedValueColor?: string;
  appendIcon?: React.ReactElement;
  overrideComponents?: Record<string, unknown>;
  staticDropdown?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  controlHeight?: number;
  groupOptions?: SelectOptionGroupType[];
} & AsyncSelectProps &
  BaseSelectProps;

const componentsMapping: Record<Variant, unknown> = {
  base: BaseSelectComponents,
  outline: OutlineSelectComponents,
};
export const Select: FC<React.PropsWithChildren<SelectProps>> = ({
  variant = 'base',
  renderMultiValueLabel = (options) => `${options?.length} options selected`,
  value,
  isAsync = false,
  overrideComponents,
  onChange,
  menuPosition = 'fixed',
  menuPlacement = 'auto',
  menuShouldScrollIntoView = true,
  staticDropdown = false,
  readOnly = false,
  closeMenuOnScroll = true,
  menuShouldBlockScroll = true,
  controlHeight = 45,
  disabled,
  placeholderColor,
  selectedValueColor,
  appendIcon,
  menuPortalTarget,
  groupOptions,
  ...props
}) => {
  const baseProps: any = {
    theme: ({
      borderRadius,
      colors,
      spacing: { baseUnit, menuGutter },
    }: {
      borderRadius?: string;
      colors?: string;
      spacing: { baseUnit?: string; menuGutter?: string; controlHeight?: string };
    }) => ({
      borderRadius,
      colors,
      spacing: {
        baseUnit,
        controlHeight,
        menuGutter,
      },
    }),
    renderMultiValueLabel,
    menuPosition,
    menuPlacement,
    menuShouldScrollIntoView,
    staticDropdown,
    readOnly,
    closeMenuOnScroll,
    menuShouldBlockScroll,
    isDisabled: disabled,
    placeholderColor,
    selectedValueColor,
    appendIcon,
    menuPortalTarget,
  };
  if (props.isMulti) {
    const displayValues = value
      ? (value as SelectValueType[]).map((valueItem: SelectValueType) => ({
          value: valueItem,
          label: groupOptions
            ? props.options?.map((option) => (option as GroupTypeBase<SelectOptionType>).options.find((item) => item.value === valueItem)?.label)
            : props.options?.find((option) => option.value === valueItem)?.label || valueItem,
        }))
      : null;
    baseProps.value = displayValues;
    baseProps.onChange = (selectedOptions: SelectOptionType[], actionMeta: ActionMeta<OptionTypeBase>) =>
      onChange(
        selectedOptions.map((option) => option.value),
        actionMeta,
      );
    baseProps.hideSelectedOptions = props?.hideSelectedOptions ?? false;
  } else {
    const displayValue =
      value !== undefined && value !== null
        ? props.options?.find((option) => option.value === value) || {
            label: value,
            value: value,
          }
        : null;
    baseProps.value = displayValue;
    baseProps.onChange = (selectedOption: SelectOptionType) => (selectedOption ? onChange(selectedOption.value) : onChange(null));
  }
  const Component: any = isAsync ? AsyncSelect : BaseSelect;

  return <Component {...baseProps} components={overrideComponents ?? componentsMapping[variant]} {...props} />;
};
