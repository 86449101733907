import React, { FC } from 'react';

import { IconProps } from './type';

export const IconArrowTopRight: FC<React.PropsWithChildren<IconProps>> = ({ className = '' }) => (
  //@ts-ignore slot prop required by market, but not recognized by react type
  <svg slot="icon" className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.05881 3H8.99998V7.94118" stroke="currentColor" />
    <path d="M9 3L2 10" stroke="currentColor" strokeLinejoin="bevel" />
  </svg>
);
