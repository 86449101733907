import React, { FC } from 'react';
import { IconProps } from './type';

export const IconWarningLarge: FC<IconProps> = ({ className = '', width = 34, height = 30 }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 11.5H18.5V19.7499H15.5V11.5Z" fill="currentColor" />
    <path
      // eslint-disable-next-line max-len
      d="M17 24.9999C18.0355 24.9999 18.875 24.1604 18.875 23.1249C18.875 22.0893 18.0355 21.2499 17 21.2499C15.9645 21.2499 15.125 22.0893 15.125 23.1249C15.125 24.1604 15.9645 24.9999 17 24.9999Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M18.299 1.26904C18.0311 0.804941 17.5359 0.519043 17 0.519043C16.4641 0.519043 15.9689 0.804941 15.701 1.26904L0.700962 27.2498C0.433013 27.7139 0.433013 28.2857 0.700962 28.7498C0.968911 29.2139 1.4641 29.4998 2 29.4998H32C32.5359 29.4998 33.0311 29.2139 33.299 28.7498C33.567 28.2857 33.567 27.7139 33.299 27.2498L18.299 1.26904ZM17 5.01904L29.4019 26.4998H4.59808L17 5.01904Z"
      fill="currentColor"
    />
  </svg>
);
