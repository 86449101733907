import React, { FC } from 'react';

export const IconTag: FC<{ className?: string }> = ({ className = '' }) => (
  // @ts-ignore slot attribute is required for market, but not part of the React svg type.

  <svg slot="icon" className={className} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.293 16.7071L7.29297 11.7071L8.70718 10.2929L13.7072 15.2929L12.293 16.7071Z" fill="black" fillOpacity="0.9" />
    <path
      d="M16 10C17.1046 10 18 9.10457 18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10Z"
      fill="black"
      fillOpacity="0.9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.5862 2H19.0004C20.6573 2 22.0004 3.34314 22.0004 5L22.0004 12.4128L12.0004 22.4606L1.58789 11.9983L11.5862 2ZM12.4146 4L4.41295 12.0017L12.0004 19.6254L20.0004 11.5872L20.0004 5C20.0004 4.44771 19.5527 4 19.0004 4H12.4146Z"
      fill="black"
      fillOpacity="0.9"
    />
  </svg>
);
