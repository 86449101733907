import React, { FC } from 'react';

export const IconPayment: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M7.33317 8.66667V6C7.33317 5.63333 7.03317 5.33333 6.6665 5.33333H3.99984V4H7.33317V2.66667H5.6665V2H4.33317V2.66667H3.33317C2.9665 2.66667 2.6665 2.96667 2.6665 3.33333V6C2.6665 6.36667 2.9665 6.66667 3.33317 6.66667H5.99984V8H2.6665V9.33333H4.33317V10H5.6665V9.33333H6.6665C7.03317 9.33333 7.33317 9.03333 7.33317 8.66667Z"
      fill="currentColor"
    />
    <path d="M13.06 8.34668L9.28663 12.1133L7.39996 10.2267L6.45996 11.1733L9.28663 14L14 9.28668L13.06 8.34668Z" fill="currentColor" />
  </svg>
);
