import React, { FC } from 'react';

export const IconNotifications: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M8.00018 14.6665C8.73352 14.6665 9.33352 14.0665 9.33352 13.3332H6.66685C6.66685 14.0665 7.26685 14.6665 8.00018 14.6665ZM12.0002 10.6665V7.33317C12.0002 5.2865 10.9135 3.57317 9.00018 3.11984V2.6665C9.00018 2.11317 8.55352 1.6665 8.00018 1.6665C7.44685 1.6665 7.00018 2.11317 7.00018 2.6665V3.11984C5.09352 3.57317 4.00018 5.27984 4.00018 7.33317V10.6665L2.66685 11.9998V12.6665H13.3335V11.9998L12.0002 10.6665ZM10.6668 11.3332H5.33352V7.33317C5.33352 5.67984 6.34018 4.33317 8.00018 4.33317C9.66018 4.33317 10.6668 5.67984 10.6668 7.33317V11.3332ZM5.05352 2.71984L4.10018 1.7665C2.50018 2.9865 1.44685 4.8665 1.35352 6.99984H2.68685C2.78685 5.23317 3.69352 3.6865 5.05352 2.71984ZM13.3135 6.99984H14.6468C14.5468 4.8665 13.4935 2.9865 11.9002 1.7665L10.9535 2.71984C12.3002 3.6865 13.2135 5.23317 13.3135 6.99984Z"
      fill="currentColor"
    />
  </svg>
);
