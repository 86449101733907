import { StoreDateRangeDto } from '@goparrot/store-v2-sdk';

import { ELEVEN_FIFTY_NINE_PM } from '../constants';
import { getHour } from './getHour';
import { getMins } from './getMins';

export const getDisabledAddTimeset = (dataset?: StoreDateRangeDto[], index?: number) => {
  if (!dataset || index === undefined) return false;

  const currItem = dataset[index];
  const nextItem = dataset[index + 1];
  const conditions = [
    ELEVEN_FIFTY_NINE_PM === currItem.to,
    true === !!nextItem && getHour(currItem.to) === getHour(nextItem.from) && 2 >= getMins(nextItem.from) - getMins(currItem.to),
    true === !!nextItem && 1 === getHour(nextItem.from) - getHour(currItem.to) && 0 === getMins(nextItem.from) && 58 <= getMins(currItem.to),
  ];
  return conditions.includes(true);
};
