import React, { FC } from 'react';

export const IconServiceCharges: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 12.6667H3.33333V3.33333H12.6667V12.6667Z"
      fill="currentColor"
    />
    <path d="M7.49984 5.14648H4.1665V6.14648H7.49984V5.14648Z" fill="currentColor" />
    <path d="M11.9998 10.5H8.6665V11.5H11.9998V10.5Z" fill="currentColor" />
    <path d="M11.9998 8.8335H8.6665V9.8335H11.9998V8.8335Z" fill="currentColor" />
    <path d="M5.33333 12.0002H6.33333V10.6668H7.66667V9.66683H6.33333V8.3335H5.33333V9.66683H4V10.6668H5.33333V12.0002Z" fill="currentColor" />
    <path
      // eslint-disable-next-line max-len
      d="M9.39319 7.3L10.3332 6.36L11.2732 7.3L11.9799 6.59333L11.0399 5.64667L11.9799 4.70667L11.2732 4L10.3332 4.94L9.39319 4L8.68652 4.70667L9.62652 5.64667L8.68652 6.59333L9.39319 7.3Z"
      fill="currentColor"
    />
  </svg>
);
