import React, { FC } from 'react';

export const IconPower: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M8.66667 2H7.33333V8.66667H8.66667V2ZM11.8867 3.44667L10.94 4.39333C11.9933 5.24 12.6667 6.54 12.6667 8C12.6667 10.58 10.58 12.6667 8 12.6667C5.42 12.6667 3.33333 10.58 3.33333 8C3.33333 6.54 4.00667 5.24 5.05333 4.38667L4.11333 3.44667C2.82 4.54667 2 6.17333 2 8C2 11.3133 4.68667 14 8 14C11.3133 14 14 11.3133 14 8C14 6.17333 13.18 4.54667 11.8867 3.44667Z"
      fill="currentColor"
    />
  </svg>
);
