import React, { FC } from 'react';

export const IconReportLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M21.004 6.61582C21.4355 6.58027 21.8716 6.5625 22.3124 6.5625C31.0077 6.5625 38.0624 13.6172 38.0624 22.3125C38.0624 31.0078 31.0077 38.0625 22.3124 38.0625C18.9758 38.0624 15.7253 37.0031 13.0293 35.0372C10.3333 33.0714 8.33087 30.3004 7.31055 27.1236"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21 3.9375C11.5763 3.9375 3.93752 11.5763 3.93752 21C3.93394 23.403 4.4397 25.7794 5.42146 27.9727L21 21V3.9375Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
