import React, { FC } from 'react';
import { Popover as KitPopover, PopoverDisclosure, PopoverOptions, PopoverStateReturn } from 'reakit/Popover';
import classNames from 'classnames';

export type PopoverProps = {
  content: JSX.Element | string;
  popoverState: PopoverStateReturn;
  ariaLabel?: string;
  disabled?: boolean;
  className?: string;
  noPadding?: boolean;
};

export const Popover: FC<React.PropsWithChildren<PopoverProps & PopoverOptions>> = ({
  content,
  popoverState,
  noPadding = false,
  ariaLabel,
  disabled,
  children,
  className,
  ...props
}) => {
  return (
    <>
      <PopoverDisclosure disabled={disabled} className={className} {...popoverState}>
        {children}
      </PopoverDisclosure>
      <KitPopover {...props} className="tw-z-10" {...popoverState} aria-label={ariaLabel} tabIndex={0}>
        <div className={classNames(['tw-bg-white  tw-rounded tw-border tw-border-blue-gray-100', noPadding ? 'tw-p-0' : 'tw-p-4'])}>{content}</div>
      </KitPopover>
    </>
  );
};
