import React from 'react';
import './ContentWrapper.css';
import classNames from 'classnames';

export type ContentWrapperProps = ContentWrapperInterface;
interface ContentWrapperInterface {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

export const ContentWrapper: React.FC<React.PropsWithChildren<ContentWrapperInterface>> = ({ children, className, testId }): React.ReactElement => {
  return (
    <main data-testid={testId} className={classNames(['content-wrapper tw-bg-white', className])}>
      {children}
    </main>
  );
};
