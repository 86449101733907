import React, { FC } from 'react';

export const IconMoreHorizontal: FC<React.PropsWithChildren<unknown>> = () => (
  // @ts-ignore slot attribute is required for market, but not part of the React svg type.
  <svg slot="icon" width="17" height="3" viewBox="0 0 17 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
    <circle cx="8.5" cy="1.5" r="1.5" fill="currentColor" />
    <circle cx="15.5" cy="1.5" r="1.5" fill="currentColor" />
  </svg>
);
