import React, { FC } from 'react';

export const IconHome: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M7.00016 1.79333L10.3335 4.79333V10H9.00016V6H5.00016V10H3.66683V4.79333L7.00016 1.79333ZM7.00016 0L0.333496 6H2.3335V11.3333H6.3335V7.33333H7.66683V11.3333H11.6668V6H13.6668L7.00016 0Z"
      fill="currentColor"
    />
  </svg>
);
