import * as React from 'react';
import { IconProps } from './type';

export const IconWarningCircle: React.FC<React.PropsWithChildren<IconProps>> = ({ width = 18, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9 18C13.96 18 18 13.96 18 9C18 4.04 13.96 0 9 0C4.04 0 0 4.04 0 9C0 13.96 4.04 18 9 18ZM9 2C12.86 2 16 5.14 16 9C16 12.86 12.86 16 9 16C5.14 16 2 12.86 2 9C2 5.14 5.14 2 9 2ZM9 14C9.69036 14 10.25 13.4404 10.25 12.75C10.25 12.0596 9.69036 11.5 9 11.5C8.30964 11.5 7.75 12.0596 7.75 12.75C7.75 13.4404 8.30964 14 9 14ZM8 4H10V10.5H8V4Z"
      fill="currentColor"
    />
  </svg>
);
