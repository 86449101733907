import React, { FC } from 'react';

export const IconMegaphoneLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M9.1875 13.1251V23.6251M33.4638 4.28379C33.4638 4.28379 26.3566 13.1251 19.6875 13.1251H6.5625C6.2144 13.1251 5.88056 13.2634 5.63442 13.5095C5.38828 13.7557 5.25 14.0895 5.25 14.4376V22.3126C5.25 22.6607 5.38828 22.9945 5.63442 23.2407C5.88056 23.4868 6.2144 23.6251 6.5625 23.6251H19.6875C26.3566 23.6251 33.4638 32.5034 33.4638 32.5034C33.9609 33.1596 35.4375 32.7101 35.4375 31.697V5.08605C35.4375 4.07625 34.043 3.54222 33.4638 4.28379ZM5.25 21.0001C5.25 21.0001 3.9375 20.5079 3.9375 18.3751C3.9375 16.2423 5.25 15.7501 5.25 15.7501V21.0001ZM36.75 20.1798C36.75 20.1798 38.0625 19.8246 38.0625 18.3751C38.0625 16.9256 36.75 16.5704 36.75 16.5704V20.1798ZM21 13.1251V23.6251V13.1251Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11.8125 23.625V37.4062C11.8125 37.5803 11.8816 37.7472 12.0047 37.8703C12.1278 37.9934 12.2947 38.0625 12.4688 38.0625H16.8164C17.022 38.0626 17.2248 38.0143 17.4083 37.9217C17.5918 37.829 17.751 37.6945 17.8731 37.5291C17.9951 37.3636 18.0766 37.1718 18.1109 36.9691C18.1452 36.7664 18.1314 36.5584 18.0707 36.362C17.3832 34.157 15.75 31.6706 15.75 27.5625H17.0625C17.4106 27.5625 17.7444 27.4242 17.9906 27.1781C18.2367 26.9319 18.375 26.5981 18.375 26.25V24.9375C18.375 24.5894 18.2367 24.2556 17.9906 24.0094C17.7444 23.7633 17.4106 23.625 17.0625 23.625H15.75"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
