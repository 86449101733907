import React, { FC } from 'react';
import classNames from 'classnames';
import { MarketActivityIndicator } from '@market/react-bindings';

type Transparency = 'visible' | 'base' | 'transparent';

type SpinnerProps = {
  children?: React.ReactNode;
  spinning?: boolean;
  className?: string;
  isMarketComponent?: boolean;
  size?: 'large' | 'small';
  backgroundTransparency?: Transparency;
};

type TransparencyScheme = Record<Transparency, string>;
const transparencyScheme: TransparencyScheme = {
  base: 'tw-bg-opacity-75',
  visible: 'tw-bg-opacity-100',
  transparent: 'tw-bg-opacity-0',
};

export const Spinner: FC<React.PropsWithChildren<SpinnerProps>> = ({
  children,
  spinning,
  className,
  backgroundTransparency,
  isMarketComponent = false,
  size = 'large',
}) => {
  return (
    <div className={classNames([className, spinning && 'tw-relative'])}>
      {spinning && (
        <div
          data-testid="spinner"
          className={classNames(
            ['tw-w-full tw-z-50 tw-bg-gray-100 tw-absolute tw-inset-0'],
            backgroundTransparency ? transparencyScheme[backgroundTransparency] : transparencyScheme['base'],
          )}
        >
          <div className="tw-sticky tw-top-0 tw-max-h-screen tw-h-full tw-flex tw-items-center tw-justify-center">
            {isMarketComponent ? (
              <MarketActivityIndicator size={size} />
            ) : (
              <svg className="tw-animate-spin" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="tw-text-gray-200" d="M16 1.2019V7.2019" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path className="tw-text-gray-600" d="M16 25.2017V31.2017" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  className="tw-text-gray-900"
                  d="M5.39453 5.59692L9.63953 9.84192"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className="tw-text-gray-500"
                  d="M22.3608 22.562L26.6058 26.807"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path className="tw-text-gray-800" d="M1 16.2019H6.99999" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  className="tw-text-gray-400"
                  d="M24.9973 16.2019H30.9973"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className="tw-text-gray-700"
                  d="M5.39453 26.807L9.63953 22.562"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className="tw-text-gray-300"
                  d="M22.3608 9.84192L26.6058 5.59692"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
