import React, { FC } from 'react';

export const IconHourGlassLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M11.9442 38.0625H30.055C31.6792 38.0625 32.9712 36.7262 32.7965 35.1865C31.6686 25.2656 24.9371 25.4297 24.9371 21C24.9371 16.5703 31.7547 16.8164 32.7957 6.81352C32.9597 5.27297 31.6792 3.9375 30.055 3.9375H11.9442C10.3199 3.9375 9.04272 5.27297 9.2035 6.81352C10.2445 16.8164 17.0621 16.4883 17.0621 21C17.0621 25.5117 10.3306 25.2656 9.2035 35.1865C9.02795 36.7262 10.3199 38.0625 11.9442 38.0625Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M28.161 35.4375H13.8736C12.5939 35.4375 12.233 33.9609 13.1304 33.0455C15.3026 30.8438 19.6872 29.2671 19.6872 26.7422V18.375C19.6872 16.7467 16.57 15.5039 14.6414 12.8625C14.3232 12.4269 14.3552 11.8125 15.164 11.8125H26.8723C27.5622 11.8125 27.7115 12.422 27.3973 12.8584C25.4966 15.5039 22.3122 16.7385 22.3122 18.375V26.7422C22.3122 29.2466 26.8821 30.5977 28.9075 33.0479C29.7237 34.0356 29.4382 35.4375 28.161 35.4375Z"
      fill="currentColor"
    />
  </svg>
);
