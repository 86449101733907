import React, { FC } from 'react';
import classNames from 'classnames';
import { MarketButton } from '@market/react-bindings';

import { Divider } from '../divider/Divider';
import { ButtonOptions } from '../button/buttonUtils';

import { Modal, ModalProps } from './Modal';

export type ConfirmModalProps = Pick<ModalProps, 'dialog' | 'disclosure'> & {
  content?: React.ReactNode;
  onConfirm?: () => void;
  onSecondConfirm?: () => void;
  onCancel?: () => void;
  header?: React.ReactNode;
  confirmDisabled?: boolean;
  confirmText?: string;
  secondConfirmText?: string;
  cancelText?: string;
  maxWidth?: string;
  small?: boolean;
  confirmColor?: ButtonOptions['color'];
  hideCloseButton?: boolean;
  showSecondSaveButton?: boolean;
  hideSaveButton?: boolean;
  onCancelClassName?: string;
  testId?: string;
};

export const ConfirmModal: FC<React.PropsWithChildren<ConfirmModalProps>> = ({
  content,
  dialog,
  disclosure,
  onConfirm,
  onSecondConfirm,
  onCancel,
  header,
  small = false,
  confirmDisabled,
  maxWidth = 'tw-max-w-screen-sm',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  secondConfirmText,
  confirmColor = 'primary',
  onCancelClassName = '',
  hideCloseButton = false,
  hideSaveButton = false,
  showSecondSaveButton = false,
  testId,
}) => {
  return (
    <Modal
      aria-label="Dialog"
      maxWidth={classNames([small ? 'tw-max-w-lg' : maxWidth])}
      dialog={dialog}
      disclosure={disclosure}
      hideOnClickOutside={false}
      data-testid={testId}
    >
      {header && (
        <Modal.ModalHeaderWithCloseButton className={classNames([small && 'tw-px-8'])} onClose={onCancel || dialog.hide}>
          {header}
        </Modal.ModalHeaderWithCloseButton>
      )}
      {content && <Modal.ModalContent className={classNames([small && 'tw-px-8'])}>{content}</Modal.ModalContent>}
      <Modal.ModalFooter className={classNames([small && 'tw-px-8'])}>
        {content && <Divider />}
        <div className="tw-pt-3 tw-flex tw-justify-end tw-space-x-3">
          {!hideCloseButton && (
            <MarketButton className={onCancelClassName} onClick={onCancel || dialog.hide} rank="secondary">
              {cancelText}
            </MarketButton>
          )}
          {showSecondSaveButton && (
            <MarketButton onClick={onSecondConfirm} rank="secondary">
              {secondConfirmText}
            </MarketButton>
          )}
          {!hideSaveButton && (
            <MarketButton
              variant={confirmColor === 'danger' ? 'destructive' : 'regular'}
              rank="primary"
              onClick={onConfirm}
              disabled={confirmDisabled || undefined}
            >
              {confirmText}
            </MarketButton>
          )}
        </div>
      </Modal.ModalFooter>
    </Modal>
  );
};
