import React, { FC, useState } from 'react';
import { Button, TimePicker } from 'antd';
import { Moment } from 'moment';
import classNames from 'classnames';
import { IconCheck } from '../icons';
import './TimePicker.scss';

export const TIME_FORMAT = 'h:mm a';

export type TimePickerProps = {
  value?: Moment;
  onChange: (time: Moment | null) => void;
  disabledHours?: () => number[];
  disabledMinutes?: (selectedHour?: number) => number[];
  suffixIcon?: React.ReactNode;
  hasError?: boolean;
  outline?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
  allowClear?: boolean;
};

const TimePickerComponent: FC<React.PropsWithChildren<TimePickerProps>> = ({
  value,
  onChange,
  isDisabled,
  suffixIcon,
  hasError,
  placeholder,
  disabledHours,
  outline = true,
  disabledMinutes,
  className,
  allowClear = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <TimePicker
      use12Hours
      inputReadOnly
      allowClear={allowClear}
      format={TIME_FORMAT}
      className={classNames([hasError && 'error', outline ? 'ant-time-picker-outlined tw-w-24 tw-block' : 'ant-time-picker-inlined', 'tw-relative', className])}
      placeholder={placeholder}
      disabledHours={disabledHours}
      disabledMinutes={disabledMinutes}
      value={value}
      onChange={onChange}
      suffixIcon={suffixIcon}
      open={isOpen}
      disabled={isDisabled}
      onOpenChange={(open) => setIsOpen(open)}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      addon={() => (
        <Button size="small" type="default" className="tw-w-full tw-h-full tw-bg-transparent tw-py-2 tw-border-0" onClick={handleClose}>
          <IconCheck />
        </Button>
      )}
    />
  );
};

export { TimePickerComponent as TimePicker };
