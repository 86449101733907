import React, { FC } from 'react';

export const IconImagePlaceholderSmall: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M18.7778 1H3.22222C1.99492 1 1 1.99492 1 3.22222V18.7778C1 20.0051 1.99492 21 3.22222 21H18.7778C20.0051 21 21 20.0051 21 18.7778V3.22222C21 1.99492 20.0051 1 18.7778 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M7.11198 8.77779C8.03245 8.77779 8.77865 8.0316 8.77865 7.11112C8.77865 6.19065 8.03245 5.44446 7.11198 5.44446C6.1915 5.44446 5.44531 6.19065 5.44531 7.11112C5.44531 8.0316 6.1915 8.77779 7.11198 8.77779Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20.9965 14.3333L15.441 8.77777L3.21875 21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
