import * as React from 'react';

export const ArrowLeft = () => (
  // @ts-ignore slot attribute is required for market, but not part of the React svg type.
  <svg slot="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 8H1M1 8L8 1M1 8L8 15"
      stroke="var(--icon-button-secondary-rank-normal-state-icon-color)"
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
