import { StoreDateRangeDto } from '@goparrot/store-v2-sdk';
import classNames from 'classnames';
import moment from 'moment';
import React, { FC } from 'react';
import { MarketButton } from '@market/react-bindings';
import { IconAdd, IconArrowDownSmall, IconCloseSmall } from '../../icons';
import { TimePicker } from '../TimePicker';
import { getDisabledAddTimeset, getDisabledMinutes, getDisabledHours } from './utils';

const FORMAT_TIME = 'HH:mm';

type TimePickerItemProps = {
  removeItem?(): void;
  addItem?(): void;
  className?: string;
  value: StoreDateRangeDto;
  onChange(value: StoreDateRangeDto): void;
  timesetIndex?: number;
  allRangeItems?: StoreDateRangeDto[];
  hasFieldError?: boolean;
  disabled?: boolean;
};

export const TimePickerItem: FC<React.PropsWithChildren<TimePickerItemProps>> = ({
  removeItem,
  hasFieldError,
  addItem,
  allRangeItems,
  timesetIndex,
  className,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div className={classNames([className, disabled && 'tw-opacity-50', 'tw-mb-2 last:tw-mb-0 tw-flex tw-items-start'])}>
      <div className="tw-flex tw-items-center tw-relative">
        <TimePicker
          value={moment(value.from, FORMAT_TIME)}
          disabledHours={getDisabledHours(allRangeItems, timesetIndex, 'from')}
          disabledMinutes={getDisabledMinutes(allRangeItems, timesetIndex, 'from')}
          onChange={(from) => onChange({ ...value, from: moment(from).format(FORMAT_TIME) })}
          suffixIcon={<IconArrowDownSmall className={classNames([disabled ? 'tw-text-cool-gray-400' : 'tw-text-blue-gray-900', 'tw-mt-px'])} />}
          hasError={(value && value.from >= value.to) || hasFieldError}
          isDisabled={disabled}
        />
        <span className={classNames([disabled && 'tw-opacity-30', 'tw-mx-1 tw-block tw-text-blue-gray-900'])}>—</span>
        <TimePicker
          value={moment(value.to, FORMAT_TIME)}
          disabledHours={getDisabledHours(allRangeItems, timesetIndex, 'to')}
          disabledMinutes={getDisabledMinutes(allRangeItems, timesetIndex, 'to')}
          onChange={(to) => onChange({ ...value, to: moment(to).format(FORMAT_TIME) })}
          suffixIcon={<IconArrowDownSmall className={classNames([disabled ? 'tw-text-cool-gray-400' : 'tw-text-blue-gray-900', 'tw-mt-px'])} />}
          hasError={(value && value.from >= value.to) || hasFieldError}
          isDisabled={disabled}
        />
        <MarketButton
          rank="secondary"
          disabled={getDisabledAddTimeset(allRangeItems, timesetIndex) || (value && value.from >= value.to) || hasFieldError || disabled || undefined}
          className="tw-ml-1"
          onClick={addItem}
        >
          <IconAdd />
        </MarketButton>
        <MarketButton className="tw-absolute tw--right-13" rank="tertiary" disabled={disabled || undefined} onClick={removeItem}>
          <IconCloseSmall className="tw-text-blue-gray-600" />
        </MarketButton>
      </div>
    </div>
  );
};
