import React, { FC } from 'react';

export const IconFranchiseManagement: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M14.6668 7.33333V2H10.0002V4H6.00016V2H1.3335V7.33333H6.00016V5.33333H7.3335V12H10.0002V14H14.6668V8.66667H10.0002V10.6667H8.66683V5.33333H10.0002V7.33333H14.6668ZM4.66683 6H2.66683V3.33333H4.66683V6ZM11.3335 10H13.3335V12.6667H11.3335V10ZM11.3335 3.33333H13.3335V6H11.3335V3.33333Z"
      fill="currentColor"
    />
  </svg>
);
