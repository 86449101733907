import React, { FC } from 'react';

export const IconDiagram: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 2C9.79086 2 8 3.79086 8 6C8 7.86384 9.27477 9.42994 11 9.87398V11H5V14.126C3.27477 14.5701 2 16.1362 2 18C2 20.2091 3.79086 22 6 22C8.20914 22 10 20.2091 10 18C10 16.1362 8.72523 14.5701 7 14.126V13H17V14.126C15.2748 14.5701 14 16.1362 14 18C14 20.2091 15.7909 22 18 22C20.2091 22 22 20.2091 22 18C22 16.1362 20.7252 14.5701 19 14.126V11H13V9.87398C14.7252 9.42994 16 7.86384 16 6C16 3.79086 14.2091 2 12 2ZM10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6ZM4 18C4 16.8954 4.89543 16 6 16C7.10457 16 8 16.8954 8 18C8 19.1046 7.10457 20 6 20C4.89543 20 4 19.1046 4 18ZM18 16C16.8954 16 16 16.8954 16 18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16Z"
      fill="currentColor"
      fillOpacity="0.9"
    />
  </svg>
);
