import { StoreDateRangeDto } from '@goparrot/store-v2-sdk';
import classNames from 'classnames';
import React, { FC, useState } from 'react';

import { MarketButton } from '@market/react-bindings';
import { IconAdd } from '../../icons';
import { TimePickerItem } from './TimePickerItem';
import { NINE_AM, ELEVEN_FIFTY_NINE_PM } from './constants';
import { getHoursAndMinutes } from './utils';

export type TimePickerIntervalProps = {
  value: StoreDateRangeDto[];
  onChange(value: StoreDateRangeDto[]): void;
  className?: string;
  setIsInvalidForm?(value: boolean): void;
  disabled?: boolean;
};

export const TimePickerInterval: FC<React.PropsWithChildren<TimePickerIntervalProps>> = ({
  value,
  disabled = false,
  setIsInvalidForm,
  onChange,
  className,
}) => {
  const [hasFieldError, setHasFieldError] = useState<boolean>(false);
  const handleChange = (newIntervalValue: StoreDateRangeDto, index: number) => {
    const duplicate = [...value];
    duplicate[index] = newIntervalValue;
    onChange(duplicate);
    const newIntervalInvalidDate = newIntervalValue.from >= newIntervalValue.to;

    if (!index) {
      setIsInvalidForm && setIsInvalidForm(newIntervalInvalidDate);
      return;
    }

    const lastItemTo = getHoursAndMinutes(duplicate[index - 1]);
    const currentItemFrom = getHoursAndMinutes(duplicate[index]);
    const invalidDate = currentItemFrom.fromHour < lastItemTo.toHour;

    setHasFieldError(invalidDate);
    setIsInvalidForm && setIsInvalidForm(invalidDate || newIntervalInvalidDate);
  };

  const handleRemove = (index: number) => {
    setHasFieldError(false);
    setIsInvalidForm && setIsInvalidForm(false);
    const scheduleWithRemovedItem = value.filter((_, itemIndex) => itemIndex !== index);
    onChange(scheduleWithRemovedItem);
  };

  const addItem = (index: number) => {
    if (value.length) {
      const valueItemIndexTo = value[index].to;
      const valueItemIndexFrom = value[index + 1]?.from;
      const start = index + 1;
      const deleteCount = 0;
      const newValue = [...value];
      newValue.splice(start, deleteCount, {
        from: valueItemIndexTo,
        to: valueItemIndexFrom ? valueItemIndexFrom : ELEVEN_FIFTY_NINE_PM,
      });
      onChange(newValue);
    } else {
      onChange([
        {
          from: NINE_AM,
          to: ELEVEN_FIFTY_NINE_PM,
        },
      ]);
    }
  };

  return (
    <div className={classNames([className, 'tw-flex tw-items-start'])}>
      <div>
        {value &&
          value.map((timeset, index) => {
            return (
              <TimePickerItem
                allRangeItems={value}
                value={timeset}
                removeItem={() => handleRemove(index)}
                onChange={(item) => handleChange(item, index)}
                timesetIndex={index}
                key={index}
                addItem={() => addItem(index)}
                hasFieldError={hasFieldError}
                disabled={disabled}
              />
            );
          })}
      </div>
      {value?.length === 0 && (
        <MarketButton disabled={disabled || undefined} rank="secondary" className="tw-ml-1" onClick={() => addItem(-1)}>
          <IconAdd />
        </MarketButton>
      )}
    </div>
  );
};
