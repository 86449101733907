import React, { FC } from 'react';
import classNames from 'classnames';

import { IconClose } from '../icons';
import { Button } from '../button/Button';

import { ModalProps } from './Modal';

type DivElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
type ModalContentProps = DivElementProps & Partial<ModalProps>;

export const ModalHeader: FC<React.PropsWithChildren<DivElementProps & { className?: string }>> = ({ children, className }) => (
  <div
    data-testid="modal-header"
    className={classNames([className, 'tw-py-5 tw-px-6 tw-bg-blue-gray-100 tw-font-bold tw-text-blue-gray-900 tw-text-lg tw-font-sans tw-rounded-inherit'])}
  >
    {children}
  </div>
);

export const ModalFooter: FC<React.PropsWithChildren<DivElementProps & { className?: string }>> = ({ children, className }) => (
  <div data-testid="modal-footer" className={classNames([className, 'tw-px-6 tw-pb-3'])}>
    {children}
  </div>
);

type ModalHeaderWithCloseButtonProps = {
  onClose(): void;
} & DivElementProps;

export const ModalHeaderWithCloseButton: FC<React.PropsWithChildren<ModalHeaderWithCloseButtonProps & { className?: string }>> = ({
  children,
  className,
  onClose,
}) => (
  <ModalHeader className={className}>
    <div className="tw-flex tw-justify-between tw-items-center">
      {children}
      <Button onClick={onClose} kind="icon" color="clean">
        <IconClose />
      </Button>
    </div>
  </ModalHeader>
);

export const ModalContentWrapper = React.forwardRef<React.Ref<ModalContentProps>, ModalContentProps>(
  ({ children, maxWidth = 'tw-max-w-screen-sm', fullScreen, ...props }, ref) => (
    <div
      className={classNames([
        'tw-bg-white tw-rounded tw-w-full focus:tw-outline-none tw-overflow-y-auto',
        fullScreen ? 'tw-max-h-screen tw-h-full tw-max-w-full' : [maxWidth, 'tw-max-h-full'],
      ])}
      ref={ref as any}
      {...props}
    >
      {children}
    </div>
  ),
);

export const ModalContent: FC<React.PropsWithChildren<DivElementProps>> = ({ children, className = '' }) => (
  <div data-testid="modal-content" className={`${className} tw-p-6`}>
    {children}
  </div>
);
