import * as React from 'react';
import { IconProps } from './type';

export const IconCloudUpload: React.FC<React.PropsWithChildren<IconProps>> = ({ className, width = 48, height = 48 }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 32L24 24L16 32" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M24 24V42" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      // eslint-disable-next-line max-len
      d="M40.7828 36.78C42.7335 35.7165 44.2745 34.0337 45.1626 31.9972C46.0507 29.9607 46.2353 27.6864 45.6873 25.5334C45.1392 23.3803 43.8898 21.471 42.1362 20.1069C40.3826 18.7427 38.2246 18.0014 36.0028 18H33.4828C32.8775 15.6585 31.7492 13.4846 30.1827 11.642C28.6163 9.79927 26.6525 8.33567 24.439 7.36118C22.2256 6.3867 19.8199 5.92669 17.4031 6.01573C14.9862 6.10478 12.621 6.74057 10.4852 7.8753C8.34942 9.01003 6.49867 10.6142 5.07209 12.5671C3.64551 14.5201 2.68023 16.771 2.24881 19.1508C1.81739 21.5305 1.93106 23.977 2.58128 26.3065C3.23149 28.636 4.40133 30.7877 6.00285 32.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M32 32L24 24L16 32" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
