import * as React from 'react';
import classNames from 'classnames';

import { Radio, RadioProps, RadioRenderOptionProps, RenderOptionType } from '../radio';

const RenderOption = React.forwardRef<HTMLInputElement, RadioRenderOptionProps & { isOutlined: boolean }>(({ isOutlined, ...props }, ref) => {
  const onInputChange = (e: any) => {
    props.onSelect(e?.target?.value);
    props.onChange?.(e);
    props.onBlur && props.onBlur(e);
  };

  return (
    <div
      className={classNames([
        'tw-transition-colors tw-ease-in-out',
        isOutlined
          ? [
              props.checked && 'tw-bg-cool-gray-100',
              !props.disabled && props.checked ? 'tw-text-cool-gray-900' : 'tw-text-cool-gray-500',
              'tw-text-center tw-border-l tw-border-cool-gray-100 first:tw-border-0 last:tw-rounded-r-inherit first:tw-rounded-l-inherit tw-font-medium',
            ]
          : [props.checked && 'tw-shadow-sm tw-bg-white', !props.disabled && props.checked ? 'tw-text-light-blue-400' : '', 'tw-rounded-md'],
      ])}
    >
      <label
        htmlFor={props.id}
        data-text={props.label}
        className={classNames([
          'tw-block tw-text-sm',
          props.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
          isOutlined ? 'tw-py-1.5 tw-px-3' : 'tw-py-1 tw-px-4.5',
        ])}
      >
        {props.label}
      </label>
      <input {...props} onChange={onInputChange} ref={ref} className="tw-absolute tw-invisible" />
    </div>
  );
});

const OutlinedRenderOption = React.forwardRef<HTMLInputElement, RadioRenderOptionProps>((props, ref) => <RenderOption ref={ref} {...props} isOutlined />);

export const InlineSelect: React.FC<
  React.PropsWithChildren<
    RadioProps & {
      containerClassNames?: string;
      isOutlined?: boolean;
    }
  >
> = ({ isOutlined, containerClassNames = 'tw-p-1', ...props }) => {
  return (
    <Radio
      {...props}
      radioGroupClassName={classNames([
        'tw-rounded-md tw-flex tw-relative',
        containerClassNames,
        isOutlined ? 'tw-border tw-border-cool-gray-100 tw-py-0 tw-px-0' : 'tw-bg-gray-100',
      ])}
      CustomRenderOption={(isOutlined ? OutlinedRenderOption : RenderOption) as RenderOptionType}
    />
  );
};
