import React, { FC } from 'react';

import { Tooltip } from '../../tooltip/Tooltip';
import { Button } from '../../button/Button';
import { IconQuestion } from '../../icons';

export type LabelWithTooltipProps = {
  tooltip: React.ReactNode | string;
};

export const LabelWithTooltip: FC<React.PropsWithChildren<LabelWithTooltipProps>> = ({ tooltip, children }) => (
  <div className="tw-flex tw-justify-between tw-items-center">
    <div>{children}</div>
    <Tooltip tooltip={tooltip} tooltipClassName="tw-max-w-xs tw-z-1">
      <Button kind="clean" color="clean" className="tw-w-4 tw-h-4 tw-outline-none tw-ring-transparent tw-ml-3">
        <IconQuestion />
      </Button>
    </Tooltip>
  </div>
);
