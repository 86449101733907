import React, { FC } from 'react';

export const IconQuestion: FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.6" stroke="currentColor" strokeWidth="0.8" />
    <path
      // eslint-disable-next-line max-len
      d="M10.7278 5.86804C10.7278 4.40404 9.3478 3.74404 7.9558 3.74404C6.2038 3.74404 4.9438 4.78804 4.9318 6.42004H6.1798C6.2278 5.56804 6.7198 4.77604 7.9318 4.77604C8.7838 4.77604 9.4318 5.20804 9.4318 5.92804C9.4318 6.67204 8.9278 7.12804 8.2678 7.53604C7.3318 8.12404 6.8518 8.60404 6.8518 9.66004V10.068H8.1118V9.69604C8.1118 8.97604 8.41181 8.74804 9.1558 8.28004C9.93581 7.78804 10.7278 6.99604 10.7278 5.86804ZM6.7438 12.6H8.2438V11.1H6.7438V12.6Z"
      fill="currentColor"
    />
  </svg>
);
