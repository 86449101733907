import React, { FC } from 'react';

export const IconStore: FC<React.PropsWithChildren<{ className?: string; width?: number; height?: number }>> = ({
  className = '',
  width = 28,
  height = 22,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M27.3327 8.33301L25.7593 0.333008H2.23935L0.692682 8.06634L0.666016 8.33301C0.666016 10.293 1.74602 11.9997 3.33268 12.9197V21.6663H16.666V12.9197C17.1727 12.6263 17.6127 12.253 17.9993 11.813C18.9727 12.933 20.3993 13.653 21.9993 13.653V21.653H24.666V12.9063C26.2527 11.9997 27.3327 10.293 27.3327 8.33301ZM23.5727 2.99967L24.106 5.66634H3.89268L4.42602 2.99967H23.5727ZM3.33268 8.45301L3.35935 8.33301H8.66602C8.66602 9.79967 7.46602 10.9997 5.99935 10.9997C4.57268 10.9997 3.39935 9.86634 3.33268 8.45301ZM13.9993 18.9997H5.99935V13.6663C7.59935 13.6663 9.02602 12.9463 9.99935 11.8263C10.9727 12.9463 12.3993 13.6663 13.9993 13.6663V18.9997ZM13.9993 10.9997C12.5327 10.9997 11.3327 9.79967 11.3327 8.33301H16.666C16.666 9.79967 15.466 10.9997 13.9993 10.9997ZM19.3327 8.33301H24.6393L24.666 8.45301C24.5993 9.86634 23.426 10.9997 21.9993 10.9997C20.5327 10.9997 19.3327 9.79967 19.3327 8.33301Z"
      fill="currentColor"
    />
  </svg>
);
