import React, { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './FloatingPageTitle.css';

export type FloatingPageTitleProps = {
  className?: string;
  wrapperSize?: 'small' | 'large';
};

export const FloatingPageTitle: FC<React.PropsWithChildren<FloatingPageTitleProps>> = ({ children, className = '', wrapperSize = 'large' }) => {
  const floatingBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollHandler = () => {
      const stickyEl = floatingBarRef.current;
      if (!stickyEl) return;
      const stickyElHeight = stickyEl.offsetHeight;
      const element = document.getElementsByClassName('ant-layout-content')[0],
        style = window.getComputedStyle(element),
        paddingTop = style.getPropertyValue('padding-top');
      const currStickyPos = stickyEl.getBoundingClientRect().top + window.pageYOffset - parseInt(paddingTop, 10);

      if (window.pageYOffset > currStickyPos) {
        stickyEl.classList.add('floating-bar--sticky');
        stickyEl.style.height = `${stickyElHeight}px`;
      } else {
        stickyEl.classList.remove('floating-bar--sticky');
        stickyEl.style.height = 'auto';
      }
    };

    window.addEventListener('scroll', scrollHandler, true);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  return (
    <div ref={floatingBarRef} className="floating-bar">
      <div className="floating-bar__content">
        <div className={classNames([className, `floating-bar__container ${wrapperSize}`])}>{children}</div>
      </div>
    </div>
  );
};
