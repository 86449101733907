import * as React from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
};

export const IconAlertOutlined: React.FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <svg className={className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d={classNames([
          'M8.03174 15.617C11.7136 15.617 14.6984 12.6323 14.6984 8.95036C14.6984 5.26846 11.7136 2.28369 8.03174',
          '2.28369C4.34984 2.28369 1.36507 5.26846 1.36507 8.95036C1.36507 12.6323 4.34984 15.617 8.03174 15.617Z',
        ])}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.03174 6.28369V8.95036" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.03174 11.6172H8.03841" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="translate(0.0317402 0.950439)" />
      </clipPath>
    </defs>
  </svg>
);
