import * as React from 'react';

export const IconHours: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M12.0002 4.74667H7.48017L9.30683 2.86667C7.48683 1.06667 4.54017 1 2.72017 2.8C0.900166 4.60667 0.900166 7.52 2.72017 9.32667C4.54017 11.1333 7.48683 11.1333 9.30683 9.32667C10.2135 8.43333 10.6668 7.38667 10.6668 6.06667H12.0002C12.0002 7.38667 11.4135 9.1 10.2402 10.26C7.90017 12.58 4.10017 12.58 1.76017 10.26C-0.573168 7.94667 -0.593168 4.18667 1.74683 1.87333C4.08683 -0.44 7.84017 -0.44 10.1802 1.87333L12.0002 0V4.74667ZM6.3335 3.33333V6.16667L8.66683 7.55333L8.18683 8.36L5.3335 6.66667V3.33333H6.3335Z"
      fill="currentColor"
    />
  </svg>
);
