import React, { FC, MutableRefObject, useRef } from 'react';
import { MarketToaster } from '@market/react-bindings';
import styles from './Toaster.module.scss';
import { Toast } from './Toast';

export type NotificationOptions = {
  text: string | JSX.Element;
  persistent?: boolean;
  actions?: JSX.Element[]; // <a href> or <button> (not a <market-button>) see http://go/marketstorybook
};

export type MarketNotification = NotificationOptions & {
  id: string;
  type: 'success' | 'warning' | 'info' | 'critical';
};

export const Toaster: FC<React.PropsWithChildren<{ notifications: MarketNotification[] }>> = ({ notifications }) => {
  const toasterElRef = useRef<HTMLMarketToasterElement>(null) as MutableRefObject<HTMLMarketToasterElement>;

  return (
    <MarketToaster className={styles.toaster} ref={toasterElRef}>
      {notifications.map((notification) => (
        <Toast key={notification.id} {...notification} toasterElRef={toasterElRef} />
      ))}
    </MarketToaster>
  );
};
