import React from 'react';
import classNames from 'classnames';

type DivElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type TableCellProps = { isEnabled?: boolean; className?: string };

export const TableCellText: React.FC<React.PropsWithChildren<DivElementProps & TableCellProps>> = ({ children, isEnabled = true, className = '' }) => (
  <div className={classNames(['tw-font-normal tw-text-sm ', isEnabled ? 'tw-text-blue-gray-900' : `tw-text-blue-gray-400`, className])}>{children}</div>
);
