import React, { FC } from 'react';
import { IconProps } from './type';

export const MarketIconCheckCircle: FC<IconProps> = ({ className }) => (
  <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M0.5 14C0.5 6.545 6.545 0.5 14 0.5C21.455 0.5 27.5 6.545 27.5 14C27.5 21.455 21.455 27.5 14 27.5C6.545 27.5 0.5 21.455 0.5 14ZM3.5 14C3.5 19.79 8.21 24.5 14 24.5C19.79 24.5 24.5 19.79 24.5 14C24.5 8.21 19.79 3.5 14 3.5C8.21 3.5 3.5 8.21 3.5 14ZM9.06507 12.2001L12.5001 15.6351L18.9651 9.20007L21.0801 11.3151L13.5801 18.8151C13.2801 19.1001 12.9051 19.2501 12.5151 19.2501C12.1251 19.2501 11.7501 19.1151 11.4501 18.8151L6.95007 14.3151L9.06507 12.2001Z"
      fill="currentColor"
    />
  </svg>
);
