import React, { FC } from 'react';

export const IconPosSettings: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M13.3335 2H2.66683C1.92683 2 1.3335 2.59333 1.3335 3.33333V11.3333C1.3335 12.0667 1.92683 12.6667 2.66683 12.6667H5.3335V14H10.6668V12.6667H13.3335C14.0668 12.6667 14.6668 12.0667 14.6668 11.3333V3.33333C14.6668 2.59333 14.0668 2 13.3335 2ZM13.3335 11.3333H2.66683V3.33333H13.3335V11.3333Z"
      fill="currentColor"
    />
    <path d="M9.33333 5.5H4V6.5H9.33333V5.5Z" fill="currentColor" />
    <path d="M11 6.50008H12V5.50008H11V4.66675H10V7.33342H11V6.50008Z" fill="currentColor" />
    <path d="M12.0003 8.16675H6.66699V9.16675H12.0003V8.16675Z" fill="currentColor" />
    <path d="M5 9.99992H6V7.33325H5V8.16659H4V9.16659H5V9.99992Z" fill="currentColor" />
  </svg>
);
