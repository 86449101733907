import React from 'react';
import './ResponsiveContainer.scss';
import classNames from 'classnames';

interface ResponsiveContainerInterface {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

export const ResponsiveContainer: React.FC<React.PropsWithChildren<ResponsiveContainerInterface>> = ({ children, className, testId }): React.ReactElement => {
  return (
    <div data-testid={testId} className={classNames(['responsive-container tw-bg-white', className])}>
      {children}
    </div>
  );
};
