import React, { FC } from 'react';

export const IconGiftCard: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M13.3335 4.00016H11.8802C11.9535 3.7935 12.0002 3.56683 12.0002 3.3335C12.0002 2.22683 11.1068 1.3335 10.0002 1.3335C9.30016 1.3335 8.6935 1.6935 8.3335 2.2335L8.00016 2.68016L7.66683 2.22683C7.30683 1.6935 6.70016 1.3335 6.00016 1.3335C4.8935 1.3335 4.00016 2.22683 4.00016 3.3335C4.00016 3.56683 4.04683 3.7935 4.12016 4.00016H2.66683C1.92683 4.00016 1.34016 4.5935 1.34016 5.3335L1.3335 12.6668C1.3335 13.4068 1.92683 14.0002 2.66683 14.0002H13.3335C14.0735 14.0002 14.6668 13.4068 14.6668 12.6668V5.3335C14.6668 4.5935 14.0735 4.00016 13.3335 4.00016ZM10.0002 2.66683C10.3668 2.66683 10.6668 2.96683 10.6668 3.3335C10.6668 3.70016 10.3668 4.00016 10.0002 4.00016C9.6335 4.00016 9.3335 3.70016 9.3335 3.3335C9.3335 2.96683 9.6335 2.66683 10.0002 2.66683ZM6.00016 2.66683C6.36683 2.66683 6.66683 2.96683 6.66683 3.3335C6.66683 3.70016 6.36683 4.00016 6.00016 4.00016C5.6335 4.00016 5.3335 3.70016 5.3335 3.3335C5.3335 2.96683 5.6335 2.66683 6.00016 2.66683ZM13.3335 12.6668H2.66683V11.3335H13.3335V12.6668ZM13.3335 9.3335H2.66683V5.3335H6.0535L4.66683 7.22016L5.74683 8.00016L8.00016 4.9335L10.2535 8.00016L11.3335 7.22016L9.94683 5.3335H13.3335V9.3335Z"
      fill="currentColor"
    />
  </svg>
);
