import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { Checkbox as BaseCheckbox, CheckboxProps as BaseCheckboxProps } from 'reakit/Checkbox';
import classNames from 'classnames';

export type CheckboxProps = {
  onChange?(value: boolean, event?: ChangeEvent): void;
  checked?: boolean;
  label?: string;
  state?: unknown;
  className?: string;
} & Omit<BaseCheckboxProps, 'onChange'>;

const CheckMark = () => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5.23529L5.125 9L12 1" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

const Line = () => (
  <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H8" stroke="currentColor" strokeWidth="2" strokeLinejoin="round" />
  </svg>
);
const INDETERMINATE = 'indeterminate';

export const Checkbox: FC<React.PropsWithChildren<CheckboxProps>> = React.forwardRef(
  ({ onChange, label, checked = false, className = '', state, ...props }, ref) => {
    const onInputChange = useCallback((event: any) => !props.disabled && onChange && onChange(!checked, event), [props.disabled, onChange, checked]);
    const inputId = useMemo(() => `toggle-${Math.random().toString(36).substring(2)}`, []);
    const checkboxProps = {
      ref,
      state,
      checked,
      id: inputId,
      onChange: onInputChange,
    };

    const getIcon = () => {
      if (state === undefined) {
        return checked ? <CheckMark /> : null;
      }

      if (state) {
        return state === INDETERMINATE ? <Line /> : <CheckMark />;
      }

      return null;
    };

    return (
      <>
        <BaseCheckbox {...props} {...checkboxProps} as="input" className="tw-sr-only" />
        <BaseCheckbox
          as="label"
          htmlFor={inputId}
          className={classNames([
            className,
            label ? 'tw-flex tw-items-center' : 'tw-inline-block tw-align-middle',
            'tw-not-sr-only tw-outline-none',
            props.disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
          ])}
        >
          <span
            className={classNames([
              'tw-h-4 tw-w-4 tw-rounded-sm tw-flex tw-items-center tw-justify-center',
              checked || (!!state && state !== INDETERMINATE) ? 'tw-bg-light-blue-400 tw-text-white' : 'tw-border tw-border-cool-gray-300 tw-bg-white',
              props.disabled ? (checked || !!state ? 'tw-bg-cool-gray-300' : 'tw-bg-cool-gray-100') : '',
            ])}
          >
            {getIcon()}
          </span>
          {label && <span className="tw-ml-2 tw-text-blue-gray-900">{label}</span>}
        </BaseCheckbox>
      </>
    );
  },
);
