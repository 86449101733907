import React, { FC } from 'react';

export const IconMarketingLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M14.4375 35.4375C15.1624 35.4375 15.75 34.8499 15.75 34.125C15.75 33.4001 15.1624 32.8125 14.4375 32.8125C13.7126 32.8125 13.125 33.4001 13.125 34.125C13.125 34.8499 13.7126 35.4375 14.4375 35.4375Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M32.8125 35.4375C33.5374 35.4375 34.125 34.8499 34.125 34.125C34.125 33.4001 33.5374 32.8125 32.8125 32.8125C32.0876 32.8125 31.5 33.4001 31.5 34.125C31.5 34.8499 32.0876 35.4375 32.8125 35.4375Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3.9375 6.5625H9.1875L13.125 28.875H34.125" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      // eslint-disable-next-line max-len
      d="M13.125 23.625H33.5869C33.7386 23.6251 33.8858 23.5726 34.0032 23.4764C34.1206 23.3803 34.201 23.2464 34.2308 23.0975L36.5933 11.285C36.6124 11.1898 36.6101 11.0915 36.5865 10.9972C36.563 10.903 36.5188 10.8152 36.4572 10.7401C36.3956 10.6649 36.3181 10.6045 36.2303 10.5629C36.1425 10.5214 36.0465 10.4999 35.9494 10.5H10.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
