import React, { FC } from 'react';

interface IconProps {
  className?: string;
}

export const IconRoundedCheck: FC<React.PropsWithChildren<IconProps>> = ({ className = '' }) => {
  return (
    <svg className={className} width="88" height="88" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M30 46L42 58C42 58 56.6274 43.3726 66 34M90 46C90 70.3005 70.3005 90 46 90C21.6995 90 2 70.3005 2 46C2 21.6995 21.6995 2 46 2C70.3005 2 90 21.6995 90 46Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
