/* eslint-disable max-len */
import * as React from 'react';
import { FC } from 'react';

export const IconCalculator: FC<React.PropsWithChildren<{ width?: number; height?: number }>> = ({ width = 20, height = 20 }) => (
  // @ts-ignore slot attribute is required for market, but not part of the React svg type.
  <svg slot="icon" width={width} height={height} fill="currentColor" version="1.1" viewBox="0 0 64 64">
    <g id="calculator">
      <g>
        <path d="M44,11H20c-1.7,0-3,1.3-3,3v4c0,1.7,1.3,3,3,3h24c1.7,0,3-1.3,3-3v-4C47,12.3,45.7,11,44,11z M45,18    c0,0.6-0.4,1-1,1H20c-0.6,0-1-0.4-1-1v-4c0-0.6,0.4-1,1-1h24c0.6,0,1,0.4,1,1V18z" />
        <path d="M48,5H16c-1.7,0-3,1.3-3,3v48c0,1.7,1.3,3,3,3h32c1.7,0,3-1.3,3-3V8C51,6.3,49.7,5,48,5z M49,56    c0,0.6-0.4,1-1,1H16c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h32c0.6,0,1,0.4,1,1V56z" />
        <path d="M23,25h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C26,26.3,24.7,25,23,25z M24,30    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V30z" />
        <path d="M33,25h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C36,26.3,34.7,25,33,25z M34,30    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V30z" />
        <path d="M43,25h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C46,26.3,44.7,25,43,25z M44,30    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V30z" />
        <path d="M23,35h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C26,36.3,24.7,35,23,35z M24,40    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V40z" />
        <path d="M33,35h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C36,36.3,34.7,35,33,35z M34,40    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V40z" />
        <path d="M43,35h-2c-1.7,0-3,1.3-3,3v12c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3V38C46,36.3,44.7,35,43,35z M44,50    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1V38c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V50z" />
        <path d="M23,45h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C26,46.3,24.7,45,23,45z M24,50    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V50z" />
        <path d="M33,45h-2c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h2c1.7,0,3-1.3,3-3v-2C36,46.3,34.7,45,33,45z M34,50    c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1V50z" />
      </g>
    </g>
  </svg>
);
