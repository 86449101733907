import React, { FC } from 'react';

export const IconDeliverySettings: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M12.6668 4.66683C12.6668 3.9335 12.0668 3.3335 11.3335 3.3335H9.3335V4.66683H11.3335V6.4335L9.0135 9.3335H6.66683V6.00016H4.00016C2.52683 6.00016 1.3335 7.1935 1.3335 8.66683V10.6668H2.66683C2.66683 11.7735 3.56016 12.6668 4.66683 12.6668C5.7735 12.6668 6.66683 11.7735 6.66683 10.6668H9.6535L12.6668 6.90016V4.66683ZM2.66683 9.3335V8.66683C2.66683 7.9335 3.26683 7.3335 4.00016 7.3335H5.3335V9.3335H2.66683ZM4.66683 11.3335C4.30016 11.3335 4.00016 11.0335 4.00016 10.6668H5.3335C5.3335 11.0335 5.0335 11.3335 4.66683 11.3335Z"
      fill="currentColor"
    />
    <path d="M6.66683 4H3.3335V5.33333H6.66683V4Z" fill="currentColor" />
    <path
      // eslint-disable-next-line max-len
      d="M12.667 8.66699C11.5603 8.66699 10.667 9.56033 10.667 10.667C10.667 11.7737 11.5603 12.667 12.667 12.667C13.7737 12.667 14.667 11.7737 14.667 10.667C14.667 9.56033 13.7737 8.66699 12.667 8.66699ZM12.667 11.3337C12.3003 11.3337 12.0003 11.0337 12.0003 10.667C12.0003 10.3003 12.3003 10.0003 12.667 10.0003C13.0337 10.0003 13.3337 10.3003 13.3337 10.667C13.3337 11.0337 13.0337 11.3337 12.667 11.3337Z"
      fill="currentColor"
    />
  </svg>
);
