import classNames from 'classnames';
import React from 'react';
import { Input as BaseInput, InputProps as BaseInputProps } from 'reakit/Input';

type Variant = 'clean' | 'outlined' | 'market';

export type InputProps = BaseInputProps & {
  variant?: Variant;
  className?: string;
  hasError?: boolean;
  as?: 'input' | 'textarea';
  leadingAccessory?: string;
  rows?: number;
};

const inputVariantMapping: Record<Variant, string> = {
  clean: 'tw-h-full tw-w-full',
  outlined: 'tw-border tw-rounded-md tw-p-3',
  market: 'tw-border tw-rounded-md tw-p-3',
};

const textareaVariantMapping: Record<Variant, string> = {
  clean: 'tw-min-h-12 tw-max-h-80 tw-w-full',
  outlined: 'tw-border tw-rounded-md tw-p-3',
  market: '',
};

const defaultVariantsModifier: Record<Variant, string> = {
  clean: '',
  outlined: 'tw-border-cool-gray-200',
  market: '',
};

const errorVariantsModifiers: Record<Variant, string> = {
  clean: '',
  outlined: 'tw-border-red-600',
  market: '',
};

export const Input = React.forwardRef<React.Ref<InputProps>, InputProps>(
  ({ className, variant = 'clean', hasError, disabled, as = 'input', leadingAccessory, ...props }, ref) =>
    leadingAccessory ? (
      <div
        role="presentation"
        className={classNames([
          className,
          'tw-flex',
          as === 'input' ? inputVariantMapping[variant] : textareaVariantMapping[variant],
          hasError ? errorVariantsModifiers[variant] : defaultVariantsModifier[variant],
          'focus:tw-outline-none',
          variant === 'clean' && 'tw-rounded-r-inherit',
          disabled && 'tw-bg-cool-gray-100',
        ])}
      >
        <img className="tw-w-4 tw-mr-3" src={leadingAccessory} alt="" />
        <BaseInput className={`tw-w-full ${variant === 'market' && 'market'}`} disabled={disabled} as={as} {...props} ref={ref as any} />
      </div>
    ) : (
      <BaseInput
        className={classNames([
          className,
          as === 'input' ? inputVariantMapping[variant] : textareaVariantMapping[variant],
          hasError ? errorVariantsModifiers[variant] : defaultVariantsModifier[variant],
          'focus:tw-outline-none',
          variant === 'clean' && 'tw-rounded-r-inherit',
          variant === 'market' && 'market',
          disabled && 'tw-bg-cool-gray-100',
        ])}
        disabled={disabled}
        as={as}
        {...props}
        ref={ref as any}
      />
    ),
);
