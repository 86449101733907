import React, { FC } from 'react';

export const IconSquareLogo: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_26818_7870)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M19.4543 18.1918C19.4543 18.8891 18.8886 19.454 18.192 19.454H5.75249C5.05512 19.454 4.48945 18.8891 4.48945 18.1918V5.75223C4.48945 5.05486 5.05512 4.48994 5.75249 4.48994H18.192C18.8886 4.48994 19.4543 5.05486 19.4543 5.75223V18.1918ZM19.9422 0.000488281H4.0016C1.79204 0.000488281 0 1.79178 0 4.00134V19.9426C0 22.1522 1.79204 23.9435 4.0016 23.9435H19.9422C22.1517 23.9435 23.9438 22.1522 23.9438 19.9426V4.00134C23.9438 1.79178 22.1517 0.000488281 19.9422 0.000488281Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M14.2166 8.979H9.72712C9.32607 8.979 8.97888 9.32544 8.97888 9.72725V14.2167C8.97888 14.6193 9.32607 14.9649 9.72712 14.9649H14.2166C14.6169 14.9649 14.9648 14.6193 14.9648 14.2167V9.72725C14.9648 9.32544 14.6169 8.979 14.2166 8.979Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_26818_7870">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
