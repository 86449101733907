import React, { FC } from 'react';

export const IconFranchiseReport: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M13.3332 10.0002H2.6665V8.66683H13.3332V10.0002ZM13.3332 11.3335H2.6665V12.6668H13.3332V11.3335ZM9.99984 7.3335L13.3332 4.96683V3.3335L9.99984 5.70016L6.6665 3.3335L2.6665 5.7735V7.3335L6.61317 4.92683L9.99984 7.3335Z"
      fill="currentColor"
    />
  </svg>
);
