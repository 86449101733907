import React, { FC } from 'react';

export const IconConnections: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      // eslint-disable-next-line max-len
      d="M15.6365 3.18177C15.6365 4.38673 16.6133 5.36354 17.8182 5.36354C19.0232 5.36354 20 4.38673 20 3.18177C20 1.97681 19.0232 1 17.8182 1C16.6133 1 15.6365 1.97681 15.6365 3.18177Z"
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M1.08959 8.99964C1.08959 10.2046 2.0664 11.1814 3.27136 11.1814C4.47631 11.1814 5.45312 10.2046 5.45312 8.99964C5.45312 7.79468 4.47631 6.81787 3.27136 6.81787C2.0664 6.81787 1.08959 7.79468 1.08959 8.99964Z"
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5469 8.99988L5.45608 8.99988" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      // eslint-disable-next-line max-len
      d="M15.6365 14.8182C15.6365 16.0232 16.6133 17 17.8182 17C19.0232 17 20 16.0232 20 14.8182C20 13.6133 19.0232 12.6365 17.8182 12.6365C16.6133 12.6365 15.6365 13.6133 15.6365 14.8182Z"
      stroke="#374151"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6367 3.18201C12.2427 3.18201 10.5459 6.31466 10.5459 9.00006C10.5459 11.6855 12.2427 14.8181 15.6367 14.8181"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
