import React, { FC } from 'react';

export const MarketIconDualRestore: FC<React.PropsWithChildren<unknown>> = () => (
  // @ts-ignore slot attribute is required for Market but isn't part of the React SVG type
  <svg slot="icon" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M6.10398 17.01C6.14398 16.97 6.17398 16.92 6.21398 16.88L7.52398 15.57C6.75398 14.54 6.33398 13.31 6.33398 12C6.33398 8.69004 9.02398 6.00004 12.334 6.00004H13.924L12.634 7.29004L14.044 8.70004L17.044 5.70004C17.434 5.31004 17.434 4.68004 17.044 4.29004L14.044 1.29004L12.634 2.70004L13.924 4.00004H12.334C7.92398 4.00004 4.33398 7.59004 4.33398 12C4.33398 13.85 4.95398 15.6 6.10398 17.01Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M18.334 12C18.334 15.31 15.644 18 12.334 18H10.744L12.034 16.71L10.624 15.3L7.62398 18.3C7.23398 18.69 7.23398 19.32 7.62398 19.71L10.624 22.71L12.034 21.3L10.744 20H12.334C16.744 20 20.334 16.41 20.334 12C20.334 10.15 19.714 8.40004 18.564 6.99004C18.524 7.03004 18.494 7.08004 18.454 7.12004L17.144 8.43004C17.914 9.46004 18.334 10.69 18.334 12Z"
      fill="currentColor"
    />
  </svg>
);
