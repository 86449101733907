type ColorKey = 'clean' | 'primary' | 'primaryLight' | 'danger' | 'secondary' | 'link' | 'accent';
type RoundedKey = 'clean' | 'full' | 'md';
type KindKey = 'clean' | 'button' | 'icon';
type SizeKey = 'clean' | 'normal' | 'small';

export enum ButtonRank {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum ButtonVariant {
  REGULAR = 'regular',
  DESTRUCTIVE = 'destructive',
}

export type ButtonOptions = {
  color?: ColorKey;
  rounded?: RoundedKey;
  kind?: KindKey;
  rank?: ButtonRank;
  variant?: ButtonVariant;
  disabled?: boolean;
  outline?: boolean;
  size?: SizeKey;
  loading?: boolean;
};

type ButtonColorScheme = Record<
  ColorKey,
  {
    default: {
      base: string;
      disabled?: string;
    };
    outline: {
      base: string;
      disabled?: string;
    };
  }
>;

const disabledStyle = 'tw-bg-cool-gray-100 tw-text-cool-gray-400 tw-cursor-not-allowed tw-pointer-events-auto';
const outlineDisabledStyle = 'tw-border-2 tw-border-cool-gray-100 tw-bg-cool-gray-100 tw-text-cool-gray-400 tw-cursor-not-allowed tw-pointer-events-auto';

const colorScheme: ButtonColorScheme = {
  clean: {
    default: {
      base: '',
      disabled: disabledStyle,
    },
    outline: {
      base: '',
      disabled: '',
    },
  },
  primary: {
    default: {
      base: 'tw-bg-light-blue-400 hover:tw-bg-light-blue-500 active:tw-bg-light-blue-700 tw-text-white tw-ring-light-blue-300',
      disabled: disabledStyle,
    },
    outline: {
      base:
        // eslint-disable-next-line max-len
        'tw-bg-transparent tw-border-2 tw-border-light-blue-400 tw-text-light-blue-400 focus:tw-ring-light-blue-300 hover:tw-border-light-blue-500 hover:tw-bg-light-blue-500 hover: hover:tw-text-white',
      disabled: outlineDisabledStyle,
    },
  },
  primaryLight: {
    default: {
      base: 'tw-bg-light-blue-50 focus:tw-bg-light-blue-100 tw-text-light-blue-400 tw-ring-light-blue-200 hover:tw-bg-light-blue-100',
      disabled: disabledStyle,
    },
    outline: {
      base:
        // eslint-disable-next-line max-len
        'tw-bg-transparent tw-border-2 tw-border-light-blue-100 tw-text-light-blue-400 focus:tw-ring-light-blue-300 hover:tw-bg-light-blue-100 hover:tw-text-light-blue-400 hover:tw-border-light-blue-100',
      disabled: outlineDisabledStyle,
    },
  },
  secondary: {
    default: {
      base: 'tw-bg-cool-gray-100 focus:tw-bg-cool-gray-200 tw-text-cool-gray-700 tw-ring-cool-gray-300 hover:tw-bg-cool-gray-200 hover:tw-text-blue-gray-900',
      disabled: disabledStyle,
    },
    outline: {
      base:
        // eslint-disable-next-line max-len
        'tw-bg-transparent tw-border-2 tw-border-cool-gray-300 tw-text-cool-gray-700 focus:tw-ring-cool-gray-200 hover:tw-bg-cool-gray-200 hover:tw-text-blue-gray-900 hover:tw-border-cool-gray-200',
      disabled: outlineDisabledStyle,
    },
  },
  accent: {
    default: {
      base: 'tw-text-light-blue-400 tw-bg-cool-gray-100 tw-ring-cool-gray-300 hover:tw-bg-cool-gray-200 hover:tw-text-light-blue-500',
      disabled: disabledStyle,
    },
    outline: {
      base:
        // eslint-disable-next-line max-len
        'tw-bg-transparent tw-border-2 tw-border-cool-gray-300 tw-text-light-blue-400 focus:tw-ring-cool-gray-200 hover:tw-bg-cool-gray-200 hover:tw-text-light-blue-500 hover:tw-border-cool-gray-200',
      disabled: outlineDisabledStyle,
    },
  },
  danger: {
    default: {
      base: 'tw-bg-red-500 focus:tw-bg-red-600 tw-text-white tw-ring-red-300 hover:tw-bg-red-600',
      disabled: disabledStyle,
    },
    outline: {
      base:
        'tw-bg-transparent tw-border-2 tw-border-red-400 tw-text-red-400 focus:tw-ring-red-300 hover:tw-bg-red-600 hover:tw-border-red-600 hover:tw-text-white',
      disabled: outlineDisabledStyle,
    },
  },
  link: {
    default: {
      base: 'tw-text-light-blue-400 focus:tw-ring-light-blue-300',
      disabled: 'tw-text-light-blue-300 tw-cursor-not-allowed tw-pointer-events-auto',
    },
    outline: {
      base: 'tw-bg-transparent tw-border-2 tw-border-light-blue-400 tw-text-light-blue-400 focus:tw-ring-light-blue-300',
    },
  },
};

const roundedMapping: Record<RoundedKey, string> = {
  clean: '',
  md: 'tw-rounded-md',
  full: 'tw-rounded-full',
};

const kindMapping: Record<KindKey, string> = {
  clean: '',
  button: 'tw-font-medium',
  icon: 'tw-h-8 tw-w-8 tw-justify-center',
};

const sizeMapping: Record<SizeKey, string> = {
  clean: '',
  small: 'tw-px-5 tw-py-2 ',
  normal: 'tw-px-5 tw-py-3',
};

export const getButtonClasses: (options: ButtonOptions) => string = ({ color, size, kind, rounded, disabled, outline }) => {
  const classList = [rounded && roundedMapping[rounded], kind && kindMapping[kind], kind === 'button' && size ? sizeMapping[size] : ''];

  const variant: 'outline' | 'default' = outline ? 'outline' : 'default';
  if (disabled && color) {
    if (colorScheme[color][variant].disabled) {
      classList.push(colorScheme[color][variant].disabled);
    } else {
      // if *disabled* variant was not provided in color scheme
      // then the button will have a 0.7 opacity value when in disabled state
      classList.push(colorScheme[color][variant].base);
      classList.push('tw-opacity-70');
    }
  } else if (color) {
    classList.push(colorScheme[color][variant].base);
  }

  return classList.filter(Boolean).join(' ');
};
