import React, { FC } from 'react';
import classNames from 'classnames';

export type StatusTagProps = {
  color: 'primary' | 'secondary' | 'danger';
  className?: string;
};

const colorMapping: { [key in StatusTagProps['color']]: string } = {
  primary: 'tw-bg-green-300 tw-text-green-500',
  secondary: 'tw-bg-orange-300 tw-text-orange-500',
  danger: 'tw-bg-red-300 tw-text-red-500',
};

export const StatusTag: FC<React.PropsWithChildren<StatusTagProps>> = ({ children, color, className }) => {
  return (
    <div className={classNames([colorMapping[color], children && 'tw-py-1 tw-px-2', className, 'tw-inline-block tw-bg-opacity-10 tw-rounded-full'])}>
      {children}
    </div>
  );
};
