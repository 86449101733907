import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Button } from '../button/Button';
import { IconCheck, IconDateSmall, IconHours, IconArrowDownSmall } from '../icons';
import { DatePickerInput } from './DatePicker';

export type DateRangeFilterStringsType = Partial<{
  apply: string;
  from: string;
  label: string;
  preselectListTitle: string;
  reset: string;
  timeRangeTitle: string;
  to: string;
}>;

export interface IDateRange {
  from: Date | null;
  to: Date | null;
  label?: string;
}

type Props = {
  period: IDateRange;
  onChange(dateRange: IDateRange): void;
  preselectedList: IDateRange[];
  customStrings?: DateRangeFilterStringsType;
};

const DEFAULT_STRINGS: Required<DateRangeFilterStringsType> = {
  apply: 'Apply',
  from: 'from',
  label: 'Date range',
  preselectListTitle: 'Relative time ranges',
  reset: 'Reset',
  timeRangeTitle: 'Absolute time range',
  to: 'To',
};
const CALENDAR = 'Calendar';
const DEFAULT_DATE_FORMAT = 'yyyy-LL-dd';
const DATE_PICKER_PROPS = {
  className: 'tw-text-cool-gray-400',
  inputClassName: 'tw-text-blue-gray-900',
  icon: <IconDateSmall />,
  showIcon: true,
  format: DEFAULT_DATE_FORMAT,
  outline: true,
  startDate: null,
};

const getFormattedDate = (date: Date | null) => (date ? DateTime.fromJSDate(date).toFormat(DEFAULT_DATE_FORMAT) : '-');

export const DateRangeFilter: React.FC<React.PropsWithChildren<Props>> = ({ period, onChange, preselectedList, customStrings }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarDateRange, setCalendarDateRange] = useState<IDateRange>(() => ({
    from: period.from,
    to: period.to,
  }));
  const isChangedFromDatePicker = useRef(period?.label === CALENDAR);
  const dateFilterRef = useRef<HTMLDivElement>(null);
  const strings = useMemo(() => ({ ...DEFAULT_STRINGS, ...customStrings }), [customStrings]);

  useEffect(() => {
    const handler = (event: any) => {
      if (!dateFilterRef?.current?.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const updateCalendarDateRange = (value: Partial<IDateRange>) => {
    isChangedFromDatePicker.current = true;
    setCalendarDateRange((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const choosePreselectedPeriod = (label: string) => {
    const selectedPeriod = preselectedList?.find((item) => item.label === label);
    isChangedFromDatePicker.current = false;
    if (selectedPeriod) {
      onChange(selectedPeriod);
      setShowCalendar(false);
      setCalendarDateRange(selectedPeriod);
    }
  };

  const handleApplyDateRange = () => {
    if (!isChangedFromDatePicker.current) return;

    const { from, to } = calendarDateRange;

    const obj: IDateRange = {
      label: CALENDAR,
      from,
      to,
    };

    onChange(obj);
    setShowCalendar(false);
    isChangedFromDatePicker.current = false;
  };

  const resetDateRange = () => {
    const obj: IDateRange = {
      label: strings.label,
      from: null,
      to: null,
    };
    setCalendarDateRange(obj);
    onChange(obj);
    setShowCalendar(false);
    isChangedFromDatePicker.current = false;
  };

  const getButtonLabel = () => {
    if (period.label === CALENDAR) {
      if (period.from && period.to) {
        return `${getFormattedDate(period.from)} to ${getFormattedDate(period.to)}`;
      } else if (period.from) {
        return `from ${getFormattedDate(period.from)}`;
      } else if (period.to) {
        return `to ${getFormattedDate(period.to)}`;
      }
    }
    return period.label || strings.label;
  };

  const isFilterApplied = !!(period.to || period.from);

  return (
    <div className="tw-flex tw-justify-end">
      <div ref={dateFilterRef} className="tw-inline-block tw-relative">
        <Button
          kind="clean"
          color="clean"
          rounded="md"
          className={classNames(
            'tw-text-base tw-border tw-border-cool-gray-200 tw-py-2 tw-px-3 tw-rounded-md hover:tw-bg-cool-gray-50 tw-transition-all tw-duration-200 tw-h-full',
          )}
          onClick={() => setShowCalendar(!showCalendar)}
        >
          <IconHours />
          <span className={classNames(['tw-block tw-ml-2.5 tw-mr-3', isFilterApplied && 'tw-text-blue-gray-900 tw-font-medium'])}>{getButtonLabel()}</span>
          <IconArrowDownSmall className={classNames([showCalendar && 'tw-transform tw-rotate-180'])} />
        </Button>
        {showCalendar && (
          <div
            className={classNames([
              'dropdown-container tw-z-1 tw-w-72 sm:tw-w-auto tw-absolute tw-top-12 tw-left-0 tw-border tw-border-cool-gray-200 tw-rounded-md',
              'tw-shadow tw-shadow-cool-gray-200 tw-bg-white sm:tw-flex tw-pl-2.5 sm:tw-pr-0 tw-pr-2.5 tw-overflow-auto sm:tw-overflow-visible',
            ])}
          >
            <div className="tw-relative sm:tw-w-80 tw-p-5 tw-pb-7 sm:tw-pr-16">
              <h4 className={'tw-font-bold'}>{strings.timeRangeTitle}</h4>
              <div className="tw-mb-4">
                <span className="tw-mb-1 tw-block tw-font-medium tw-text-xs">{strings.from}</span>
                <DatePickerInput
                  {...DATE_PICKER_PROPS}
                  value={calendarDateRange.from ?? new Date()}
                  onChange={(value) => updateCalendarDateRange({ from: value })}
                  startDate={calendarDateRange.from ?? undefined}
                  endDate={calendarDateRange.to ?? undefined}
                  selectsStart={true}
                  placeholder={strings.from}
                />
              </div>
              <div className="tw-mb-4">
                <span className="tw-mb-1 tw-block tw-font-medium tw-text-xs">{strings.to}</span>
                <DatePickerInput
                  {...DATE_PICKER_PROPS}
                  minDate={calendarDateRange.from ?? undefined}
                  value={calendarDateRange.to ?? new Date()}
                  onChange={(value) => updateCalendarDateRange({ to: value })}
                  startDate={calendarDateRange.from ?? undefined}
                  endDate={calendarDateRange.to ?? undefined}
                  selectsEnd={true}
                  placeholder={strings.to}
                />
              </div>
              <div className="tw-flex">
                <Button className="tw-mr-2" onClick={handleApplyDateRange} disabled={!isChangedFromDatePicker.current}>
                  {strings.apply}
                </Button>
                <Button onClick={resetDateRange} color="secondary" disabled={!isFilterApplied}>
                  {strings.reset}
                </Button>
              </div>
            </div>
            {preselectedList && (
              <div className="sm:tw-w-52 tw-p-5 sm:tw-border-l sm:tw-border-cool-gray-200 sm:tw-overflow-auto">
                <div className="tw-mb-4 last:tw-mb-0">
                  <h4 className={'tw-font-bold'}>{strings.preselectListTitle}</h4>
                  <ul className="tw-list-none tw-m-0 tw-p-0">
                    {preselectedList.map((item) => {
                      const isChecked = item.label === period.label;
                      return (
                        <li
                          className="tw-flex tw-justify-between tw-items-center tw-px-2.5 tw-cursor-pointer tw-font-medium tw-py-1.5 hover:tw-bg-cool-gray-100"
                          key={item.label}
                          onClick={() => choosePreselectedPeriod(item.label ?? '')}
                        >
                          <span>{item.label}</span>
                          {isChecked && <IconCheck />}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
