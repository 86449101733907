import * as React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

type DefaultProps = {
  onDragEnd?(result: DropResult, provided: ResponderProvided): void;
};

export const DragDropProvider: React.FC<React.PropsWithChildren<React.PropsWithChildren<DefaultProps>>> = ({ onDragEnd, children }) =>
  onDragEnd ? <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext> : <>{children}</>;

type DroppableProviderProps = {
  isDroppable: boolean;
  children(provided?: DroppableProvided): React.ReactElement;
};

export const DroppableProvider: React.FC<DroppableProviderProps> = ({ isDroppable = false, children }) =>
  isDroppable ? <Droppable droppableId="droppable-table">{(provided) => children(provided)}</Droppable> : children();

type DraggableProviderProps = {
  isDraggable: boolean;
  draggableId: string;
  index: number;
  children(provided?: DraggableProvided, snapshot?: DraggableStateSnapshot): React.ReactElement;
};

export const DraggableProvider: React.FC<DraggableProviderProps> = ({ isDraggable = false, draggableId, index, children }) =>
  isDraggable ? (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => children(provided, snapshot)}
    </Draggable>
  ) : (
    children()
  );

export const getDraggableProps = (provided?: DraggableProvided) => {
  if (provided) {
    return {
      ...provided.draggableProps,
      ...provided.dragHandleProps,
      innerRef: provided.innerRef,
    };
  }

  return {};
};
