import React, { FC } from 'react';

export const IconLocationGroup: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27408_13877)">
      <path
        // eslint-disable-next-line max-len
        d="M8.62207 11.7136C8.99546 12.0294 9.39914 12.3471 9.83313 12.6668C12.9465 10.3735 14.4998 8.18016 14.4998 6.10016C14.4998 2.9735 12.0131 1.3335 9.83313 1.3335C9.55585 1.3335 9.27361 1.36003 8.99216 1.41277C9.72499 1.7239 10.4044 2.20933 10.9315 2.86369C12.0389 3.25204 13.1665 4.24454 13.1665 6.10016C13.1665 7.54016 12.0198 9.2135 9.83313 10.9802C9.75493 10.917 9.67807 10.8539 9.60254 10.791C9.30369 11.0967 8.97687 11.4043 8.62207 11.7136Z"
        fill="currentColor"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.99968 4.6665C5.26634 4.6665 4.66634 5.2665 4.66634 5.99984C4.66634 6.73317 5.26634 7.33317 5.99968 7.33317C6.3533 7.33317 6.69244 7.1927 6.94248 6.94265C7.19253 6.6926 7.33301 6.35346 7.33301 5.99984C7.33301 5.64622 7.19253 5.30708 6.94248 5.05703C6.69244 4.80698 6.3533 4.6665 5.99968 4.6665ZM1.33301 13.3332H10.6663V14.6665H1.33301V13.3332Z"
        fill="currentColor"
      />
      <path
        // eslint-disable-next-line max-len
        d="M5.99968 2.66683C7.28634 2.66683 9.33301 3.60016 9.33301 6.10016C9.33301 7.54016 8.18634 9.2135 5.99968 10.9802C3.81301 9.2135 2.66634 7.5335 2.66634 6.10016C2.66634 3.60016 4.71301 2.66683 5.99968 2.66683ZM5.99968 1.3335C3.81968 1.3335 1.33301 2.9735 1.33301 6.10016C1.33301 8.18016 2.88634 10.3735 5.99968 12.6668C9.11301 10.3735 10.6663 8.18016 10.6663 6.10016C10.6663 2.9735 8.17968 1.3335 5.99968 1.3335Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_27408_13877">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
