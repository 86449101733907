import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  seoTitle: string;
};

export const SeoTitle: FC<React.PropsWithChildren<Props>> = ({ seoTitle }) => (
  <Helmet>
    <title>{`GoParrot | ${seoTitle}`}</title>
  </Helmet>
);
