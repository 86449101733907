import React, { FC } from 'react';
import classNames from 'classnames';

interface IconProps {
  className?: string;
}

export const AlertOctagon: FC<React.PropsWithChildren<IconProps>> = ({ className }) => (
  <svg className={classNames([className])} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.93 1H8.07L11 3.93V8.07L8.07 11H3.93L1 8.07V3.93L3.93 1Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 4V6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 8H6.005" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
