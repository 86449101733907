import React, { FC } from 'react';

export const IconPaymentLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M33.4688 7.875H8.53125C5.99419 7.875 3.9375 9.93169 3.9375 12.4688V29.5312C3.9375 32.0683 5.99419 34.125 8.53125 34.125H33.4688C36.0058 34.125 38.0625 32.0683 38.0625 29.5312V12.4688C38.0625 9.93169 36.0058 7.875 33.4688 7.875Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M38.0625 15.75H5.20898H36.709M10.5 24.6094H14.4375V26.25H10.5V24.6094Z" stroke="#0F172A" strokeWidth="4" strokeLinejoin="round" />
  </svg>
);
