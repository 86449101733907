import React, { FC } from 'react';

export const IconRestaurantLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M4.71642 3.91626L34.9392 34.139C35.5127 34.7125 35.8348 35.4904 35.8348 36.3014C35.8348 37.1124 35.5127 37.8902 34.9392 38.4637V38.4637C34.3656 39.037 33.5878 39.359 32.7769 39.359C31.9659 39.359 31.1881 39.037 30.6145 38.4637L23.2317 30.9538C22.7485 30.4632 22.4775 29.8024 22.477 29.1138V28.6602C22.4771 28.3125 22.4081 27.9683 22.274 27.6475C22.14 27.3267 21.9435 27.0357 21.6961 26.7915L20.7429 25.9113C20.4193 25.6127 20.0258 25.4003 19.5986 25.2937C19.1714 25.187 18.7243 25.1895 18.2983 25.301V25.301C17.6266 25.4763 16.9208 25.4729 16.2508 25.2912C15.5808 25.1094 14.97 24.7556 14.479 24.2649L7.47103 17.2562C3.31369 13.0988 1.7838 6.82099 4.71642 3.91626Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M16.406 30.1875L8.22588 38.4136C7.61056 39.0287 6.77611 39.3743 5.90604 39.3743C5.03597 39.3743 4.20152 39.0287 3.58619 38.4136V38.4136C2.97105 37.7983 2.62549 36.9638 2.62549 36.0938C2.62549 35.2237 2.97105 34.3892 3.58619 33.7739L10.4998 26.9062M32.8123 2.625L26.4754 8.96191C25.9877 9.44944 25.6009 10.0282 25.337 10.6653C25.0731 11.3023 24.9373 11.9851 24.9373 12.6746V13.8936C24.9373 14.0661 24.9034 14.2369 24.8373 14.3962C24.7713 14.5556 24.6746 14.7003 24.5526 14.8222L23.6248 15.75L32.8123 2.625ZM26.2498 18.375L27.1776 17.4472C27.2995 17.3252 27.4442 17.2285 27.6036 17.1624C27.7629 17.0964 27.9337 17.0625 28.1062 17.0625H29.3251C30.0147 17.0625 30.6975 16.9267 31.3345 16.6628C31.9715 16.3989 32.5503 16.012 33.0379 15.5244L39.3748 9.1875L26.2498 18.375ZM36.0935 5.90625L29.531 12.4688L36.0935 5.90625Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
