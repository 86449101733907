import * as React from 'react';
import classNames from 'classnames';

export type TagProps = {
  text: string | React.ReactNode;
  borderColorClass?: string;
  textColor?: string;
  bgColorClass?: string;
  borderRadius?: string;
};

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({ text, bgColorClass, borderColorClass, textColor, borderRadius }) => (
  <span
    className={classNames([
      'tw-px-2 tw-py-0.5 tw-mr-1 tw-my-0.5 tw-whitespace-nowrap tw-inline-block tw-leading-5 tw-text-xs',
      textColor ?? 'tw-text-white',
      bgColorClass ?? 'tw-bg-blue-400',
      borderColorClass ?? 'tw-border-transparent',
      borderRadius ?? 'tw-rounded',
    ])}
  >
    {text}
  </span>
);
