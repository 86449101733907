import React, { FC, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { Toaster, MarketNotification, NotificationOptions } from '../components/toaster';

export type MarketNotifier = (options: NotificationOptions) => void;

type NotificationProviderProps = {
  notifySuccess: MarketNotifier;
  notifyError: MarketNotifier;
  notifyWarning: MarketNotifier;
  notifyInfo: MarketNotifier;
};

let _exportableContext: NotificationProviderProps;

export const MarketNotificationProvider: FC<React.PropsWithChildren<unknown>> = () => {
  const [notifications, setNotifications] = useState<MarketNotification[]>([]);

  const addNotification = (notification: MarketNotification) => setNotifications((prevState) => [...prevState, notification]);
  const notifySuccess = (options: NotificationOptions) => addNotification({ type: 'success', id: uniqueId(), ...options });
  const notifyError = (options: NotificationOptions) => addNotification({ type: 'critical', id: uniqueId(), ...options });
  const notifyWarning = (options: NotificationOptions) => addNotification({ type: 'warning', id: uniqueId(), ...options });
  const notifyInfo = (options: NotificationOptions) => addNotification({ type: 'info', id: uniqueId(), ...options });

  useEffect(() => {
    _exportableContext = {
      notifyError,
      notifySuccess,
      notifyWarning,
      notifyInfo,
    };
  }, []);

  return <Toaster notifications={notifications} />;
};

export const getNotificationProvider = () => _exportableContext;
export const useNotifications = () => _exportableContext;
