import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { FC } from 'react';
import { HexColorPicker } from 'react-colorful';
import { ColorPickerBaseProps } from 'react-colorful/dist/types';

import { Button } from '../button/Button';
import { Menu } from '../menu/Menu';

export type ColorPickerProps = ColorPickerBaseProps<string> & {
  disclosureWidth?: string;
  disclosureHeight?: string;
  disabled?: boolean;
};

export const ColorPicker: FC<React.PropsWithChildren<ColorPickerProps>> = ({
  disclosureWidth = 'tw-w-6',
  disclosureHeight = 'tw-h-6',
  color,
  onChange = () => undefined,
  disabled = false,
}) => {
  const onChangeDebounced = debounce((color: string) => {
    onChange(color);
  }, 250);

  return (
    <div>
      <Menu
        menuProps={{ 'aria-label': 'Custom menu' }}
        closeMenuOnItemClick={false}
        className="tw-z-1"
        disclosure={
          <Button
            kind="clean"
            color="clean"
            rounded="clean"
            style={{ backgroundColor: color }}
            disabled={disabled}
            className={classNames([disclosureWidth, disclosureHeight, 'tw-shadow tw-rounded tw-border tw-border-gray-200'])}
          >
            &nbsp;
          </Button>
        }
        menuItems={[
          <Menu.MenuItem key="1" noPadding type="button">
            <HexColorPicker color={color} onChange={onChangeDebounced} />
          </Menu.MenuItem>,
        ]}
      />
    </div>
  );
};
