import React, { FC } from 'react';

export const IconPromotions: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M12.7998 7.2002C12.7998 7.64686 12.7998 8.08686 12.7998 8.53353C13.5998 8.53353 14.6398 8.53353 15.4665 8.53353C15.4665 8.08686 15.4665 7.64686 15.4665 7.2002C14.6398 7.2002 13.5998 7.2002 12.7998 7.2002Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11.4668 12.5401C12.1068 13.0135 12.9401 13.6401 13.6001 14.1335C13.8668 13.7801 14.1335 13.4201 14.4001 13.0668C13.7401 12.5735 12.9068 11.9468 12.2668 11.4668C12.0001 11.8268 11.7335 12.1868 11.4668 12.5401Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M14.1335 2.66676C13.8669 2.31343 13.6002 1.95343 13.3335 1.6001C12.6735 2.09343 11.8402 2.7201 11.2002 3.2001C11.4669 3.55343 11.7335 3.91343 12.0002 4.26676C12.6402 3.78676 13.4735 3.16676 14.1335 2.66676Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M3.05464 5.41558C2.25464 5.41558 1.6001 6.0802 1.6001 6.8925V8.36943C1.6001 9.18173 2.25464 9.84635 3.05464 9.84635H3.78192V12.8002H5.23646V9.84635H5.96373L9.6001 12.0617V3.2002L5.96373 5.41558H3.05464ZM6.71282 6.67835L8.14555 5.80696V9.45496L6.71282 8.58358L6.36373 8.36943H5.96373H3.05464V6.8925H5.96373H6.36373L6.71282 6.67835Z"
      fill="currentColor"
    />
    <path d="M11.1999 7.60399C11.1999 6.49069 10.8906 5.4862 10.3999 4.7998V10.3998C10.8906 9.72178 11.1999 8.71729 11.1999 7.60399Z" fill="currentColor" />
  </svg>
);
