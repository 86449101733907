import React, { FC } from 'react';

export const IconMerchant: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M14.6 5.92667L13.9 3.01333C13.7533 2.41333 13.2333 2 12.6267 2H3.36667C2.76667 2 2.24 2.42 2.1 3.01333L1.4 5.92667C1.24 6.60667 1.38667 7.3 1.81334 7.84667C1.86667 7.92 1.94 7.97333 2 8.04V12.6667C2 13.4 2.6 14 3.33334 14H12.6667C13.4 14 14 13.4 14 12.6667V8.04C14.06 7.98 14.1333 7.92 14.1867 7.85333C14.6133 7.30667 14.7667 6.60667 14.6 5.92667ZM12.6067 3.32667L13.3067 6.24C13.3733 6.52 13.3133 6.8 13.14 7.02C13.0467 7.14 12.8467 7.33333 12.5133 7.33333C12.1067 7.33333 11.7533 7.00667 11.7067 6.57333L11.32 3.33333L12.6067 3.32667ZM8.66667 3.33333H9.97334L10.3333 6.34667C10.3667 6.60667 10.2867 6.86667 10.1133 7.06C9.96667 7.23333 9.75334 7.33333 9.48001 7.33333C9.03334 7.33333 8.66667 6.94 8.66667 6.46V3.33333ZM5.66 6.34667L6.02667 3.33333H7.33334V6.46C7.33334 6.94 6.96667 7.33333 6.47334 7.33333C6.24667 7.33333 6.04 7.23333 5.88 7.06C5.71334 6.86667 5.63334 6.60667 5.66 6.34667ZM2.69334 6.24L3.36667 3.33333H4.68L4.29334 6.57333C4.24 7.00667 3.89334 7.33333 3.48667 7.33333C3.16 7.33333 2.95334 7.14 2.86667 7.02C2.68667 6.80667 2.62667 6.52 2.69334 6.24ZM3.33334 12.6667V8.64667C3.38667 8.65333 3.43334 8.66667 3.48667 8.66667C4.06667 8.66667 4.59334 8.42667 4.98 8.03333C5.38 8.43333 5.91334 8.66667 6.52 8.66667C7.1 8.66667 7.62 8.42667 8.00667 8.04667C8.4 8.42667 8.93334 8.66667 9.53334 8.66667C10.0933 8.66667 10.6267 8.43333 11.0267 8.03333C11.4133 8.42667 11.94 8.66667 12.52 8.66667C12.5733 8.66667 12.62 8.65333 12.6733 8.64667V12.6667H3.33334Z"
      fill="currentColor"
    />
  </svg>
);
