import React, { FC } from 'react';
import classNames from 'classnames';

export const IconImagePlaceholder: FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <svg className={className} width="50" height="42" viewBox="0 0 50 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={classNames([
        'M43.4615 1H6.53846C3.47965 1 1 3.44208 1 6.45455V35.5455C1 38.5579',
        '3.47965 41 6.53846 41H43.4615C46.5203 41 49 38.5579 49 35.5455V6.45455C49 3.44208 46.5203 1 43.4615 1Z',
      ])}
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d={classNames([
        'M34.2294 15.5455C36.2686 15.5455 37.9217 13.9174 37.9217 11.9091C37.9217 9.90079 36.2686 8.27272',
        '34.2294 8.27272C32.1902 8.27272 30.5371 9.90079 30.5371 11.9091C30.5371 13.9174 32.1902 15.5455 34.2294 15.5455Z',
      ])}
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d={classNames([
        'M30.9538 30.5L20.0777 19.7841C19.412 19.1287 18.5174 18.7484 17.5765 18.7209C16.6356 18.6935 15.7197 19.0209 15.0158 19.6364L1 ',
        '31.9091M21.3077 41L35.5392 26.9841C36.1901 26.3418 37.0612 25.9624 37.9816 25.9204C38.902 25.8783 39.805 26.1767 40.5135 26.7568L49 33.7273',
      ])}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
