import classNames from 'classnames';
import React, { FC } from 'react';
import {
  components,
  OptionProps,
  ContainerProps,
  ControlProps,
  PlaceholderProps,
  IndicatorProps,
  ValueContainerProps,
  SingleValueProps,
  MultiValueProps,
  MenuProps as MenuBaseProps,
  InputProps,
} from 'react-select';
import { MenuPortalProps, MenuState } from 'react-select/src/components/Menu';

import { Checkbox } from '../checkbox';

import { SelectOptionType } from './types';

const SelectContainer: FC<React.PropsWithChildren<ContainerProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  <components.SelectContainer {...props} className="focus:tw-outline-none tw-w-full tw-rounded-r-inherit">
    {children}
  </components.SelectContainer>
);

const Control: FC<React.PropsWithChildren<ControlProps<SelectOptionType, true | false>>> = ({ children, isFocused, isDisabled, ...props }) => (
  <components.Control
    {...props}
    isFocused={isFocused}
    isDisabled={isDisabled}
    className={classNames([
      'tw-outline-none tw-w-full tw-rounded-none tw-shadow-none tw-border tw-rounded-r-inherit',
      isFocused ? 'tw-border-light-blue-400' : 'tw-border-transparent',
      isDisabled && 'tw-bg-gray-100',
    ])}
  >
    <div className="tw-flex tw-w-full tw-items-center">
      {props?.selectProps?.appendIcon ? <span className="tw-pl-4 tw--mb-1">{props?.selectProps?.appendIcon}</span> : null}
      {children}
    </div>
  </components.Control>
);
const Placeholder: FC<React.PropsWithChildren<PlaceholderProps<SelectOptionType, true | false>>> = (props) => (
  <components.Placeholder {...props} className={props.isDisabled ? '' : props?.selectProps?.placeholderColor ?? 'tw-text-light-blue-500'} />
);
const DropdownIndicator: FC<React.PropsWithChildren<IndicatorProps<SelectOptionType, true | false>>> = (props) =>
  !props.isDisabled ? (
    <components.DropdownIndicator
      {...props}
      className={classNames(['tw-text-light-blue-500', props.isMulti && props.getValue()?.length > 0 && 'tw-absolute tw-right-0 tw-self-start tw-py-3'])}
    />
  ) : null;

const ValueContainer: FC<React.PropsWithChildren<ValueContainerProps<SelectOptionType, true | false>>> = ({ children, ...props }) => {
  if (props.isMulti && props.getValue()?.length > 0) {
    const { renderMultiValueLabel, selectedValuesMaxCount } = props?.selectProps;
    const [chips, element] = children as any;
    // this allows us to display a given {selectedValuesMaxCount} number of selected options
    const displayChips =
      selectedValuesMaxCount !== undefined && selectedValuesMaxCount < chips.length
        ? [chips.slice(chips.length - selectedValuesMaxCount, chips.length), element]
        : children;
    const hasMoreStores = chips.length > selectedValuesMaxCount;
    return (
      <div className={classNames([props?.selectProps?.appendIcon ? 'tw-pr-3' : 'tw-px-3', 'tw-text-light-blue-400 tw-flex-1'])}>
        <div className="tw-border-b tw-border-cool-gray-200 tw-py-3 tw-mb-2">{renderMultiValueLabel(props.getValue())}</div>
        <components.ValueContainer {...props} className="tw-bg-transparent tw-px-0 tw-pt-0">
          {displayChips}
          {hasMoreStores && <span className="tw-text-blue-gray-900 tw-pt-2.5 tw-mr-32">...</span>}
        </components.ValueContainer>
      </div>
    );
  }
  return (
    <components.ValueContainer
      {...props}
      className={classNames(['select-input-wrapper', props?.selectProps?.appendIcon ? 'tw-pr-3' : 'tw-px-3', 'tw-bg-transparent tw-text-light-blue-400'])}
    >
      {children}
    </components.ValueContainer>
  );
};

const SingleValue: FC<React.PropsWithChildren<SingleValueProps<SelectOptionType>>> = ({ children, ...props }) => (
  <components.SingleValue
    {...props}
    className={classNames([props.isDisabled ? 'tw-text-gray-600' : props?.selectProps?.selectedValueColor ?? 'tw-text-light-blue-400'])}
  >
    {children}
  </components.SingleValue>
);
const MultiValue: FC<React.PropsWithChildren<MultiValueProps<SelectOptionType>>> = (props) => {
  const { readOnly } = props?.selectProps;
  const showRemoveIcon = readOnly ? false : !props.isDisabled;
  return (
    <div className="lg:tw-whitespace-nowrap tw-py-1 tw-px-2 tw-mr-2 tw-my-1 tw-text-blue-gray-900 tw-bg-cool-gray-100 tw-text-sm tw-flex tw-items-center">
      {props.data.label}
      {showRemoveIcon && (
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props.removeProps}
          className="tw-ml-1 hover:tw-text-light-blue-500"
        >
          <path d="M10.3333 1.33331L1 10.6666" stroke="currentColor" />
          <path d="M0.998698 1.33331L10.332 10.6666" stroke="currentColor" />
        </svg>
      )}
    </div>
  );
};

export type BaseMenuProps = MenuBaseProps<SelectOptionType, true | false> & {
  readonly placement: MenuState['placement'];
};

const Menu: FC<React.PropsWithChildren<BaseMenuProps>> = ({ children, ...props }) => {
  const { staticDropdown } = props?.selectProps;

  return (
    <components.Menu
      {...props}
      className={classNames([
        props.placement === 'top' ? 'tw-border-t tw-border-l tw-border-r tw-rounded-b-none' : 'tw-rounded-t-none tw-border-b tw-border-l tw-border-r',
        'menu tw-mt-0 tw-mb-0 tw-border-light-blue-400 tw-shadow-none',
        'hover:tw-cursor-pointer tw-z-20 tw-transition-all tw-ease-in-out',
        staticDropdown && 'tw-static',
      ])}
    >
      {children}
    </components.Menu>
  );
};

const MenuPortal: FC<React.PropsWithChildren<MenuPortalProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  // this z-index makes select visible inside Modal
  <components.MenuPortal {...props} className={classNames(['tw-z-max', props.className])}>
    {children}
  </components.MenuPortal>
);

const Option: FC<React.PropsWithChildren<OptionProps<SelectOptionType, true | false>>> = ({ children, isFocused, isSelected, isDisabled, ...props }) => {
  const { readOnly } = props?.selectProps;

  return (
    <components.Option
      {...props}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      className={classNames([
        isDisabled || readOnly
          ? 'tw-bg-blue-gray-100 tw-cursor-not-allowed tw-text-blue-gray-600'
          : 'tw-text-blue-gray-900 hover:tw-bg-light-blue-100 tw-transition-colors tw-ease-in-out tw-cursor-pointer',
        (isFocused || isSelected) && !isDisabled && 'tw-bg-light-blue-50',
        isSelected && 'tw-font-medium',
      ])}
    >
      {props.isMulti && <Checkbox checked={isSelected} disabled={isDisabled || readOnly} className="tw-mr-3 tw-pointer-events-none" />}
      {children}
    </components.Option>
  );
};

const Input: FC<
  React.PropsWithChildren<
    InputProps & {
      selectProps: any;
    }
  >
> = (props) => <components.Input {...props} className={classNames([props?.selectProps?.isMulti && props?.selectProps?.value?.length > 0 && 'tw-mb-2'])} />;

const ClearIndicator: FC<React.PropsWithChildren<IndicatorProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  <components.ClearIndicator {...props} className={classNames(['tw-cursor-pointer'])}>
    {children}
  </components.ClearIndicator>
);

export const BaseSelectComponents = {
  SelectContainer,
  ValueContainer,
  IndicatorSeparator: () => null,
  ClearIndicator,
  DropdownIndicator,
  Placeholder,
  SingleValue,
  MultiValue,
  Control,
  Menu,
  MenuPortal,
  Option,
  Input,
};
