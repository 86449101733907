import React, { FC } from 'react';

interface IconProps {
  className?: string;
  width?: number;
  height?: number;
}

export const AlertRounded: FC<React.PropsWithChildren<IconProps>> = ({ className, width = 20, height = 20 }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M10.5 2.1875C6.19219 2.1875 2.6875 5.69219 2.6875 10C2.6875 14.3078 6.19219 17.8125 10.5 17.8125C14.8078 17.8125 18.3125 14.3078 18.3125 10C18.3125 5.69219 14.8078 2.1875 10.5 2.1875ZM10.5 5.39062C10.7009 5.39062 10.8972 5.45019 11.0643 5.56179C11.2313 5.67339 11.3614 5.83201 11.4383 6.01759C11.5152 6.20317 11.5353 6.40738 11.4961 6.60439C11.4569 6.8014 11.3602 6.98237 11.2182 7.12441C11.0761 7.26644 10.8952 7.36317 10.6981 7.40236C10.5011 7.44155 10.2969 7.42144 10.1113 7.34457C9.92576 7.2677 9.76714 7.13752 9.65554 6.9705C9.54394 6.80348 9.48438 6.60712 9.48438 6.40625C9.48438 6.13689 9.59138 5.87856 9.78184 5.68809C9.97231 5.49763 10.2306 5.39063 10.5 5.39062V5.39062ZM12.375 14.2188H8.9375C8.77174 14.2188 8.61277 14.1529 8.49556 14.0357C8.37835 13.9185 8.3125 13.7595 8.3125 13.5938C8.3125 13.428 8.37835 13.269 8.49556 13.1518C8.61277 13.0346 8.77174 12.9688 8.9375 12.9688H10.0312V9.53125H9.40625C9.24049 9.53125 9.08152 9.4654 8.96431 9.34819C8.8471 9.23098 8.78125 9.07201 8.78125 8.90625C8.78125 8.74049 8.8471 8.58152 8.96431 8.46431C9.08152 8.3471 9.24049 8.28125 9.40625 8.28125H10.6562C10.822 8.28125 10.981 8.3471 11.0982 8.46431C11.2154 8.58152 11.2812 8.74049 11.2812 8.90625V12.9688H12.375C12.5408 12.9688 12.6997 13.0346 12.8169 13.1518C12.9342 13.269 13 13.428 13 13.5938C13 13.7595 12.9342 13.9185 12.8169 14.0357C12.6997 14.1529 12.5408 14.2188 12.375 14.2188Z"
      fill="currentColor"
    />
  </svg>
);
