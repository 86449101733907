import React, { FC } from 'react';

export const IconLoading: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0.5V3.1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 10.8999V13.4999" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.4043 2.40454L4.2438 4.24404" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.75635 9.7561L11.5958 11.5956" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.5 7H3.1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.8989 7H13.4989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.4043 11.5956L4.2438 9.7561" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.75635 4.24404L11.5958 2.40454" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
