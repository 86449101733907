import React from 'react';

import { FormField } from './form-field/FormField';
import { OutlinedFormField } from './outlined-form-field/OutlinedFormField';
import { LabelWithTooltip } from './label-with-tooltip/LabelWithTooltip';

export const Form: React.FC<React.PropsWithChildren<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>>> & {
  FormField: typeof FormField;
  OutlinedFormField: typeof OutlinedFormField;
  LabelWithTooltip: typeof LabelWithTooltip;
} = ({ children, ...props }) => <form {...props}>{children}</form>;

Form.FormField = FormField;
Form.OutlinedFormField = OutlinedFormField;
Form.LabelWithTooltip = LabelWithTooltip;
