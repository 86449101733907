import React from 'react';
import { MarketDialog, MarketFooter, MarketButton, MarketButtonGroup } from '@market/react-bindings';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { DialogStateReturn } from './DialogStateReturn';
import { InModal } from './InModal';

type Props = {
  dialog: DialogStateReturn;
  onCancel?: () => void;
  confirmButtonLabel?: string;
  handleConfirm: () => void;
  title?: string;
  dialogText?: string | JSX.Element;
  isConfirming?: boolean;
  icon?: JSX.Element;
  critical?: boolean;
};

export const MarketConfirmModal: React.FC<React.PropsWithChildren<Props>> = ({
  dialog,
  handleConfirm,
  onCancel,
  dialogText,
  title,
  isConfirming,
  confirmButtonLabel,
  icon,
  critical,
}) => {
  const { visible, dialogRef, dismiss } = dialog;

  const handleCancel = () => {
    dismiss();
    onCancel?.();
  };

  return visible ? (
    <InModal>
      <MarketDialog onMarketDialogDismissed={handleCancel} ref={dialogRef} className="tw-overflow-visible">
        <main className="tw-overflow-visible">
          {icon}
          <h2>{title}</h2>
          <p className="paragraph-30">{dialogText}</p>
        </main>
        <MarketFooter>
          <MarketButtonGroup alignment="fill">
            <MarketButton variant={critical ? 'destructive' : 'regular'} rank="primary" onClick={handleConfirm} isLoading={isConfirming || undefined}>
              {confirmButtonLabel || getIntlString('button.confirm')}
            </MarketButton>
            <MarketButton variant={critical ? 'destructive' : 'regular'} rank="secondary" onClick={handleCancel}>
              {getIntlString('button.cancel')}
            </MarketButton>
          </MarketButtonGroup>
        </MarketFooter>
      </MarketDialog>
    </InModal>
  ) : null;
};
