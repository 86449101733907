import * as React from 'react';
import classNames from 'classnames';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

type Props = {
  content: React.ReactNode;
} & DropzoneOptions;

export const Upload: React.FC<Props> = ({ content, ...dropProps }) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone(dropProps);

  return (
    <div className="tw-w-full">
      <div
        {...getRootProps()}
        className={classNames(['tw-full tw-flex tw-items-center tw-justify-center tw-py-4 tw-rounded', isDragActive ? 'tw-bg-gray-100' : 'tw-bg-gray-50'])}
      >
        <input {...getInputProps()} />
        {content}
      </div>
    </div>
  );
};
