import React, { FC } from 'react';
import { Button as RButton, ButtonProps as RButtonProps } from 'reakit/Button';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { IconLoading } from '../icons';

import { ButtonOptions, getButtonClasses } from './buttonUtils';

export type ButtonProps = {
  children: React.ReactNode | string;
  noFocusOutline?: boolean;
} & ButtonOptions &
  RButtonProps;

export const Button: FC<React.PropsWithChildren<ButtonProps>> = React.forwardRef(
  (
    {
      children,
      color = 'primary',
      rounded = 'md',
      kind = 'button',
      noFocusOutline = false,
      className,
      disabled,
      loading = false,
      size = 'normal',
      outline,
      ...props
    },
    ref,
  ) => (
    <RButton
      className={classNames([
        noFocusOutline ? 'focus:tw-ring-0' : 'focus:tw-ring-2',
        `tw-fill-current tw-flex tw-items-center tw-justify-center focus:tw-outline-none tw-transition-all tw-ease-in-out`,
        getButtonClasses({
          color,
          rounded,
          kind: kind,
          disabled: disabled || loading,
          outline,
          size,
        }),
        className,
      ])}
      ref={ref}
      disabled={disabled || loading}
      {...props}
    >
      <CSSTransition
        timeout={150}
        classNames={{
          enter: 'tw-w-0 tw-mr-0',
          enterActive: 'tw-w-6 tw-mr-1',
          enterDone: 'tw-w-6 tw-mr-1',
          exit: 'tw-w-0 tw-mr-0',
          exitActive: 'tw-w-0 tw-mr-0',
        }}
        in={loading}
        mountOnEnter
        unmountOnExit
      >
        <IconLoading className={classNames(['tw-transition-all tw-animate-spin'])} />
      </CSSTransition>

      {children}
    </RButton>
  ),
);
