import React, { FC } from 'react';
import classNames from 'classnames';
import { ValueContainerProps, components, ControlProps, MultiValueProps, PlaceholderProps, ContainerProps } from 'react-select';

import { SelectOptionType } from '../select/types';

const SelectContainer: FC<React.PropsWithChildren<ContainerProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  <components.SelectContainer {...props} className="focus:tw-outline-none tw-w-full">
    {children}
  </components.SelectContainer>
);

const ValueContainer: FC<React.PropsWithChildren<ValueContainerProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  <div className="tw-text-light-blue-400 tw-px-3 tw-flex-1">
    <components.ValueContainer {...props} className="tw-bg-transparent tw-px-0 tw-py-0">
      {children}
    </components.ValueContainer>
  </div>
);

const MultiValue: FC<React.PropsWithChildren<MultiValueProps<SelectOptionType>>> = (props) => (
  <div className="tw-py-1 tw-px-2 tw-m-1 tw-text-blue-gray-900 tw-bg-cool-gray-100 tw-text-sm tw-flex tw-items-center">
    {props.data.label}
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props.removeProps}
      className="tw-ml-1 hover:tw-text-light-blue-500"
    >
      <path d="M10.3333 1.33331L1 10.6666" stroke="currentColor" />
      <path d="M0.998698 1.33331L10.332 10.6666" stroke="currentColor" />
    </svg>
  </div>
);

const Control: FC<React.PropsWithChildren<ControlProps<SelectOptionType, true | false>>> = ({ children, isFocused, isDisabled, ...props }) => (
  <components.Control
    {...props}
    isFocused={isFocused}
    isDisabled={isDisabled}
    className={classNames([
      'tw-outline-none tw-w-full tw-rounded-none tw-shadow-none tw-border',
      isFocused ? 'tw-border-light-blue-400' : 'tw-border-transparent',
      isDisabled && 'tw-bg-gray-100',
    ])}
  >
    {children}
  </components.Control>
);

const Placeholder: FC<React.PropsWithChildren<PlaceholderProps<SelectOptionType, true | false>>> = (props) => (
  <components.Placeholder {...props} className="tw-text-gray-400" />
);

export const BaseMultiSelectTextInputComponents = {
  DropdownIndicator: null,
  ValueContainer,
  MultiValue,
  Control,
  Placeholder,
  SelectContainer,
};
