import React, { FC } from 'react';

interface IconProps {
  className?: string;
}

export const IconMarketArrowDown: FC<React.PropsWithChildren<IconProps>> = ({ className = '' }) => (
  <svg className={className} width="12" height="14" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L6 6L1 1" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"></path>
  </svg>
);
