import React, { FC } from 'react';
import { AlertRounded } from '../icons/AlertRounded';

type FetchingWarningProps = {
  warningText: string;
};

export const FetchingWarning: FC<React.PropsWithChildren<FetchingWarningProps>> = ({ warningText }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-p-2 tw-rounded tw-bg-amber-300">
      <AlertRounded className="tw-mr-1 tw-block tw-text-blue-gray-900" />
      <p className="tw-text-xs tw-m-0 tw-text-blue-gray-900">{warningText}</p>
    </div>
  );
};
