import React, { FC } from 'react';
import { Tooltip as BaseTooltip, TooltipInitialState, TooltipReference, useTooltipState } from 'reakit/Tooltip';

export type TooltipProps = {
  tooltip: React.ReactNode;
  tooltipClassName?: string;
  children: any;
  tooltipProps?: TooltipInitialState;
};

export const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = ({ children, tooltip, tooltipProps, tooltipClassName = '' }) => {
  const tooltipState = useTooltipState(tooltipProps);
  return (
    <>
      <TooltipReference {...tooltipState} ref={children?.ref} {...children.props}>
        {(referenceProps) => React.cloneElement(children, referenceProps)}
      </TooltipReference>
      {tooltip && (
        <BaseTooltip
          {...tooltipState}
          as="div"
          className={`tw-bg-black tw-bg-opacity-80 tw-text-white tw-py-3 tw-px-4 tw-rounded-md tw-z-max ${tooltipClassName}`}
        >
          {tooltip}
        </BaseTooltip>
      )}
    </>
  );
};
