import React, { FC } from 'react';

export const IconCentralLocation: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#10B981" />
      <path
        d="M14 5.71429C12.939 4.65384 11.4858 4 9.88286 4C6.63384 4 4 6.68629 4 10C4 13.3137 6.63384 16 9.88286 16C11.4858 16 12.939 15.3462 14 14.2857"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="10" cy="10" r="2" fill="white" />
    </svg>
  );
};
