import React, { FC } from 'react';

export const IconMagicPen: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  // @ts-ignore slot attribute is required for market, but not part of the React svg type.
  <svg slot="icon" className={className} width="21" height="21" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M9.32722 7.20445C8.74106 6.61829 7.79078 6.61821 7.20472 7.20428C6.61865 7.79034 6.61873 8.74062 7.20489 9.32677L17.8169 19.9388C18.403 20.5249 19.3533 20.525 19.9394 19.9389C20.5254 19.3529 20.5254 18.4026 19.9392 17.8164L9.32722 7.20445Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M7.20897 7.20858C7.06903 7.34849 6.95803 7.51459 6.88229 7.69741C6.80656 7.88022 6.76758 8.07617 6.76758 8.27405C6.76758 8.47193 6.80656 8.66788 6.88229 8.85069C6.95803 9.03351 7.06903 9.19962 7.20897 9.33953L9.34564 11.4762L11.4766 9.34524L9.33992 7.20858C9.20001 7.06864 9.03391 6.95763 8.85109 6.8819C8.66827 6.80616 8.47233 6.76718 8.27445 6.76718C8.07657 6.76718 7.88062 6.80616 7.6978 6.8819C7.51499 6.95763 7.34888 7.06864 7.20897 7.20858Z"
      fill="currentColor"
    />
    <path d="M4.62476 11.0895L3.00857 12.7057L4.62476 11.0895Z" fill="currentColor" />
    <path
      d="M1 7.85714H3.28571M3.00857 3.00857L4.62476 4.62476M7.85714 1V3.28571M12.7057 3.00857L11.0895 4.62476M4.62476 11.0895L3.00857 12.7057"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="currentColor"
    />
  </svg>
);
