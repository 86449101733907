import React, { FC } from 'react';

export const IconGeneralSettings: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M11.1067 3.01313L12.9933 4.8998L11.1067 6.78646L9.22 4.8998L11.1067 3.01313ZM6 3.33313V5.9998H3.33333V3.33313H6ZM12.6667 9.9998V12.6665H10V9.9998H12.6667ZM6 9.9998V12.6665H3.33333V9.9998H6ZM11.1067 1.12646L7.33333 4.89313L11.1067 8.66646L14.88 4.89313L11.1067 1.12646ZM7.33333 1.9998H2V7.33313H7.33333V1.9998ZM14 8.66646H8.66667V13.9998H14V8.66646ZM7.33333 8.66646H2V13.9998H7.33333V8.66646Z"
      fill="currentColor"
    />
  </svg>
);
