import React, { FC } from 'react';
import { Dialog as BaseDialog, DialogProps, DialogBackdrop, DialogDisclosure, DialogDisclosureProps, DialogStateReturn } from 'reakit/Dialog';
import './Modal.css';
import classNames from 'classnames';

import { ModalContent, ModalContentWrapper, ModalHeader, ModalHeaderWithCloseButton, ModalFooter } from './ModalComponents';

export type ModalProps = {
  dialog: DialogStateReturn;
  disclosure?: React.ReactElement<Partial<DialogDisclosureProps>> & { ref?: any };
  maxWidth?: string;
  fullScreen?: boolean;
} & Partial<DialogProps>;

export const Modal: FC<React.PropsWithChildren<ModalProps>> & {
  ModalHeader: typeof ModalHeader;
  ModalHeaderWithCloseButton: typeof ModalHeaderWithCloseButton;
  ModalContent: typeof ModalContent;
  ModalFooter: typeof ModalFooter;
} = ({ disclosure, dialog, ...props }) => {
  return (
    <>
      {disclosure && (
        // @ts-ignore-next-line
        <DialogDisclosure {...dialog} ref={disclosure?.ref} {...disclosure.props}>
          {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
        </DialogDisclosure>
      )}
      <DialogBackdrop
        {...dialog}
        className={classNames([
          `tw-fixed tw-h-screen tw-w-screen tw-top-0 tw-left-0 tw-z-max tw-bg-black tw-bg-opacity-30`,
          dialog.animated && 'modal-default-transition',
        ])}
      >
        <div className="tw-w-full tw-h-full tw-max-h-screen tw-overflow-y-auto tw-flex tw-items-center tw-justify-center">
          <BaseDialog {...dialog} tabIndex={0} {...props} as={ModalContentWrapper} />
        </div>
      </DialogBackdrop>
    </>
  );
};

Modal.ModalHeader = ModalHeader;
Modal.ModalHeaderWithCloseButton = ModalHeaderWithCloseButton;
Modal.ModalContent = ModalContent;
Modal.ModalFooter = ModalFooter;
