import React, { FC } from 'react';

export const IconEmployees: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M2.66683 11.9998V11.5665C2.66683 11.3398 2.7735 11.1265 2.94016 11.0265C4.06683 10.3532 5.3535 9.99984 6.66683 9.99984C6.68683 9.99984 6.70016 9.99984 6.72016 10.0065C6.78683 9.53984 6.92016 9.09317 7.1135 8.6865C6.96683 8.67317 6.82016 8.6665 6.66683 8.6665C5.0535 8.6665 3.54683 9.11317 2.26016 9.87984C1.6735 10.2265 1.3335 10.8798 1.3335 11.5665V13.3332H7.50683C7.22683 12.9332 7.00683 12.4798 6.86016 11.9998H2.66683Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M6.66667 7.99984C8.14 7.99984 9.33333 6.8065 9.33333 5.33317C9.33333 3.85984 8.14 2.6665 6.66667 2.6665C5.19333 2.6665 4 3.85984 4 5.33317C4 6.8065 5.19333 7.99984 6.66667 7.99984ZM6.66667 3.99984C7.4 3.99984 8 4.59984 8 5.33317C8 6.0665 7.4 6.6665 6.66667 6.6665C5.93333 6.6665 5.33333 6.0665 5.33333 5.33317C5.33333 4.59984 5.93333 3.99984 6.66667 3.99984Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M13.8333 10.6663C13.8333 10.5197 13.8133 10.3863 13.7933 10.2463L14.5533 9.57301L13.8866 8.41967L12.9199 8.74634C12.7066 8.56634 12.4666 8.42634 12.1999 8.32634L11.9999 7.33301H10.6666L10.4666 8.32634C10.1999 8.42634 9.95995 8.56634 9.74661 8.74634L8.77995 8.41967L8.11328 9.57301L8.87328 10.2463C8.85328 10.3863 8.83328 10.5197 8.83328 10.6663C8.83328 10.813 8.85328 10.9463 8.87328 11.0863L8.11328 11.7597L8.77995 12.913L9.74661 12.5863C9.95995 12.7663 10.1999 12.9063 10.4666 13.0063L10.6666 13.9997H11.9999L12.1999 13.0063C12.4666 12.9063 12.7066 12.7663 12.9199 12.5863L13.8866 12.913L14.5533 11.7597L13.7933 11.0863C13.8133 10.9463 13.8333 10.813 13.8333 10.6663ZM11.3333 11.9997C10.5999 11.9997 9.99995 11.3997 9.99995 10.6663C9.99995 9.93301 10.5999 9.33301 11.3333 9.33301C12.0666 9.33301 12.6666 9.93301 12.6666 10.6663C12.6666 11.3997 12.0666 11.9997 11.3333 11.9997Z"
      fill="currentColor"
    />
  </svg>
);
