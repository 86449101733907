import React, { FC } from 'react';

export const IconMenuManagement: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M5.39999 8.8933L7.28666 7.00664L2.60666 2.3333C1.56666 3.3733 1.56666 5.05997 2.60666 6.10664L5.39999 8.8933ZM9.91999 7.68664C10.94 8.15997 12.3733 7.82664 13.4333 6.76664C14.7067 5.4933 14.9533 3.66664 13.9733 2.68664C13 1.7133 11.1733 1.95331 9.89333 3.22664C8.83333 4.28664 8.49999 5.71997 8.97333 6.73997L2.46666 13.2466L3.40666 14.1866L7.99999 9.60664L12.5867 14.1933L13.5267 13.2533L8.93999 8.66664L9.91999 7.68664Z"
      fill="currentColor"
    />
  </svg>
);
