import * as React from 'react';
import classNames from 'classnames';
import { IconImagePlaceholderSmall } from '../icons/IconImagePlaceholderSmall';

export type ImageUploadMenuSmallProps = {
  title?: string;
  className?: string;
  hasError?: boolean;
  imgUrl: string;
};

const DEFAULT_MESSAGE = 'menu image';

export const ImageUploadMenuSmall: React.FC<React.PropsWithChildren<ImageUploadMenuSmallProps>> = ({
  hasError,
  className,
  title = DEFAULT_MESSAGE,
  imgUrl,
  children,
}) => {
  const slicedTitlte = title.split(' ')[0];
  return (
    <div>
      <div
        className={classNames([
          'tw-w-18 tw-h-18 tw-border tw-border-px tw-rounded-md tw-p-1 tw-flex tw-items-center tw-justify-center tw-relative',
          hasError ? 'tw-border-red-300' : 'tw-border-cool-gray-200',
          className,
        ])}
      >
        {imgUrl ? (
          <div>
            <img className="tw-w-18 tw-h-18 tw-object-contain" src={imgUrl} alt={title} />
            {children}
          </div>
        ) : (
          <IconImagePlaceholderSmall className="tw-text-cool-gray-300" />
        )}
      </div>
      <div className="tw-mx-auto tw-font-medium tw-text-xs tw-text-cool-gray-700">{slicedTitlte}</div>
    </div>
  );
};
