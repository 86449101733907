import React, { FC } from 'react';

export const IconDateSmall: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg className={className} width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="1" width="3" height="3" stroke="currentColor" />
    <rect x="4" y="4" width="3" height="3" stroke="currentColor" />
    <rect x="7" y="1" width="3" height="3" stroke="currentColor" />
    <rect x="7" y="4" width="3" height="3" stroke="currentColor" />
    <rect x="10" y="1" width="3" height="3" stroke="currentColor" />
    <rect x="10" y="4" width="3" height="3" stroke="currentColor" />
    <rect x="1" y="4" width="3" height="3" stroke="currentColor" />
    <rect x="1" y="7" width="3" height="3" stroke="currentColor" />
    <rect x="4" y="4" width="3" height="3" stroke="currentColor" />
    <rect x="4" y="7" width="3" height="3" stroke="currentColor" />
    <rect x="7" y="4" width="3" height="3" stroke="currentColor" />
    <rect x="7" y="7" width="3" height="3" stroke="currentColor" />
    <rect x="10" y="4" width="3" height="3" stroke="currentColor" />
  </svg>
);
