import React, { FC } from 'react';
import classNames from 'classnames';

type LabelSize = '64' | '52' | '32' | 'auto';

export type FormFieldProps = {
  label: React.ReactNode | string;
  input: React.ReactNode;
  hasError?: boolean;
  errorMessage?: React.ReactNode | string;
  labelSize?: LabelSize;
  withValuePadding?: boolean;
  disabled?: boolean;
  testId?: string;
};

export const labelSizeMapping: Record<LabelSize, string> = {
  '64': 'tw-w-full sm:tw-w-64',
  '52': 'tw-w-full sm:tw-w-52',
  '32': 'tw-w-full sm:tw-w-32',
  auto: 'tw-w-full sm:tw-w-auto',
};

export const FormField: FC<React.PropsWithChildren<FormFieldProps>> = ({
  label,
  input,
  hasError,
  errorMessage,
  labelSize = '52',
  withValuePadding = true,
  disabled = false,
  testId,
}) => (
  <div
    data-testid={testId}
    className={classNames([
      'tw-border-t tw-border-l tw-border-r first:tw-rounded-t-md last:tw-rounded-b-md last:tw-border-b',
      hasError ? 'tw-border-red-300 tw-border-b' : 'tw-border-gray-200',
    ])}
  >
    <div className="tw-flex tw-flex-col sm:tw-flex-row tw-rounded-inherit">
      <div
        className={classNames([
          'tw-py-3 tw-pl-4 tw-pr-3 tw-text-gray-600 tw-font-medium tw-border-r tw-border-cool-gray-200',
          labelSizeMapping[labelSize],
          hasError ? 'tw-bg-red-50' : 'tw-bg-gray-100',
          hasError ? 'tw-rounded-tl-inherit' : 'tw-rounded-l-inherit',
        ])}
      >
        {label}
      </div>
      <div
        className={classNames([
          'tw-flex-1 tw-flex tw-items-center tw-min-h-45 sm:tw-min-h-0',
          hasError ? 'tw-rounded-tr-inherit' : 'tw-rounded-r-inherit',
          withValuePadding && 'tw-px-4',
          disabled && 'tw-bg-cool-gray-100',
        ])}
      >
        {input}
      </div>
    </div>
    {hasError && errorMessage && (
      <div role="status" className="tw-text-center tw-py-1 tw-bg-red-100 tw-text-red-600 tw-rounded-b-inherit">
        • {errorMessage}
      </div>
    )}
  </div>
);
