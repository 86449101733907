import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Checkbox, CheckboxProps } from 'reakit/Checkbox';

const ToggleWrapper = styled.label`
  width: 30px;
`;

const ToggleBubble = styled.span`
  height: 14px;
  width: 14px;
`;

type CustomProps = {
  isCritical?: boolean;
  isSquareColor?: boolean;
};

const ToggleElement = React.forwardRef<HTMLButtonElement, CheckboxProps & CustomProps>((props, ref) => {
  const { isCritical, ...restProps } = props;
  const toggleDisabledBg = isCritical ? 'tw-bg-red-200' : 'tw-bg-cool-gray-200';
  const toggleBg = isCritical ? 'tw-bg-red-500' : 'tw-bg-cool-gray-400';

  return (
    <ToggleWrapper
      ref={ref as any}
      {...(restProps as any)}
      className={classNames([
        'tw-relative tw-p-0.5 tw-h-4.5 tw-inline-block tw-rounded-full focus:tw-outline-none',
        props['aria-disabled'] ? 'tw-pointer-events-auto tw-cursor-not-allowed' : 'tw-cursor-pointer',
        props['aria-disabled']
          ? props.checked
            ? 'tw-bg-light-blue-200'
            : toggleDisabledBg
          : props.checked
          ? props.isSquareColor
            ? 'tw-bg-blue-700'
            : 'tw-bg-light-blue-400'
          : toggleBg,
      ])}
    >
      <ToggleBubble
        className={classNames([
          'tw-rounded-full tw-bg-white',
          'tw-absolute tw-top-0.5',
          'tw-transition-all tw-ease-in-out tw-duration-100',
          'focus:tw-outline-none',
          'tw-transform',
          props.checked ? 'tw-translate-x-full tw-left-0' : 'tw-translate-x-0 tw-left-0.5',
        ])}
      />
    </ToggleWrapper>
  );
});

export type ToggleProps = CustomProps & {
  checked?: boolean;
  onChange(value: boolean, event?: InputEvent): void;
  disabled?: boolean;
  checkedLabel?: React.ReactNode | string;
  uncheckedLabel?: React.ReactNode | string;
  prependLabels?: boolean;
};

export const Toggle: FC<React.PropsWithChildren<ToggleProps>> = ({
  checked = false,
  onChange,
  disabled = false,
  checkedLabel = null,
  uncheckedLabel = null,
  prependLabels = false,
  isCritical,
  isSquareColor,
}) => {
  const onInputChange = useCallback((event: any) => onChange(!checked, event), [onChange, checked]);
  const id = useMemo(() => `toggle-${Math.random().toString(36).substring(2)}`, []);
  const hasLabel = checkedLabel || uncheckedLabel;
  return (
    <div className="tw-inline-block">
      <div className={classNames(['tw-flex tw-items-center', prependLabels && 'tw-flex-row-reverse'])}>
        <Checkbox
          id={id}
          disabled={disabled}
          checked={checked}
          onChange={onInputChange}
          as={ToggleElement}
          isCritical={isCritical}
          isSquareColor={isSquareColor}
        />
        {hasLabel && (
          <label htmlFor={id} className={classNames(['tw-text-sm tw-text-gray-900 tw-leading-4', prependLabels ? 'tw-mr-2' : 'tw-ml-2'])}>
            {checked ? checkedLabel : uncheckedLabel}
          </label>
        )}
      </div>
    </div>
  );
};
