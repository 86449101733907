import React, { FC } from 'react';
import classNames from 'classnames';
import { DraggableProvided, DraggableStateSnapshot, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { Table } from './Table';
import { DraggableProvider, getDraggableProps } from './DragAndDropProvider';

type GenericTableRowProps = {
  row: any;
  index: number;
  onDragEnd?(result: DropResult, provided: ResponderProvided): void;
  isTableVirtualized: boolean;
  rowClassName: string;
  contentAlign?: string;
  dropdownComponent?: (original: unknown) => React.ReactNode;
  isDragDisabled?(original: unknown): boolean;
};

export const GenericTableRow: FC<React.PropsWithChildren<GenericTableRowProps>> = ({
  row,
  index,
  onDragEnd,
  isTableVirtualized,
  rowClassName,
  contentAlign,
  dropdownComponent,
  isDragDisabled,
}) => {
  return (
    <DraggableProvider
      draggableId={row.id}
      key={`row-${index}`}
      index={row.index}
      isDraggable={!!onDragEnd && (!isDragDisabled || !isDragDisabled(row.original))}
    >
      {(draggableProvided?: DraggableProvided, snapshot?: DraggableStateSnapshot) => (
        <>
          {dropdownComponent ? (
            <div className="tw-table tw-w-full tw-border-collapse">
              <Table.Row
                isTableVirtualized={isTableVirtualized}
                {...row.getRowProps()}
                {...getDraggableProps(draggableProvided)}
                className={classNames([rowClassName, snapshot?.isDragging && 'tw-table tw-bg-blue-gray-100', 'tw-table-row'])}
              >
                {row.cells.map((cell: any, index: number) => {
                  const { className, maxWidth } = cell.column;
                  return (
                    <Table.Data
                      isTableVirtualized={isTableVirtualized}
                      className={classNames(['tw-break-words', contentAlign ? `tw-align-${contentAlign}` : 'tw-align-middle', className])}
                      style={{ maxWidth }}
                      {...cell.getCellProps()}
                      key={`cell-${index}`}
                    >
                      {cell.render('Cell')}
                    </Table.Data>
                  );
                })}
              </Table.Row>
            </div>
          ) : (
            <Table.Row
              isTableVirtualized={isTableVirtualized}
              {...row.getRowProps()}
              {...getDraggableProps(draggableProvided)}
              className={classNames([rowClassName, snapshot?.isDragging && 'tw-table tw-bg-blue-gray-100', 'tw-table-row'])}
            >
              {row.cells.map((cell: any, index: number) => {
                const { className, maxWidth } = cell.column;
                return (
                  <Table.Data
                    isTableVirtualized={isTableVirtualized}
                    className={classNames(['tw-break-words', contentAlign ? `tw-align-${contentAlign}` : 'tw-align-middle', className])}
                    style={{ maxWidth }}
                    {...cell.getCellProps()}
                    key={`cell-${index}`}
                  >
                    {cell.render('Cell')}
                  </Table.Data>
                );
              })}
            </Table.Row>
          )}

          {dropdownComponent && dropdownComponent(row.original)}
        </>
      )}
    </DraggableProvider>
  );
};
