import React, { FC } from 'react';
import { ButtonHTMLProps } from 'reakit/ts';
import classNames from 'classnames';

type DivElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
type ContentWrapperProps = { className: string } & DivElementProps;
type MenuItemProps = { textColor?: string; noPadding?: boolean } & ButtonHTMLProps;
export const MenuContentWrapper = React.forwardRef(({ children, className = '', ...props }: ContentWrapperProps, ref) => (
  <div
    className={classNames(['tw-whitespace-pre-line focus:tw-outline-none tw-shadow tw-border tw-border-1 tw-border-white tw-rounded-md tw-z-10', className])}
    {...props}
    ref={ref as any}
  >
    {children}
  </div>
));

export const MenuItem: FC<React.PropsWithChildren<MenuItemProps>> = React.forwardRef(
  ({ children, className = '', textColor = 'tw-text-light-blue-400', noPadding = false, ...props }, ref) => (
    <button
      className={classNames([
        className,
        textColor,
        !noPadding && `tw-px-4 tw-py-3`,
        `tw-bg-white tw-text-left tw-w-full hover:tw-bg-gray-200 focus:tw-bg-gray-100 tw-outline-none`,
        'tw-transition-colors tw-duration-300 tw-text-sm first:tw-rounded-t-md last:tw-rounded-b-md',
      ])}
      {...props}
      ref={ref as any}
    >
      {children}
    </button>
  ),
);
