import React, { FC } from 'react';

export const IconPickupSettings: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M5 11.3335C5.55228 11.3335 6 10.8858 6 10.3335C6 9.78121 5.55228 9.3335 5 9.3335C4.44772 9.3335 4 9.78121 4 10.3335C4 10.8858 4.44772 11.3335 5 11.3335Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11 11.3335C11.5523 11.3335 12 10.8858 12 10.3335C12 9.78121 11.5523 9.3335 11 9.3335C10.4477 9.3335 10 9.78121 10 10.3335C10 10.8858 10.4477 11.3335 11 11.3335Z"
      fill="currentColor"
    />
    <circle cx="11.5" cy="3.5" r="2.9" stroke="currentColor" strokeWidth="1.2" />
    <path d="M11.5 2V3.5L12.5 4.5" stroke="currentColor" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.03544 4H4.33333C3.89333 4 3.52667 4.28 3.38667 4.67333L2 8.66667V14C2 14.3667 2.3 14.6667 2.66667 14.6667H3.33333C3.7 14.6667 4 14.3667 4 14V13.3333H12V14C12 14.3667 12.3 14.6667 12.6667 14.6667H13.3333C13.7 14.6667 14 14.3667 14 14V8.66667L13.2572 6.52758C12.8756 6.74956 12.4481 6.90145 11.9925 6.96562L12.12 7.33333H3.87333L4.56667 5.33333H8.518C8.27246 4.9348 8.10458 4.48333 8.03544 4ZM3.33333 8.89333V12H12.6667V8.89333L12.5933 8.66667H3.41333L3.33333 8.89333Z"
      fill="currentColor"
    />
  </svg>
);
