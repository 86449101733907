import React, { FC } from 'react';

export const IconRestore: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M10 4.5625C10 4.5625 10.7612 4.1875 8 4.1875C7.01109 4.1875 6.04439 4.48074 5.22215 5.03015C4.3999 5.57956 3.75904 6.36045 3.3806 7.27408C3.00217 8.18771 2.90315 9.19305 3.09608 10.163C3.289 11.1329 3.76521 12.0238 4.46447 12.723C5.16373 13.4223 6.05464 13.8985 7.02455 14.0914C7.99445 14.2844 8.99978 14.1853 9.91341 13.8069C10.827 13.4285 11.6079 12.7876 12.1573 11.9654C12.7067 11.1431 13 10.1764 13 9.1875"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M8 1.8125L10.5 4.3125L8 6.8125" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
