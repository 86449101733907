import * as React from 'react';
import { PAGE_SIZE } from './Pagination';

export const usePagination = ({
  startPage = 1,
  limit = PAGE_SIZE,
}: {
  startPage?: number;
  limit?: number;
}): { currentPage: number; updateCurrentPage(page: number): void; offset: number } => {
  const [currentPage, setCurrentPage] = React.useState(startPage);

  return { currentPage, updateCurrentPage: setCurrentPage, offset: (currentPage - 1) * limit };
};
