import React, { FC } from 'react';

export const IconSearch: FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99998 17C13.4182 17 17 13.4182 17 8.99998C17 4.58171 13.4182 1 8.99998 1C4.58171 1 1 4.58171 1 8.99998C1 13.4182 4.58171 17 8.99998 17Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.999 19L15.168 15.0292" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
