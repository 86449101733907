import React, { FC } from 'react';

export const IconOrdersThrottling: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M9.33301 12.6667H14.6663V11.3334H9.33301V12.6667ZM9.33301 9.00008H14.6663V7.66675H9.33301V9.00008ZM9.33301 5.33341H14.6663V4.00008H9.33301V5.33341ZM1.33301 8.33341C1.33301 5.94675 3.27967 4.00008 5.66634 4.00008H5.99967V2.66675L7.99967 4.66675L5.99967 6.66675V5.33341H5.66634C4.01301 5.33341 2.66634 6.68008 2.66634 8.33341C2.66634 9.98675 4.01301 11.3334 5.66634 11.3334H7.99967V12.6667H5.66634C3.27967 12.6667 1.33301 10.7201 1.33301 8.33341Z"
      fill="currentColor"
    />
  </svg>
);
