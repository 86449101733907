import React, { FC, useRef, useEffect, MutableRefObject } from 'react';
import { MarketToast } from '@market/react-bindings';
import { MarketNotification } from './Toaster';

type ToastProps = MarketNotification & {
  toasterElRef: MutableRefObject<HTMLMarketToasterElement>;
};

export const Toast: FC<React.PropsWithChildren<ToastProps>> = ({ type, text, actions, toasterElRef, persistent }) => {
  const toastElRef = useRef<HTMLMarketToastElement>(null);

  useEffect(() => {
    const { current: toastEl } = toastElRef;
    const { current: toasterEl } = toasterElRef;

    if (toastEl) {
      toasterEl?.show(toastEl);
    }
  }, [toastElRef, toasterElRef]);

  return (
    <MarketToast ref={toastElRef} variant={type} persistent={persistent}>
      {text}
      {actions}
    </MarketToast>
  );
};
