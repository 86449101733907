import React, { FC } from 'react';
import classNames from 'classnames';
import { IconProps } from './type';

export const IconErrorCircle: FC<React.PropsWithChildren<IconProps>> = ({ className, width = 12, height = 12 }) => (
  <svg className={classNames([className])} width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.41406 4.58582L4.58564 7.41424" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.58594 4.58582L7.41436 7.41424" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="6" cy="6" r="5.5" stroke="currentColor" />
  </svg>
);
