import React, { ComponentType, FC, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Props as BaseSelectProps, components, InputProps } from 'react-select';
import { SelectOptionType, SelectValueType } from '../select/types';
import { OutlineMultiSelectTextInputComponents } from './OutlineMultiSelectTextInputComponents';
import { BaseMultiSelectTextInputComponents } from './BaseMultiSelectTextInputComponents';
import { CleanMultiSelectTextInputComponents } from './CleanMultiSelectTextInputComponents';

type Variant = 'base' | 'outline' | 'clean';

export type MultiSelectTextInputProps = Omit<BaseSelectProps<SelectOptionType[], true | false>, 'value'> & {
  separator?: string;
  variant?: Variant;
  value?: SelectValueType[];
  onChange(value: SelectValueType[]): void;
  customInput?: ComponentType<React.PropsWithChildren<InputProps>>;
  inputWithError?: boolean;
  onValidateFail?(): void;
  validate?(v: string): boolean;
  outline?: boolean;
  // base - to be used within FormField
  // outline - for standalone usage
};

const componentsMapping: Record<Variant, any> = {
  base: BaseMultiSelectTextInputComponents,
  outline: OutlineMultiSelectTextInputComponents,
  clean: CleanMultiSelectTextInputComponents,
};

export const MultiSelectTextInput: FC<React.PropsWithChildren<MultiSelectTextInputProps>> = ({
  onChange,
  inputWithError,
  value = [],
  variant = 'base',
  separator,
  customInput,
  onValidateFail,
  validate,
  ...props
}) => {
  const baseProps: any = {
    theme: ({ borderRadius, colors, spacing: { baseUnit, menuGutter } }: BaseSelectProps) => ({
      borderRadius,
      colors,
      spacing: {
        baseUnit,
        controlHeight: 45,
        menuGutter,
      },
    }),
  };

  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = React.useState<boolean | undefined>(false);

  const separateStringByComma = (value: SelectValueType[], inputValue: string): SelectValueType[] =>
    value.concat(separator ? inputValue.split(separator) : inputValue);

  const handleChange = (v: SelectOptionType[]): void => {
    onChange(v.map((option) => option.value));
  };

  const handleInputChange = (v: string) => {
    if (inputWithError) {
      const isError = validate?.(v);
      setError(isError);
    }
    setInputValue(v);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (!inputValue) return;
    if (['Enter', 'Tab', ' ', ','].includes(event.key)) {
      if (error && inputWithError) {
        event.preventDefault();
        onValidateFail?.();
      } else {
        event.preventDefault();
        onChange(separateStringByComma(value, inputValue));
        setInputValue('');
      }
    }
  };

  const handleBlur = () => {
    if (!inputValue) return;
    if (error && inputWithError) {
      setInputValue('');
    } else {
      setInputValue('');
      onChange(separateStringByComma(value, inputValue));
    }
  };

  const displayValues = value
    ? (value as SelectValueType[]).map((valueItem: SelectValueType) => ({
        value: valueItem,
        label: valueItem,
      }))
    : undefined;
  baseProps.value = displayValues;

  return (
    <CreatableSelect
      {...props}
      {...baseProps}
      components={{ ...componentsMapping[variant], Input: customInput ?? components.Input }}
      inputValue={inputValue}
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};
