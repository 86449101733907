import React, { FC } from 'react';

export const IconOrders: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M12 5.99984L11.06 5.05317L6.66667 9.4465L4.94 7.7265L4 8.6665L6.66667 11.3332L12 5.99984ZM12.6667 1.99984H9.88C9.6 1.2265 8.86667 0.666504 8 0.666504C7.13333 0.666504 6.4 1.2265 6.12 1.99984H3.33333C3.24 1.99984 3.15333 2.0065 3.06667 2.0265C2.80667 2.07984 2.57333 2.21317 2.39333 2.39317C2.27333 2.51317 2.17333 2.65984 2.10667 2.81984C2.04 2.97317 2 3.1465 2 3.33317V12.6665C2 12.8465 2.04 13.0265 2.10667 13.1865C2.17333 13.3465 2.27333 13.4865 2.39333 13.6132C2.57333 13.7932 2.80667 13.9265 3.06667 13.9798C3.15333 13.9932 3.24 13.9998 3.33333 13.9998H12.6667C13.4 13.9998 14 13.3998 14 12.6665V3.33317C14 2.59984 13.4 1.99984 12.6667 1.99984ZM8 1.83317C8.27333 1.83317 8.5 2.05984 8.5 2.33317C8.5 2.6065 8.27333 2.83317 8 2.83317C7.72667 2.83317 7.5 2.6065 7.5 2.33317C7.5 2.05984 7.72667 1.83317 8 1.83317ZM12.6667 12.6665H3.33333V3.33317H12.6667V12.6665Z"
      fill="currentColor"
    />
  </svg>
);
