import * as React from 'react';
import { IconProps } from './type';

export const IconDragAndDropVertical: React.FC<React.PropsWithChildren<IconProps>> = ({ className, height = 16, width = 6 }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 6 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.904785 3.09524L3.00002 1M3.00002 1L5.09526 3.09524M3.00002 1V17M3.00002 17L0.904785 14.9048M3.00002 17L5.09526 14.9048"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
