/* eslint-disable max-len */
import * as React from 'react';

export const IconCopyOutlined: React.FC<React.PropsWithChildren<{ className?: string; width?: number; height?: number }>> = ({
  className = '',
  width = 15,
  height = 15,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 11.5H14.5C12.8431 11.5 11.5 12.8431 11.5 14.5V28C11.5 29.6569 12.8431 31 14.5 31H28C29.6569 31 31 29.6569 31 28V14.5C31 12.8431 29.6569 11.5 28 11.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 20.5H4C3.20435 20.5 2.44129 20.1839 1.87868 19.6213C1.31607 19.0587 1 18.2956 1 17.5V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H17.5C18.2956 1 19.0587 1.31607 19.6213 1.87868C20.1839 2.44129 20.5 3.20435 20.5 4V5.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
