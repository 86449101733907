import React, { FC } from 'react';
import classNames from 'classnames';

export type OutliendFormFieldProps = {
  className?: string;
  hasError?: boolean;
  errorMessage?: React.ReactNode | string;
  disabled?: boolean;
};

export const OutlinedFormField: FC<React.PropsWithChildren<OutliendFormFieldProps>> = ({ children, className, hasError, errorMessage, disabled = false }) => (
  <div className={classNames(className)}>
    {children}
    {hasError && errorMessage && <div className="tw-text-left tw-py-1 tw-text-red-600 tw-transition-all">{errorMessage}</div>}
  </div>
);
