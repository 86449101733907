import React, { FC } from 'react';

export const IconCustomers: FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1133 8.75342C12.0266 9.37342 12.6666 10.2134 12.6666 11.3334V13.3334H15.3333V11.3334C15.3333 9.88008 12.9533 9.02008 11.1133 8.75342Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M9.99995 7.99984C11.4733 7.99984 12.6666 6.8065 12.6666 5.33317C12.6666 3.85984 11.4733 2.6665 9.99995 2.6665C9.68661 2.6665 9.39328 2.73317 9.11328 2.8265C9.66661 3.51317 9.99995 4.3865 9.99995 5.33317C9.99995 6.27984 9.66661 7.15317 9.11328 7.83984C9.39328 7.93317 9.68661 7.99984 9.99995 7.99984Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.99967 7.99984C7.47301 7.99984 8.66634 6.8065 8.66634 5.33317C8.66634 3.85984 7.47301 2.6665 5.99967 2.6665C4.52634 2.6665 3.33301 3.85984 3.33301 5.33317C3.33301 6.8065 4.52634 7.99984 5.99967 7.99984ZM5.99967 3.99984C6.73301 3.99984 7.33301 4.59984 7.33301 5.33317C7.33301 6.0665 6.73301 6.6665 5.99967 6.6665C5.26634 6.6665 4.66634 6.0665 4.66634 5.33317C4.66634 4.59984 5.26634 3.99984 5.99967 3.99984Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.99984 8.6665C4.21984 8.6665 0.666504 9.55984 0.666504 11.3332V13.3332H11.3332V11.3332C11.3332 9.55984 7.77984 8.6665 5.99984 8.6665ZM9.99984 11.9998H1.99984V11.3398C2.13317 10.8598 4.19984 9.99984 5.99984 9.99984C7.79984 9.99984 9.8665 10.8598 9.99984 11.3332V11.9998Z"
      fill="currentColor"
    />
  </svg>
);
