import { MarketContextManager } from '@market/react-bindings';
import React, { FC, createContext, ReactNode, useCallback, useContext, useRef } from 'react';

interface MarketContext {
  getContextManager: () => globalThis.HTMLMarketContextManagerElement | null;
}

interface MarketContextProviderProps {
  children: ReactNode;
}

const Context = createContext<MarketContext>({
  getContextManager: () => null,
});

/*
  Custom hook to extract refs and points to the
  market-context-manager. Usable in the <InModal>
  component.

  Set this up initially by creating a `MarketContextManagerContext`
  component
 */
export const useMarketContext = () => {
  return useContext(Context);
};

/*
  A nice component that creates the MarketContextManager for you,
  and puts it in a shared context. That can then be used by anyone
  downstream using `useMarketContext`
 */
export const MarketContextProvider: FC<React.PropsWithChildren<MarketContextProviderProps>> = ({ children }) => {
  const contextManagerRef = useRef<globalThis.HTMLMarketContextManagerElement>(null);

  const getContextManager = useCallback(() => {
    return contextManagerRef.current;
  }, []);

  return (
    <>
      <MarketContextManager ref={contextManagerRef} />
      <Context.Provider value={{ getContextManager }}>{children}</Context.Provider>
    </>
  );
};
