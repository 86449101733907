import React from 'react';
import { Button } from '../button/Button';
import { IconLoading, IconUpload } from '../icons';

type Props = {
  text?: React.ReactNode | string;
  loading?: boolean;
  onClick(): void;
};
export const ImageUploadButton: React.FC<React.PropsWithChildren<Props>> = ({ text = 'Upload image', onClick, loading }) => {
  return (
    <Button onClick={onClick} disabled={loading}>
      <div className="tw-flex tw-items-center tw-space-x-2">
        {loading ? <IconLoading className="tw-transition-all tw-animate-spin" /> : <IconUpload />}
        <span>{text}</span>
      </div>
    </Button>
  );
};
