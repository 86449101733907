import React, { FC } from 'react';

export const IconColorPalleteLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M35.2817 28.5387C34.7403 28.0383 33.9446 27.9152 33.2638 27.8004C32.3204 27.6445 31.9595 27.4723 31.4099 26.9801C30.2368 25.9383 30.2368 24.4289 31.4099 23.3871L33.8954 21.1805C37.7017 17.8172 37.7017 12.3047 33.8954 8.94141C31.0899 6.47227 27.3247 5.25 23.4118 5.25C18.8427 5.25 14.0685 6.91523 10.3853 10.1801C3.53564 16.234 3.53564 26.1516 10.3853 32.2055C13.7896 35.216 18.3833 36.7172 22.9278 36.75H23.0673C27.6118 36.75 32.0907 35.2816 35.2735 32.4516C36.4548 31.4098 36.2571 29.4492 35.2817 28.5387V28.5387Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      // eslint-disable-next-line max-len
      d="M11.8125 19.6875C13.2622 19.6875 14.4375 18.5122 14.4375 17.0625C14.4375 15.6128 13.2622 14.4375 11.8125 14.4375C10.3628 14.4375 9.1875 15.6128 9.1875 17.0625C9.1875 18.5122 10.3628 19.6875 11.8125 19.6875Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M12.4688 28.1367C13.9185 28.1367 15.0938 26.9615 15.0938 25.5117C15.0938 24.062 13.9185 22.8867 12.4688 22.8867C11.019 22.8867 9.84375 24.062 9.84375 25.5117C9.84375 26.9615 11.019 28.1367 12.4688 28.1367Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M18.375 14.4375C19.8247 14.4375 21 13.2622 21 11.8125C21 10.3628 19.8247 9.1875 18.375 9.1875C16.9253 9.1875 15.75 10.3628 15.75 11.8125C15.75 13.2622 16.9253 14.4375 18.375 14.4375Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M21 34.043C23.1746 34.043 24.9375 32.2801 24.9375 30.1055C24.9375 27.9308 23.1746 26.168 21 26.168C18.8254 26.168 17.0625 27.9308 17.0625 30.1055C17.0625 32.2801 18.8254 34.043 21 34.043Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M26.9062 14.4375C28.356 14.4375 29.5312 13.2622 29.5312 11.8125C29.5312 10.3628 28.356 9.1875 26.9062 9.1875C25.4565 9.1875 24.2812 10.3628 24.2812 11.8125C24.2812 13.2622 25.4565 14.4375 26.9062 14.4375Z"
      fill="currentColor"
    />
  </svg>
);
