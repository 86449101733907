import React, { FC } from 'react';

export const IconBusinessHours: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M7.33333 11.3333C7.33333 11.7 7.63333 12 8 12C8.36667 12 8.66667 11.7 8.66667 11.3333C8.66667 10.9667 8.36667 10.6667 8 10.6667C7.63333 10.6667 7.33333 10.9667 7.33333 11.3333ZM7.33333 2V4.66667H8.66667V3.38667C10.9267 3.71333 12.6667 5.64667 12.6667 8C12.6667 10.58 10.58 12.6667 8 12.6667C5.42 12.6667 3.33333 10.58 3.33333 8C3.33333 6.88 3.72667 5.85333 4.38667 5.05333L8 8.66667L8.94 7.72667L4.40667 3.19333V3.20667C2.94667 4.3 2 6.03333 2 8C2 11.3133 4.68 14 8 14C11.3133 14 14 11.3133 14 8C14 4.68667 11.3133 2 8 2H7.33333ZM12 8C12 7.63333 11.7 7.33333 11.3333 7.33333C10.9667 7.33333 10.6667 7.63333 10.6667 8C10.6667 8.36667 10.9667 8.66667 11.3333 8.66667C11.7 8.66667 12 8.36667 12 8ZM4 8C4 8.36667 4.3 8.66667 4.66667 8.66667C5.03333 8.66667 5.33333 8.36667 5.33333 8C5.33333 7.63333 5.03333 7.33333 4.66667 7.33333C4.3 7.33333 4 7.63333 4 8Z"
      fill="currentColor"
    />
  </svg>
);
