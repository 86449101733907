import React from 'react';
import classNames from 'classnames';
import './Table.css';

type VirtualizedTypeProps = { isTableVirtualized?: boolean };

export const Table: React.FC<React.PropsWithChildren<React.TableHTMLAttributes<HTMLTableElement> & VirtualizedTypeProps>> & {
  Row: React.FC<
    React.PropsWithChildren<React.TableHTMLAttributes<HTMLTableRowElement> & { innerRef?: (...args: unknown[]) => unknown; isTableVirtualized?: boolean }>
  >;
  Heading: React.FC<React.PropsWithChildren<React.TableHTMLAttributes<HTMLTableHeaderCellElement> & VirtualizedTypeProps>>;
  Head: React.FC<React.PropsWithChildren<React.TableHTMLAttributes<HTMLTableSectionElement> & VirtualizedTypeProps>>;
  Body: React.FC<
    React.PropsWithChildren<React.TableHTMLAttributes<HTMLTableSectionElement> & { innerRef?: (...args: unknown[]) => unknown; isTableVirtualized?: boolean }>
  >;
  Data: React.FC<React.PropsWithChildren<React.TdHTMLAttributes<HTMLTableDataCellElement> & VirtualizedTypeProps>>;
} = ({ className = '', children, isTableVirtualized, ...rest }) => {
  return isTableVirtualized ? (
    <div className={classNames([`table tw-table tw-border-collapse tw-table-fixed tw-w-full`, className])} {...rest}>
      {children}
    </div>
  ) : (
    <table className={classNames([`tw-w-full`, className])} {...rest}>
      {children}
    </table>
  );
};

Table.Row = ({ children, innerRef, className = '', isTableVirtualized, ...rest }) => {
  return isTableVirtualized ? (
    <div className={classNames([`tr tw-table-fixed tw-border-b tw-border-b-px tw-border-black tw-border-opacity-10`, className])} ref={innerRef} {...rest}>
      {children}
    </div>
  ) : (
    <tr className={classNames([`tw-border-b tw-border-b-px tw-border-black tw-border-opacity-10`, className])} ref={innerRef} {...rest}>
      {children}
    </tr>
  );
};

Table.Heading = ({ children, className = '', isTableVirtualized, ...rest }) => {
  return isTableVirtualized ? (
    <div
      className={classNames([`th tw-table-cell tw-align-middle tw-text-gray-400 tw-font-normal tw-py-3.5 tw-pr-3 tw-text-xs tw-text-left`, className])}
      {...rest}
    >
      {children}
    </div>
  ) : (
    <th className={classNames([`tw-text-gray-400 tw-font-normal tw-py-3.5 tw-pr-3 tw-text-xs tw-text-left tw-z-20`, className])} {...rest}>
      {children}
    </th>
  );
};

Table.Head = ({ children, className = '', isTableVirtualized, ...rest }) => {
  return isTableVirtualized ? (
    <div className={classNames(['tw-table tw-w-full', className])} {...rest}>
      {children}
    </div>
  ) : (
    <thead className={classNames([className])} {...rest}>
      {children}
    </thead>
  );
};

Table.Body = ({ children, innerRef, className = '', isTableVirtualized, ...rest }) => {
  return isTableVirtualized ? (
    <div id="table-body" className={classNames(['t-body tw-table tw-w-full tw-h-full', className])} ref={innerRef} {...rest}>
      {children}
    </div>
  ) : (
    <tbody className={classNames([className])} ref={innerRef} {...rest}>
      {children}
    </tbody>
  );
};

Table.Data = ({ children, className = '', isTableVirtualized, ...rest }) => {
  return isTableVirtualized ? (
    <div className={classNames(['td tw-table-cell tw-py-4 tw-pr-4 tw-text-sm tw-text-blue-gray-900 tw-font-normal', className])} {...rest}>
      {children}
    </div>
  ) : (
    <td className={classNames(['tw-py-4 tw-pr-4 tw-text-sm tw-text-blue-gray-900 tw-font-normal', className])} {...rest}>
      {children}
    </td>
  );
};
