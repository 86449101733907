import React, { FC } from 'react';

interface IconProps {
  className?: string;
}

export const ArrowUp: FC<React.PropsWithChildren<IconProps>> = ({ className }) => (
  <svg className={`tw-inline ${className}`} width="15" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.333 133.333" fill="currentColor">
    <polygon points="106.667,0 0,137 213.333,137"></polygon>
  </svg>
);
