import classNames from 'classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Button } from '../button/Button';
import { IconArrowRightSmall, IconCheck, IconCloseThin } from '../icons/';

import { ProgressBar } from './DefaultWizardComponents';
import './DefaultWizardStyle.css';

export type WizardItem = {
  label: string;
  url: string;
  description?: string;
  isCompleted?: boolean;
  items?: Array<Omit<WizardItem, 'items' | 'description' | 'url'>>;
};

export type DefaultWizardProps = {
  onClose?: () => void;
  isVisible?: boolean;
  items: WizardItem[];
  title: string;
  subtitle?: string;
};

export const DefaultWizard: FC<React.PropsWithChildren<DefaultWizardProps>> = ({ onClose, isVisible, items, title, subtitle }) => {
  const totalNumber = items.map((item) => item).length;
  const completedNumber = items.filter((item) => item.isCompleted).length;

  return (
    <CSSTransition
      timeout={150}
      classNames={{
        enter: 'tw-opacity-0',
        enterActive: 'tw-opacity-1',
        enterDone: 'tw-opacity-1',
        exit: 'tw-opacity-1',
        exitActive: 'tw-opacity-0',
        exitDone: 'tw-opacity-0',
      }}
      in={isVisible}
    >
      <div className="tw-w-full tw-max-w-full tw-pt-4 tw-pb-8 tw-px-5 tw-rounded-lg tw-border tw-border-cool-gray-200 tw-transition-all tw-ease">
        <Button onClick={onClose} color="clean" kind="clean" rounded="clean" className="tw-ml-auto">
          <IconCloseThin />
        </Button>
        <h3 className="tw-mb-1 tw-text-lg tw-leading-6 tw-font-bold tw-text-blue-gray-900">{title}</h3>
        <span className="tw-block tw-mb-5 tw-pr-1 tw-leading-4 tw-text-blue-gray-700">{subtitle}</span>
        <div className="tw-pl-3.5 tw-mb-5 tw-ml-0.5 tw-border-l tw-border-cool-gray-200">
          {items.map((item, i) => {
            return (
              <div key={i} className="item tw-mb-4.5 tw-leading-4 last:tw-mb-0">
                <NavLink
                  to={item.url}
                  activeClassName="tw-text-blue-gray-700 tw-font-semibold"
                  className={classNames(['tw-inline-block tw-relative tw-text-sm tw-leading-4 tw-text-light-blue-400'])}
                >
                  {item.isCompleted ? (
                    <span
                      className={classNames([
                        'tw--left-4 tw-top-0.5 tw-z-1 tw-transform tw--translate-x-1',
                        'tw-w-3 tw-h-3 tw-absolute tw-rounded-full tw-border tw-border-cool-gray-200 tw-bg-white',
                      ])}
                    >
                      <IconCheck className="tw-absolute tw--top-0.5 tw-text-green-500" />
                    </span>
                  ) : (
                    <span
                      className={classNames([
                        'tw--left-4 tw-top-0.5 tw-z-1 tw-transform tw--translate-x-1 tw-flex tw-items-center tw-justify-center',
                        'tw-w-3 tw-h-3 tw-absolute tw-rounded-full tw-border tw-border-cool-gray-200 tw-bg-white',
                      ])}
                    >
                      {item.items && item.items.some((item) => item.isCompleted) && (
                        <i className="tw-w-1.5 tw-h-1.5 tw-block tw-rounded-full tw-bg-green-500"></i>
                      )}
                    </span>
                  )}
                  {item.label}
                  <IconArrowRightSmall className="tw-align-middle tw-ml-0.5" />
                </NavLink>
                <ul className="tw-m-0 tw-pt-0.5 tw-pl-6 tw-list-none">
                  {item.items &&
                    item.items.map((item) => (
                      <li key={item.label} className="tw-mb-1.5 tw-block tw-leading-4 tw-relative last:tw-mb-0">
                        {item.isCompleted && <IconCheck className="tw-absolute tw-top-1 tw--left-4 tw-text-green-500" />}
                        <span className="tw-inline-block tw-text-sm tw-leading-4 tw-text-blue-gray-700">{item.label}</span>
                      </li>
                    ))}
                </ul>
                {item.description && <p className="tw-block tw-m-0 tw-text-sm tw-leading-4 tw-text-cool-gray-400">{item.description}</p>}
              </div>
            );
          })}
        </div>
        <ProgressBar total={totalNumber} completed={completedNumber} />
      </div>
    </CSSTransition>
  );
};
