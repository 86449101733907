import React, { FC } from 'react';

export const IconCampaigns: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M10.8202 4.66683H12.5535C12.5135 4.3535 12.3135 4.04016 12.0268 3.88683L7.00016 1.3335L1.86683 3.88683C1.54683 4.06016 1.3335 4.42683 1.3335 4.78016V10.0002C1.3335 10.7335 1.9335 11.3335 2.66683 11.3335V4.9335L7.00016 2.66683L10.8202 4.66683Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M13.3335 5.3335H4.66683C3.9335 5.3335 3.3335 5.9335 3.3335 6.66683V12.6668C3.3335 13.4002 3.9335 14.0002 4.66683 14.0002H13.3335C14.0668 14.0002 14.6668 13.4002 14.6668 12.6668V6.66683C14.6668 5.9335 14.0668 5.3335 13.3335 5.3335ZM13.3335 12.6668H4.66683V8.00016L9.00016 10.2202L13.3335 8.00016V12.6668ZM9.00016 8.88683L4.66683 6.66683H13.3335L9.00016 8.88683Z"
      fill="currentColor"
    />
  </svg>
);
