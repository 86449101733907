import React, { FC } from 'react';

export const IconPrintTags: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M14.6667 2H4.66667C4.20667 2 3.84667 2.23333 3.60667 2.58667L0 8L3.60667 13.4067C3.84667 13.76 4.25333 14 4.71333 14H14.6667C15.4 14 16 13.4 16 12.6667V3.33333C16 2.6 15.4 2 14.6667 2ZM14.6667 12.6667H4.71333L1.6 8L4.70667 3.33333H14.6667V12.6667Z"
      fill="currentColor"
    />
    <rect x="5" y="5.5" width="8" height="1" fill="currentColor" />
    <rect x="5" y="7.5" width="6" height="1" fill="currentColor" />
    <rect x="5" y="9.5" width="8" height="1" fill="currentColor" />
  </svg>
);
