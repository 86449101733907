import React, { FC } from 'react';
import { IconProps } from './type';

export const IconTrash: FC<React.PropsWithChildren<IconProps>> = ({ height = 24, width = 24, className = '' }) => (
  // @ts-ignore slot attribute is required for Market but isn't part of the React SVG type
  <svg slot="icon" className={className} width={width} height={height} viewBox="6 6 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 10.8008H10.4H21.6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d={`
      M12.4994 10.8V9.4C12.4994 9.0287 12.6469 8.6726
      12.9095 8.41005C13.172 8.1475 13.5281 8 13.8994 8H16.6994C17.0707
      8 17.4268 8.1475 17.6894 8.41005C17.9519 8.6726 18.0994 9.0287 18.0994 9.4V10.8M20.1994 10.8V20.6C20.1994 20.9713
      20.0519 21.3274 19.7894 21.5899C19.5268 21.8525 19.1707 22 18.7994 22H11.7994C11.4281
      22 11.072 21.8525 10.8095 21.5899C10.5469 21.3274 10.3994 20.9713 10.3994 20.6V10.8H20.1994Z`}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M13.8994 14.3008V18.5008" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.7002 14.3008V18.5008" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
