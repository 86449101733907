import React, { FC } from 'react';
import { MarketButton, MarketField, MarketInputText } from '@market/react-bindings';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketInputTextCustomEvent } from '@market/web-components';

export interface EmailInputProps {
  index: number;
  error?: string;
  value?: string;
  hasDeleteButton: boolean;
  onInput: (e: MarketInputTextCustomEvent<{ value: string; originalEvent: KeyboardEvent }>, index: number) => void;
  onDelete: (index: number) => void;
  label?: string;
}

export const EmailInput: FC<React.PropsWithChildren<EmailInputProps>> = ({ index, value, error = '', hasDeleteButton, onInput, onDelete, label }) => (
  <MarketField name={`email-${index}`} invalid={Boolean(error)} className="tw-mb-4">
    <div className="tw-flex tw-w-full tw-gap-x-4">
      <MarketInputText value={value && value} type="email" autovalidate onMarketInputValueChange={(e) => onInput(e, index)} className="tw-w-full">
        <label>{label || getIntlString('page.onboarding-franchise.form.field.email')}</label>
      </MarketInputText>
      {hasDeleteButton && (
        <MarketButton role="button" rank="secondary" variant="destructive" onClick={() => onDelete(index)} className="tw-flex">
          {getIntlString('button.delete')}
        </MarketButton>
      )}
    </div>
    {error && <small slot="error">{error}</small>}
  </MarketField>
);
