import classNames from 'classnames';
import React from 'react';

export type InputWithAddonProps = {
  className?: string;
  input: React.ReactNode;
  addon?: React.ReactNode;
  reverse?: boolean;
};

export const InputWithAddon: React.FC<React.PropsWithChildren<InputWithAddonProps>> = ({ className, addon, input, reverse }) => (
  <div className={classNames([className, 'tw-flex tw-w-full', reverse && 'tw-flex-row-reverse'])}>
    <div className="tw-flex-1">{input}</div>
    {addon}
  </div>
);
