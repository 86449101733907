import * as React from 'react';
import classNames from 'classnames';
import { IconProps } from './type';

export const IconDragAndDrop: React.FC<React.PropsWithChildren<IconProps>> = ({ className, height = 20, width = 20 }) => (
  <svg className={classNames(['tw-cursor-move', className])} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="2" y1="5.5" x2="18" y2="5.5" stroke="currentColor" />
    <line x1="2" y1="9.5" x2="18" y2="9.5" stroke="currentColor" />
    <line x1="2" y1="13.5" x2="18" y2="13.5" stroke="currentColor" />
  </svg>
);
