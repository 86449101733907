import * as React from 'react';
import classNames from 'classnames';
import { IconImagePlaceholder } from '../icons/IconImagePlaceholder';
import { Button } from '../button/Button';

export type ImageUploadMenuProps = {
  info?: string | React.ReactNode;
  buttonText?: string | React.ReactNode;
  className?: string;
  hasError?: boolean;
  onClick(): void;
  disabled?: boolean;
  uploaderHeightClass?: string;
  isLoading: boolean;
};

const DEFAULT_MESSAGE = 'Click to upload image';

export const ImageUploadMenu: React.FC<React.PropsWithChildren<ImageUploadMenuProps>> = ({
  hasError,
  className,
  info = DEFAULT_MESSAGE,
  buttonText = 'Upload',
  onClick,
  disabled,
  uploaderHeightClass = 'tw-h-40',
  isLoading,
}) => {
  return (
    <div
      className={classNames([
        'tw-border tw-border-px tw-pt-8 tw-px-4.5 tw-pb-4 tw-text-center tw-space-y-1',
        hasError ? 'tw-border-red-300' : 'tw-border-cool-gray-200',
        className,
        uploaderHeightClass,
      ])}
    >
      <IconImagePlaceholder className="tw-text-cool-gray-300" />
      <div className="tw-mx-auto tw-max-w-min">
        <Button
          outline={true}
          color="clean"
          size="clean"
          onClick={onClick}
          loading={isLoading}
          disabled={disabled}
          className={classNames([
            'tw-px-5 tw-py-1 tw-bg-transparent tw-border',
            'focus:tw-ring-light-blue-300 tw-text-sm tw-font-normal',
            disabled ? 'tw-border-gray-400 tw-text-gray-400' : 'tw-border-light-blue-400 tw-text-light-blue-400',
          ])}
        >
          {buttonText}
        </Button>
      </div>
      <div className="tw-mx-auto tw-text-center tw-font-normal tw-text-xs tw-text-cool-gray-400">{info}</div>
    </div>
  );
};
