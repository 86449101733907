import React, { FC } from 'react';

type PanelTitleProps = {
  hasMargin?: boolean;
  testId?: string;
};
export const PanelTitle: FC<React.PropsWithChildren<PanelTitleProps>> = ({ hasMargin = true, children, testId = '' }) => (
  <h3 data-testid={testId} className={`tw-font-bold tw-text-lg tw-text-blue-gray-900 ${hasMargin ? 'tw-mb-4' : 'tw-mb-0'}`}>
    {children}
  </h3>
);
