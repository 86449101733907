import classNames from 'classnames';
import React, { FC } from 'react';
import { components, ControlProps, PlaceholderProps, IndicatorProps, SingleValueProps, OptionProps, MultiValueProps } from 'react-select';
import { MenuPortalProps, MenuProps } from 'react-select/src/components/Menu';

import { SelectOptionType } from './types';

const Control: FC<React.PropsWithChildren<ControlProps<SelectOptionType, true | false>>> = ({ children, isFocused, isDisabled, ...props }) => (
  <components.Control
    {...props}
    isFocused={isFocused}
    isDisabled={isDisabled}
    className={classNames([
      'tw-w-full tw-shadow-none',
      isFocused ? 'tw-border-light-blue-400 tw-border' : 'tw-border-blue-gray-200',
      isDisabled && 'tw-bg-gray-100',
    ])}
  >
    <div className="tw-flex tw-w-full tw-items-center">
      {props?.selectProps?.appendIcon ? <span className="tw-pl-4 tw--mb-1">{props?.selectProps?.appendIcon}</span> : null}
      {children}
    </div>
  </components.Control>
);

const Placeholder: FC<React.PropsWithChildren<PlaceholderProps<SelectOptionType, true | false>>> = (props) => (
  <components.Placeholder {...props} className={props.isDisabled ? '' : props?.selectProps?.placeholderColor ?? 'tw-text-light-blue-500'} />
);
const DropdownIndicator: FC<React.PropsWithChildren<IndicatorProps<SelectOptionType, true | false>>> = (props) => (
  <components.DropdownIndicator {...props} className="tw-text-blue-gray-900" />
);

const SingleValue: FC<React.PropsWithChildren<SingleValueProps<SelectOptionType>>> = ({ children, ...props }) => (
  <components.SingleValue
    {...props}
    className={classNames([props.isDisabled ? 'tw-text-gray-600' : props?.selectProps?.selectedValueColor ?? 'tw-text-light-blue-400'])}
  >
    {children}
  </components.SingleValue>
);

const Menu: FC<React.PropsWithChildren<MenuProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  <components.Menu {...props} className="menu hover:tw-cursor-pointer">
    {children}
  </components.Menu>
);

const MultiValue: FC<React.PropsWithChildren<MultiValueProps<SelectOptionType>>> = (props) => (
  <div className="tw-py-1 tw-px-2 tw-m-1 tw-text-blue-gray-900 tw-bg-cool-gray-100 tw-text-sm tw-flex tw-items-center">
    {props.data.label}
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props.removeProps}
      className="tw-ml-1 hover:tw-text-light-blue-500"
    >
      <path d="M10.3333 1.33331L1 10.6666" stroke="currentColor" />
      <path d="M0.998698 1.33331L10.332 10.6666" stroke="currentColor" />
    </svg>
  </div>
);

const MenuPortal: FC<React.PropsWithChildren<MenuPortalProps<SelectOptionType, true | false>>> = ({ children, ...props }) => (
  // this z-index makes select visible inside Modal
  <components.MenuPortal {...props} className={classNames(['tw-z-max', props.className])}>
    {children}
  </components.MenuPortal>
);

const Option: FC<React.PropsWithChildren<OptionProps<SelectOptionType, true | false>>> = ({ children, isFocused, isSelected, ...props }) => (
  <components.Option
    {...props}
    isFocused={isFocused}
    isSelected={isSelected}
    className={classNames([
      `hover:tw-bg-light-blue-100 tw-transition-colors tw-ease-in-out tw-text-blue-gray-900 tw-cursor-pointer`,
      (isFocused || isSelected) && 'tw-bg-light-blue-50',
      isSelected ? 'tw-font-medium' : 'tw-font-normal',
    ])}
  >
    {children}
  </components.Option>
);

export const OutlineSelectComponents = {
  IndicatorSeparator: () => null,
  DropdownIndicator,
  Placeholder,
  SingleValue,
  Control,
  MenuPortal,
  Menu,
  Option,
  MultiValue,
};
