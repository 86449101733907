import React, { FC } from 'react';

interface IconProps {
  className?: string;
}

export const IconMail: FC<React.PropsWithChildren<IconProps>> = ({ className = '' }) => (
  <svg className={className} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M19 5H5C3.34 5 2 6.34 2 8V16C2 17.66 3.34 19 5 19H19C20.66 19 22 17.66 22 16V8C22 6.34 20.66 5 19 5ZM18.83 7L12 11.78L5.17 7H18.83ZM19 17H5C4.45 17 4 16.55 4 16V8.62L11.43 13.82C11.6 13.94 11.8 14 12 14C12.2 14 12.4 13.94 12.57 13.82L20 8.62V16C20 16.55 19.55 17 19 17Z"
      fill="currentColor"
      fillOpacity="0.9"
    />
  </svg>
);
