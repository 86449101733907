import React, { FC } from 'react';

export const IconDiningOptions: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M11.0268 7.33345L8.66683 4.97345L9.60683 4.03345L11.0202 5.44678L13.8468 2.62012L14.7868 3.56012L11.0268 7.33345ZM7.3335 4.66678H1.3335V6.00012H7.3335V4.66678ZM14.0002 8.94012L13.0602 8.00012L11.3335 9.72678L9.60683 8.00012L8.66683 8.94012L10.3935 10.6668L8.66683 12.3935L9.60683 13.3335L11.3335 11.6068L13.0602 13.3335L14.0002 12.3935L12.2735 10.6668L14.0002 8.94012ZM7.3335 10.0001H1.3335V11.3335H7.3335V10.0001Z"
      fill="currentColor"
    />
  </svg>
);
