import React, { FC, MutableRefObject, useEffect, useRef, useState, useCallback } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import debounce from 'lodash/debounce';
import { IconArrowRightSmall } from '../icons';
import { Button } from '../button/Button';

const TabsContext = React.createContext<{ vertical?: boolean }>({} as any);

export type RoutableTabsProps = {
  vertical?: boolean;
};

const AUTO_SCROLL_OFFSET = 100;

export const RoutableTabsWrapper: FC<React.PropsWithChildren<RoutableTabsProps>> = ({ children, vertical }) => {
  return (
    <TabsContext.Provider value={{ vertical }}>
      <div className={classNames([vertical && 'sm:tw-flex'])}>{children}</div>
    </TabsContext.Provider>
  );
};

export const RoutableTabsList: FC<React.PropsWithChildren<{ isOutlined?: boolean }>> = ({ children, isOutlined = false }) => {
  const tabsListRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { vertical } = React.useContext(TabsContext);
  const location = useLocation();
  const [scrollLeftDisabled, setScrollLeftDisabled] = useState<boolean>(true);
  const [scrollRightDisabled, setScrollRightDisabled] = useState<boolean>(false);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  const autoScroll = useCallback(
    debounce((element: HTMLElement) => {
      const containerWidth = element.offsetWidth;
      element?.querySelectorAll('a').forEach((el, i) => {
        if (el.href.split('/').pop() === location.pathname.split('/').pop()) {
          element.scrollLeft = i * AUTO_SCROLL_OFFSET;
        }
      });
      setScrollLeftDisabled(!element.scrollLeft);
      setScrollRightDisabled(element.scrollLeft + containerWidth >= element.scrollWidth);
    }, 1000),
    [],
  );

  useEffect(() => {
    let isCancelled = false;
    if (tabsListRef.current) {
      if (!isCancelled) {
        setIsScrollable(tabsListRef.current.scrollWidth > tabsListRef.current.offsetWidth);
      }
      autoScroll(tabsListRef.current);
    }
    return () => {
      isCancelled = true;
    };
  }, [autoScroll]);

  const sideScroll = (element: HTMLElement, speed: number, step: number): void => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      const containerWidth = element.offsetWidth;
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= containerWidth - 80) {
        clearInterval(slideTimer);
      }
      setScrollLeftDisabled(!element.scrollLeft);
      setScrollRightDisabled(element.scrollLeft + containerWidth >= element.scrollWidth);
    }, speed);
  };

  return (
    <div data-testid="tabs" className="tw-flex">
      {!vertical && isScrollable && (
        <Button
          className={classNames(['tw-h-9 tw-mr-1 tw-w-10', !isOutlined && 'tw--mt-2'])}
          color="clean"
          kind="clean"
          onClick={() => {
            if (tabsListRef.current) {
              sideScroll(tabsListRef.current, 15, -100);
            }
          }}
          disabled={scrollLeftDisabled}
        >
          <IconArrowRightSmall className="tw-transform tw-rotate-180 tw-min-w-max tw-h-3.5 tw-w-3.5 tw-mx-1 tw-text-gray-500" />
        </Button>
      )}
      <TabListWrapper ref={tabsListRef} isOutlined={isOutlined}>
        <div
          className={classNames([
            !isOutlined && 'tw-overflow-hidden',
            'tw-max-w-full tw-w-full tw-flex tw-flex-col tw-border-b tw-border-blue-gray-900 tw-border-opacity-10 tw-text-blue-gray-600',
            'tw-transition-all tw-duration-300',
            vertical ? 'tw-mr-3 tw-mb-8 sm:tw-w-44 sm:tw-mb-0 sm:tw-border-b-0 sm:tw-border-r tw-flex-col' : 'tw-mb-8 sm:tw-flex-row',
          ])}
          ref={!isOutlined ? tabsListRef : null}
        >
          {children}
        </div>
      </TabListWrapper>
      {!vertical && isScrollable && (
        <Button
          className={classNames(['tw-h-9 tw-ml-1 tw-w-10', !isOutlined && 'tw--mt-2'])}
          color="clean"
          kind="clean"
          onClick={() => {
            if (tabsListRef.current) {
              sideScroll(tabsListRef.current, 15, 100);
            }
          }}
          disabled={scrollRightDisabled}
        >
          <IconArrowRightSmall className="tw-min-w-max tw-h-3.5 tw-w-3.5 tw-mx-1 tw-text-gray-500" />
        </Button>
      )}
    </div>
  );
};

const TabListWrapper = React.forwardRef<HTMLDivElement, { isOutlined: boolean; children: React.ReactNode }>(({ isOutlined, children }, ref) => (
  <>
    {isOutlined ? (
      <div className="tw-w-full tw-overflow-hidden" ref={ref}>
        {children}
      </div>
    ) : (
      children
    )}
  </>
));

export const RoutableTab: FC<
  React.PropsWithChildren<{
    path: string;
    name: JSX.Element | string;
    isDisabled?: boolean;
    exact?: boolean;
    customIsActive?: boolean;
    isOutlined?: boolean;
    testId?: string;
  }>
> = ({ name, exact, path, isDisabled, customIsActive = null, isOutlined = false, testId }) => {
  const { vertical } = React.useContext(TabsContext);
  const location = useLocation();
  const isActive = customIsActive ?? location.pathname === path;

  return (
    <NavLink
      to={path}
      exact={exact}
      aria-disabled={isDisabled}
      aria-selected={isActive}
      data-testid={testId}
      activeClassName="tw-border-light-blue-400"
      className={classNames(['tw-outline-none', getClassNames({ isOutlined, isDisabled, isActive, vertical })])}
    >
      <div className="tw-w-max">{name}</div>
    </NavLink>
  );
};

const getClassNames = ({ isOutlined, isDisabled, vertical, isActive }: any) => {
  return isOutlined
    ? [
        'tw-py-2 tw-px-3 tw-rounded-t-md tw-text-blue-gray-900 tw-font-normal tw--mb-px sm:tw-mr-0.5',
        isActive ? 'tw-border-cool-gray-200 tw-bg-white tw-border-l tw-border-t tw-border-r' : ' tw-border-b tw-bg-cool-gray-100 tw-border-blue-gray-200',
      ]
    : [
        'tw-py-1 sm:tw-py-0 tw-pl-1 sm:tw-pl-0 last:tw-mr-0 tw--mb-px tw-border-l-2 sm:tw-border-l-0 hover:tw-text-light-blue-400',
        'tw-outline-none tw-text-left sm:tw-text-center',
        isDisabled && 'tw-text-blue-gray-600 tw-text-opacity-20 tw-pointer-events-none',
        vertical ? 'sm:tw-py-1 sm:tw-border-r-2' : 'tw-mr-0 sm:tw-mr-5 sm:tw-pb-4 sm:tw-border-b-2 tw-leading-4',
        isActive ? 'tw-text-light-blue-400 tw-border-light-blue-400' : 'tw-text-blue-gray-600 tw-border-transparent',
      ];
};
