import { MarketPagination, MarketRow } from '@market/react-bindings';
import * as React from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';

export const PAGE_SIZE = 20;

export type PaginationProps = {
  currentPage: number;
  total: number;
  limit?: number;
  onPageChange(page: number): void;
  testId?: string;
};
export const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = ({ currentPage, total, limit = PAGE_SIZE, onPageChange, testId }) => {
  const totalPages = Math.ceil(total / limit);
  const handlePageChange = ({ detail }: CustomEvent<{ page: string; prevPage: string; pageSize: string }>) => onPageChange(Number(detail.page));

  return (
    <MarketRow className="market-grid-item-full" data-testid={testId}>
      <MarketPagination
        hasNextPage={totalPages !== currentPage}
        hasPreviousPage={currentPage !== 1}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={limit.toString()}
        onMarketPaginationNavigation={handlePageChange}
        page-size-options=""
      >
        {getIntlString('pagination.label', {
          page: currentPage,
          totalPages: totalPages,
          label: (chunk: string) => <span slot="nav-label">{chunk}</span>,
          feedback: (chunk: string) => <span slot="nav-feedback">{chunk}</span>,
        })}
      </MarketPagination>
    </MarketRow>
  );
};
