import React, { FC, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import { Radio as BaseRadio, RadioGroup, useRadioState, RadioProps as BaseRadioProps, RadioStateReturn } from 'reakit/Radio';
import classNames from 'classnames';

export type RadioRenderOptionProps = BaseRadioProps & {
  onSelect(): void;
  label: string | React.ReactNode;
};

export type RenderOptionType = ForwardRefExoticComponent<PropsWithoutRef<RadioRenderOptionProps> & RefAttributes<HTMLInputElement>>;

const RenderOption = React.forwardRef<HTMLInputElement, RadioRenderOptionProps>((props, ref) => {
  const onInputChange = (e: any) => {
    props.onSelect(e?.target?.value);
    props.onChange?.(e);
    props.onBlur && props.onBlur(e);
  };
  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-transition-colors tw-ease-in-out">
      <input
        {...props}
        onChange={onInputChange}
        ref={ref}
        className={classNames([
          'tw-appearance-none tw-inline-block tw-align-middle tw-select-none tw-flex-shrink-0 tw-h-4 tw-w-4',
          'tw-text-blue-600 tw-border tw-rounded-full focus:tw-shadow-radio-focus focus:tw-outline-none',
          props.checked
            ? classNames(['tw-border-transparent tw-bg-center tw-bg-no-repeat tw-bg-radio-checked tw-bg-light-blue-400', 'disabled:tw-bg-light-blue-200'])
            : 'tw-border-gray-400 disabled:tw-bg-gray-50',
        ])}
      />
      <label htmlFor={props.id} className={classNames(['tw-px-4 tw-py-1 tw-block tw-text-sm', props.disabled ? 'tw-cursor-not-allowed' : ''])}>
        {props.label}
      </label>
    </div>
  );
});

type OptionType =
  | string
  | number
  | {
      label: string | React.ReactNode;
      value: string | number;
      checked?: boolean;
    };

export type RadioProps = Omit<Partial<BaseRadioProps>, 'onChange' | 'state'> & {
  value?: string | number;
  options: OptionType[];
  ariaLabel: string;
  className?: string;
  radioGroupClassName?: string;
  onChange(value: string | number): void;
  onBlur?(): void;
  disabled?: boolean;
  CustomRenderOption?: RenderOptionType;
  layout?: 'row' | 'column';
  state?: Partial<RadioStateReturn>;
};

export const Radio: FC<React.PropsWithChildren<RadioProps>> = ({
  value,
  options,
  onChange,
  onBlur,
  ariaLabel,
  disabled = false,
  className,
  CustomRenderOption,
  radioGroupClassName = '',
  layout = 'row',
  state,
}) => {
  const radio = useRadioState({ state: value });

  return (
    <div className={classNames(['tw-inline-block', className])}>
      <RadioGroup
        {...(state ?? radio)}
        aria-label={ariaLabel}
        disabled={disabled}
        className={classNames(['tw-p-1', 'row' === layout ? 'tw-flex' : '', radioGroupClassName])}
      >
        {options.map((option) => {
          if (option instanceof Object) {
            return (
              <BaseRadio
                {...(state ?? radio)}
                key={option.value}
                as={CustomRenderOption ?? RenderOption}
                label={option.label}
                value={option.value}
                disabled={disabled}
                onBlur={onBlur}
                onSelect={() => onChange(option.value)}
              />
            );
          }
          return (
            <BaseRadio
              {...(state ?? radio)}
              key={option}
              as={CustomRenderOption ?? RenderOption}
              label={option}
              value={option}
              disabled={disabled}
              onBlur={onBlur}
              onSelect={() => onChange(option)}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};
