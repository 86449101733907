import React, { FC } from 'react';
import classNames from 'classnames';

interface IconProps {
  className?: string;
}

export const IconDownloadLarge: FC<React.PropsWithChildren<IconProps>> = ({ className }) => (
  <svg className={classNames([className])} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 42.5L30 52.5L40 42.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30 30V52.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      // eslint-disable-next-line max-len
      d="M52.2009 45.225C54.3744 43.6966 56.0043 41.5154 56.8542 38.9979C57.704 36.4804 57.7295 33.7575 56.9269 31.2246C56.1244 28.6916 54.5356 26.4802 52.3911 24.9114C50.2466 23.3426 47.658 22.4979 45.0009 22.5H41.8509C41.099 19.5697 39.6922 16.8481 37.7362 14.5402C35.7803 12.2323 33.3263 10.3982 30.559 9.17588C27.7916 7.95362 24.783 7.37507 21.7597 7.48379C18.7365 7.59252 15.7772 8.38568 13.1048 9.80357C10.4324 11.2215 8.11649 13.2271 6.3314 15.6696C4.54631 18.112 3.33856 20.9276 2.79908 23.9044C2.25961 26.8811 2.40246 29.9415 3.21688 32.8551C4.0313 35.7686 5.49608 38.4595 7.50093 40.725"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
