import React, { FC } from 'react';

interface IconProps {
  className?: string;
}

export const ArrowDown: FC<React.PropsWithChildren<IconProps>> = ({ className }) => (
  <svg className={`tw-inline ${className}`} width="15" height="10" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 4L0.401924 0.625L5.59808 0.625001L3 4Z" fill="currentColor" />
  </svg>
);
