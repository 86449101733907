import React, { FC } from 'react';

export const IconCohorts: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M13.3335 3.99984H8.00016L6.66683 2.6665H2.66683C1.9335 2.6665 1.34016 3.2665 1.34016 3.99984L1.3335 11.9998C1.3335 12.7332 1.9335 13.3332 2.66683 13.3332H13.3335C14.0668 13.3332 14.6668 12.7332 14.6668 11.9998V5.33317C14.6668 4.59984 14.0668 3.99984 13.3335 3.99984ZM13.3335 11.9998H2.66683V3.99984H6.1135L7.44683 5.33317H13.3335V11.9998ZM10.0002 8.6665C10.7335 8.6665 11.3335 8.0665 11.3335 7.33317C11.3335 6.59984 10.7335 5.99984 10.0002 5.99984C9.26683 5.99984 8.66683 6.59984 8.66683 7.33317C8.66683 8.0665 9.26683 8.6665 10.0002 8.6665ZM7.3335 11.3332H12.6668V10.6665C12.6668 9.77984 10.8868 9.33317 10.0002 9.33317C9.1135 9.33317 7.3335 9.77984 7.3335 10.6665V11.3332Z"
      fill="currentColor"
    />
  </svg>
);
