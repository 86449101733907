import * as React from 'react';
import classNames from 'classnames';

export type DefaultFeedbackProps = {
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
};

type BaseFeedbackProps = DefaultFeedbackProps & {
  backgroundColor?: string;
  textColor: string;
  icon: React.ReactNode;
};

export const BaseFeedback: React.FC<React.PropsWithChildren<BaseFeedbackProps>> = ({
  className,
  backgroundColor = 'tw-bg-red-50',
  textColor,
  title,
  subtitle,
  icon,
}) => (
  <div className={classNames([className, backgroundColor, 'tw-rounded-lg tw-flex tw-items-center'])}>
    <div className={classNames(['tw-p-8', textColor])}>
      <div className="tw-flex tw-space-x-2">
        <div>{icon}</div>
        <div>
          <div className="tw-mb-0 tw-text-lg tw-font-medium">{title}</div>
          {subtitle && <div className="tw-text-base tw-mt-1">{subtitle}</div>}
        </div>
      </div>
    </div>
  </div>
);
