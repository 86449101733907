import { IDateRange } from '@goparrot/store-v2-sdk';

import { getHour } from './getHour';
import { getMins } from './getMins';

export const getHoursAndMinutes = (interval: IDateRange) => {
  return {
    fromHour: getHour(interval.from),
    fromMinute: getMins(interval.from),
    toHour: getHour(interval.to),
    toMinute: getMins(interval.to),
  };
};
