import React from 'react';
import { MarketDialog, MarketFooter, MarketButton, MarketButtonGroup } from '@market/react-bindings';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { DialogStateReturn } from './DialogStateReturn';
import { InModal } from './InModal';

type Props = {
  dialog: DialogStateReturn;
  onCancel?: () => void;
  handleDelete: () => void;
  title: string;
  dialogText: string | JSX.Element;
  isDeleting?: boolean;
  deleteText?: string;
  cancelText?: string;
  callOnCancelOnDialogDismissed?: boolean;
};

export const DeleteConfirmModal: React.FC<React.PropsWithChildren<Props>> = ({
  dialog,
  handleDelete,
  onCancel,
  dialogText,
  title,
  isDeleting,
  deleteText = getIntlString('button.delete'),
  cancelText = getIntlString('button.cancel'),
}) => {
  const { visible, dialogRef, dismiss } = dialog;

  const handleCancel = () => {
    dismiss();
    onCancel?.();
  };

  return visible ? (
    <InModal>
      <MarketDialog onMarketDialogDismissed={handleCancel} ref={dialogRef}>
        <main>
          <h2 data-testid="remove-confirmation-modal-header">{title}</h2>
          <p data-testid="remove-confirmation-modal-content" className="paragraph-30">
            {dialogText}
          </p>
        </main>
        <MarketFooter data-testid="remove-confirmation-modal-footer">
          <MarketButtonGroup alignment="fill">
            <MarketButton rank="primary" variant="destructive" onClick={handleDelete} isLoading={isDeleting || undefined}>
              {deleteText}
            </MarketButton>
            <MarketButton rank="secondary" variant="destructive" onClick={onCancel || dismiss}>
              {cancelText}
            </MarketButton>
          </MarketButtonGroup>
        </MarketFooter>
      </MarketDialog>
    </InModal>
  ) : null;
};
