import React, { FC } from 'react';

export const IconFolder: FC<{ className?: string }> = ({ className = '' }) => (
  // @ts-ignore slot attribute is required for market, but not part of the React svg type.
  <svg slot="icon" className={className} width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M9.17 6.00977L10.58 7.41977L11.17 7.99977H12H19C19.55 7.99977 20 8.44977 20 8.99977V16.9998C20 17.5498 19.55 17.9998 19 17.9998H5C4.45 17.9998 4 17.5498 4 16.9998V7.00977C4 6.45977 4.45 6.00977 5 6.00977H9.17ZM10 4.00977H5C3.34 4.00977 2 5.34977 2 7.00977V16.9998C2 18.6598 3.34 19.9998 5 19.9998H19C20.66 19.9998 22 18.6598 22 16.9998V8.99977C22 7.33977 20.66 5.99977 19 5.99977H12L10 4.00977Z"
      fill="black"
      fillOpacity="0.9"
    />
  </svg>
);
