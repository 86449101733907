import * as React from 'react';
import { IconProps } from './type';
export const IconUpload: React.FC<React.PropsWithChildren<IconProps>> = ({ width = 20, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M11 4.41006V13.0001H9.00004V4.41006L6.70004 6.70006L5.29004 5.29006L9.29004 1.29006C9.68004 0.900059 10.31 0.900059 10.7 1.29006L14.7 5.29006L13.29 6.70006L11 4.41006ZM17 16C17.55 16 18 15.55 18 15V11H20V15C20 16.66 18.66 18 17 18H3C1.34 18 0 16.66 0 15L0.04 11.04H2.04L2 15C2 15.55 2.45 16 3 16H17Z"
      fill="currentColor"
    />
  </svg>
);
