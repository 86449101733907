import * as React from 'react';
import classNames from 'classnames';

import { IconPlusOutlined } from '../icons/IconPlusOutlined';
import { Button } from '../button/Button';

export type ImageUploadProps = {
  title?: string | React.ReactNode;
  className?: string;
  hasError?: boolean;
  errorMessage?: React.ReactNode | string;
  onClick(): void;
  disabled?: boolean;
  uploaderHeightClass?: string;
};

const DEFAULT_MESSAGE = 'Click to upload image';

export const ImageUpload: React.FC<React.PropsWithChildren<ImageUploadProps>> = ({
  hasError,
  errorMessage,
  className,
  uploaderHeightClass = 'tw-h-56',
  title = DEFAULT_MESSAGE,
  onClick,
  disabled,
}) => {
  return (
    <div className={classNames([hasError ? 'tw-border tw-border-red-300 tw-rounded-md' : ''])}>
      <Button
        disabled={disabled}
        kind="clean"
        color="primaryLight"
        className={classNames([
          'tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-space-y-2',
          className,
          uploaderHeightClass,
          hasError ? 'tw-rounded-t-inherit' : 'tw-rounded-md',
        ])}
        onClick={onClick}
      >
        <IconPlusOutlined />
        <span>{title}</span>
      </Button>
      {hasError && errorMessage && (
        <div className="tw-text-center tw-py-1 tw-bg-red-100 tw-text-red-600 tw-rounded-b-inherit">• {(errorMessage as string).replace('url', 'image')}</div>
      )}
    </div>
  );
};
