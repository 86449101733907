import React, { FC, useCallback, useEffect, useRef } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

import { Input } from '../input/Input';

const isNullishValue = (value: unknown): value is undefined | null => value === undefined || value === null;

export type InputNumberProps = Omit<NumberFormatProps, 'onChange'> & {
  min?: number;
  max?: number;
  // value to be set when input is cleared
  fallbackValue?: number;
  value?: number;
  onChange?: (value: number | undefined) => void;
  onBlur?: (event: any) => void;
  prefix?: string;
};

export const InputNumber: FC<React.PropsWithChildren<InputNumberProps>> = ({
  min = -Infinity,
  max = Infinity,
  fallbackValue,
  value,
  onChange = () => undefined,
  onBlur,
  customInput = Input,
  prefix,
  ...numberFormatProps
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const handleNumberFormatChange = useCallback((value: NumberFormatValues) => onChange(value.floatValue), [onChange]);
  const handleInputBlur = useCallback(
    (event: any) => {
      if (fallbackValue !== undefined && isNullishValue(value)) {
        onChange(fallbackValue);
      } else if (-Infinity !== min && isNullishValue(value)) {
        onChange(min);
      } else if (!isNullishValue(value)) {
        const newValue = Math.min(Math.max(min, value), max);
        onChange(newValue);
      }
      onBlur && onBlur(event);
    },
    [value, fallbackValue, onBlur, onChange, min, max],
  );

  useEffect(() => {
    // set empty input when value is undefined
    if (isNullishValue(value) && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [value]);

  useEffect(() => {
    if (fallbackValue !== undefined && isNullishValue(value)) {
      onChange(fallbackValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NumberFormat
      getInputRef={inputRef}
      prefix={prefix}
      value={value}
      onValueChange={handleNumberFormatChange}
      onBlur={handleInputBlur}
      customInput={customInput}
      {...numberFormatProps}
    />
  );
};
