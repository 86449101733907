import { DialogElement } from '@market/web-components';
import React, { FC, ReactNode, useEffect, useRef } from 'react';

import { useMarketContext } from '../../providers/MarketContextProvider';
import styles from './modal.module.scss';

interface InModalProps {
  children: ReactNode;
}

/**
 * Market modals, and Web Components in general, make use of the `<template>` tag.
 * However, this paradigm really doesn't work well with React.
 *
 * Our goal here is to create a simple component that, when it enters the DOM, it pushes its
 * children into a market-context-manager with the approprirate market modal. When this component
 * leaves the DOM, we want the modal to close, similar to the Market Ember {{open-modal}} modifier
 *
 * We need 3 pieces:
 * 1. An element still in the normal DOM and document flow, outside of the market context. This way, we know
 * when to dismiss the modal when it goes out of the DOM. We make this `display: none` to ensure it doesn't appear
 * to the user.
 * 2. The contents of the modal, where the top level node is one
 * of a `MarketModalPartial`, `MarketModalFull`, `MarketDialog`, or `MarketBlade`.
 *
 * With these, we do the following:
 * 1. Get a ref to the normal container element on the page
 * 2. Get the market-context-manager from the `useMarketContext` hook. This must be created yourself near the root
 * for rendering purposes
 * 3. Call the context manager's open() function with the first child of our generic container element in the
 * normal document flow. Market's context manager will copy that, and render it in the modal.
 *
 * Now you can create modals using normal `useState` to control the visibility.
 *
 * Note that this relies on you actually creating a MarketContextManager first in the document
 *
 * Usage
 * ```tsx
 * {showModal &&
 *   <InModal>
 *     <MarketModalPartial onMarketDialogDismissed={() => setShowModal(false)}>
 *       <MarketHeader>Hello There!</MarketHeader>
 *       <main>This is some content</main>
 *   </MarketModalPartial>
 * </InModal>}
 * ```
 */

const MODAL_Z_INDEX = 'tw-z-53'; // modals need to have a minimum z index of 53 to beat the top menu bar;

export const InModal: FC<React.PropsWithChildren<InModalProps>> = ({ children }) => {
  // A container created to house the modal. It's also our anchor
  // to tell us when this element disappears from the DOM
  const containerRef = useRef<HTMLDivElement>(null);

  // Ensure we don't open the modal multiple times per render
  const isOpenRef = useRef<boolean>(false);

  const { getContextManager } = useMarketContext();

  useEffect(() => {
    const contextManager = getContextManager();
    async function setupModal() {
      if (!contextManager) {
        return;
      }

      if (isOpenRef.current) {
        return;
      }

      isOpenRef.current = true;
      if (containerRef.current?.firstElementChild) {
        contextManager.classList.add(MODAL_Z_INDEX);
        containerRef.current.firstElementChild.classList.add('tw-relative');
        await contextManager.open(containerRef.current.firstElementChild as DialogElement, false);
      }
    }

    setupModal().catch(() => null);

    return () => {
      if (!containerRef.current && contextManager) {
        contextManager
          .close()
          .then(() => setTimeout(() => contextManager.classList.remove(MODAL_Z_INDEX), 200))
          .catch(() => null);
      }
    };
  }, []);

  return (
    <div ref={containerRef} className={styles['in-modal-container']}>
      {children}
    </div>
  );
};
