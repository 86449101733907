import React, { FC } from 'react';
import { IconProps } from './type';

export const MarketIconTrash: FC<React.PropsWithChildren<IconProps>> = ({ height = 24, width = 24, className = '' }) => (
  // @ts-ignore slot attribute is required for Market but isn't part of the React SVG type
  <svg slot="icon" className={className} width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M21.334 5H19.334H17.334H15.334C15.334 3.35 13.984 2 12.334 2C10.684 2 9.33398 3.35 9.33398 5H7.33398H5.33398H3.33398V7H5.33398V18.75C5.33398 20.54 6.99398 22 9.03398 22H15.634C17.674 22 19.334 20.54 19.334 18.75V7H21.334V5ZM12.334 4C12.884 4 13.334 4.45 13.334 5H11.334C11.334 4.45 11.784 4 12.334 4ZM17.334 18.75C17.334 19.43 16.554 20 15.634 20H9.03398C8.11398 20 7.33398 19.43 7.33398 18.75V7H17.334V18.75ZM15.334 9H13.334V18H15.334V9ZM9.33398 9H11.334V18H9.33398V9Z"
      fill="currentColor"
    />
  </svg>
);
