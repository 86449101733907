import React, { FC } from 'react';

type ProgressBarProps = {
  total?: number;
  completed?: number;
};

const generateCompletedNumber = (initialNumber?: number, totalNumber?: number) => {
  if (initialNumber === undefined || totalNumber === undefined) {
    return 0;
  }

  return (initialNumber * 100) / totalNumber;
};

export const ProgressBar: FC<React.PropsWithChildren<ProgressBarProps>> = ({ total, completed }) => {
  const completedNumber = generateCompletedNumber(completed, total);

  return (
    <div>
      <label className="tw-block tw-mb-2 tw-heading-4 tw-font-normal tw-text-cool-gray-400">
        {completed} out of {total} completed
      </label>
      <div className="tw-w-full tw-h-1 tw-relative tw-rounded-full tw-bg-blue-gray-200">
        <div style={{ width: `${completedNumber}%` }} className="tw-h-1 tw-absolute tw-top-0 tw-left-0 tw-transition-all tw-rounded-full tw-bg-green-500"></div>
      </div>
    </div>
  );
};
