import React, { FC, forwardRef } from 'react';
import { useMenuState, Menu as BaseMenu, MenuItem as BaseMenuItem, MenuButton, MenuSeparator, MenuDisclosureProps, MenuProps as RMenuProps } from 'reakit/Menu';
import classNames from 'classnames';
import { MenuContentWrapper, MenuItem } from './MenuComponents';

export type MenuProps = {
  menuProps: Partial<RMenuProps>;
  disclosure: React.ReactElement<Partial<MenuDisclosureProps>> & { ref?: any };
  menuItems: any[];
  initialVisible?: boolean;
  closeMenuOnItemClick?: boolean;
  className?: string;
  ariaLabel?: string;
};

const AbstractMenu = forwardRef<MenuProps, any>(
  ({ className = '', ariaLabel, disclosure, menuItems, menuProps, initialVisible = false, closeMenuOnItemClick = true, ...props }, ref) => {
    const menu = useMenuState({ visible: initialVisible });
    return (
      <>
        <MenuButton ref={ref} {...menu} {...props} {...disclosure.props}>
          {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
        </MenuButton>
        <BaseMenu {...menu} aria-label={ariaLabel} {...menuProps} className={classNames([className, 'tw-absolute'])} as={MenuContentWrapper}>
          {menuItems.map((item: any, i: number) => {
            if (item.type === MenuSeparator) {
              return React.cloneElement(item, {
                ...menu,
                key: item.key || i,
                ...item.props,
              });
            }
            return (
              <BaseMenuItem
                {...menu}
                {...item.props}
                key={item.key || i}
                onClick={() => {
                  if (item.props.onClick) {
                    item.props.onClick();
                  }
                  if (closeMenuOnItemClick) {
                    menu.hide();
                  }
                }}
              >
                {(itemProps) => React.cloneElement(item, itemProps)}
              </BaseMenuItem>
            );
          })}
        </BaseMenu>
      </>
    );
  },
);

// The keyword `any` is used here as a workaround to avoid type errors
export const Menu: FC<React.PropsWithChildren<MenuProps>> & {
  MenuItem: typeof MenuItem;
} = AbstractMenu as any;

Menu.MenuItem = MenuItem;
