import * as React from 'react';
import classNames from 'classnames';

export type PanelTitleWithActionProps = {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  className?: string;
  action?: React.ReactNode | React.ReactNode[];
  hasMargin?: boolean;
  testId?: string;
};

export const PanelTitleWithAction: React.FC<PanelTitleWithActionProps> = ({ title, subtitle, hasMargin = true, action, className, testId }) => (
  <div
    data-testid={testId}
    className={classNames(['tw-flex tw-justify-between tw-gap-1', className, hasMargin && 'tw-mb-4', subtitle ? 'tw-items-start' : 'tw-items-center'])}
  >
    <div className="tw-flex-1">
      <div className="tw-text-blue-gray-900 tw-font-bold tw-text-lg">{title}</div>
      {subtitle && <p className="tw-mt-1 tw-mb-0 tw-w-11/12 tw-text-gray-900">{subtitle}</p>}
    </div>
    <div>{action}</div>
  </div>
);
