import React, { FC } from 'react';

export const IconUserLarge: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M29.75 36.75V33.25C29.75 31.3935 29.0125 29.613 27.6997 28.3003C26.387 26.9875 24.6065 26.25 22.75 26.25H8.75C6.89348 26.25 5.11301 26.9875 3.80025 28.3003C2.4875 29.613 1.75 31.3935 1.75 33.25V36.75"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.75 19.25C19.616 19.25 22.75 16.116 22.75 12.25C22.75 8.38401 19.616 5.25 15.75 5.25C11.884 5.25 8.75 8.38401 8.75 12.25C8.75 16.116 11.884 19.25 15.75 19.25Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.25 36.7498V33.2498C40.2488 31.6988 39.7326 30.1921 38.7824 28.9663C37.8322 27.7405 36.5017 26.865 35 26.4773"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M28 5.47729C29.5057 5.86282 30.8403 6.73852 31.7934 7.96634C32.7464 9.19415 33.2637 10.7042 33.2637 12.2585C33.2637 13.8128 32.7464 15.3229 31.7934 16.5508C30.8403 17.7786 29.5057 18.6543 28 19.0398"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
