import React, { FC } from 'react';

export const IconHide: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_24771_87568)">
        <path
          // eslint-disable-next-line max-len
          d="M6.59984 2.82664C7.05872 2.71923 7.52855 2.66554 7.99984 2.66664C12.6665 2.66664 15.3332 7.99998 15.3332 7.99998C14.9285 8.75705 14.4459 9.4698 13.8932 10.1266M9.41317 9.41331C9.23007 9.60981 9.00927 9.76741 8.76394 9.87672C8.51861 9.98604 8.25377 10.0448 7.98523 10.0496C7.71669 10.0543 7.44995 10.0049 7.20091 9.9043C6.95188 9.80371 6.72565 9.65399 6.53573 9.46408C6.34582 9.27416 6.1961 9.04794 6.09551 8.7989C5.99492 8.54987 5.94552 8.28312 5.95026 8.01458C5.955 7.74604 6.01378 7.48121 6.12309 7.23587C6.2324 6.99054 6.39001 6.76974 6.5865 6.58664M11.9598 11.96C10.8202 12.8286 9.43258 13.3099 7.99984 13.3333C3.33317 13.3333 0.666504 7.99998 0.666504 7.99998C1.49576 6.45457 2.64593 5.10438 4.03984 4.03998L11.9598 11.96Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M0.666504 0.666626L15.3332 15.3333" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_24771_87568">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </svg>
);
