import React, { FC } from 'react';

export const IconLocation: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M5.00016 1.66683C6.28683 1.66683 8.3335 2.60016 8.3335 5.10016C8.3335 6.54016 7.18683 8.2135 5.00016 9.98016C2.8135 8.2135 1.66683 6.5335 1.66683 5.10016C1.66683 2.60016 3.7135 1.66683 5.00016 1.66683ZM5.00016 0.333496C2.82016 0.333496 0.333496 1.9735 0.333496 5.10016C0.333496 7.18016 1.88683 9.3735 5.00016 11.6668C8.1135 9.3735 9.66683 7.18016 9.66683 5.10016C9.66683 1.9735 7.18016 0.333496 5.00016 0.333496Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.00016 3.6665C4.26683 3.6665 3.66683 4.2665 3.66683 4.99984C3.66683 5.73317 4.26683 6.33317 5.00016 6.33317C5.35379 6.33317 5.69292 6.1927 5.94297 5.94265C6.19302 5.6926 6.3335 5.35346 6.3335 4.99984C6.3335 4.64622 6.19302 4.30708 5.94297 4.05703C5.69292 3.80698 5.35379 3.6665 5.00016 3.6665ZM0.333496 12.3332H9.66683V13.6665H0.333496V12.3332Z"
      fill="currentColor"
    />
  </svg>
);
