import React, { FC } from 'react';

export const IconReports: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M3.33333 2H12.6667C13.4 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.6 14 2 13.4 2 12.6667V3.33333C2 2.6 2.6 2 3.33333 2ZM3.33333 12.6667H12.6667V3.33333H3.33333V12.6667ZM11.3333 6.66667H10V11.3333H11.3333V6.66667ZM8.66667 4.66667H7.33333V11.3333H8.66667V4.66667ZM6 8.66667H4.66667V11.3333H6V8.66667Z"
      fill="currentColor"
    />
  </svg>
);
