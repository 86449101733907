import React, { FC } from 'react';

export const IconBack: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.04499 3L3 7.04499L7.04499 11.09" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.22461 7.04492L9.98389 7.12261C11.6552 7.14183 13 8.50215 13 10.1736V10.1736C13 11.8587 11.6339 13.2248 9.94882 13.2248H7.8314"
      stroke="currentColor"
      strokeWidth="1.3"
    />
  </svg>
);
