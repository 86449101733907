import React, { FC } from 'react';

export const IconMenus: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M0.666504 14.6532C0.666504 15.0265 0.966504 15.3265 1.33984 15.3265H9.99984C10.3732 15.3265 10.6732 15.0265 10.6732 14.6532V13.9998H0.666504V14.6532ZM5.6665 5.99317C3.1665 5.99317 0.666504 7.33317 0.666504 9.99984H10.6665C10.6665 7.33317 8.1665 5.99317 5.6665 5.99317ZM2.41317 8.6665C3.15317 7.63317 4.7265 7.3265 5.6665 7.3265C6.6065 7.3265 8.17984 7.63317 8.91984 8.6665H2.41317ZM0.666504 11.3332H10.6665V12.6665H0.666504V11.3332ZM11.9998 3.33317V0.666504H10.6665V3.33317H7.33317L7.4865 4.6665H13.8598L12.9265 13.9998H11.9998V15.3332H13.1465C13.7065 15.3332 14.1665 14.8998 14.2332 14.3532L15.3332 3.33317H11.9998Z"
      fill="currentColor"
    />
  </svg>
);
