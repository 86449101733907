import * as React from 'react';

export const IconPlaceholder: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className = '' }) => (
  <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M18.7778 1H3.22222C1.99492 1 1 1.99492 1 3.22222V18.7778C1 20.0051 1.99492 21 3.22222 21H18.7778C20.0051 21 21 20.0051 21 18.7778V3.22222C21 1.99492 20.0051 1 18.7778 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      // eslint-disable-next-line max-len
      d="M7.11003 8.77779C8.0305 8.77779 8.77669 8.0316 8.77669 7.11112C8.77669 6.19065 8.0305 5.44446 7.11003 5.44446C6.18955 5.44446 5.44336 6.19065 5.44336 7.11112C5.44336 8.0316 6.18955 8.77779 7.11003 8.77779Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.0004 14.3334L15.4449 8.77783L3.22266 21.0001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
