import * as React from 'react';
import classNames from 'classnames';
import { IconCloseSmall } from '../icons/IconCloseSmall';
import { Button } from '../button/Button';
import { DatePickerInput, DatePickerInputProps } from './DatePicker';

export type DateRangeType = {
  startDate?: Date;
  endDate?: Date;
};

export type DateRangeProps = {
  disabled?: boolean;
  startDate?: Date;
  endDate?: Date;
  onChange(range: DateRangeType): void;
  startDateHasError?: boolean;
  endDateHasError?: boolean;
  allowClear?: boolean;
  options?: Partial<DatePickerInputProps>;
};

const TODAY = new Date();

export const DateRangePicker: React.FC<React.PropsWithChildren<DateRangeProps>> = ({
  disabled,
  startDate,
  endDate,
  onChange,
  startDateHasError,
  endDateHasError,
  allowClear,
  options = {},
}) => {
  const { className, ...restOptions } = options;
  const datePickerProps = {
    outline: true,
    startDate,
    ...(restOptions ?? {}),
  };

  const handleDateRangeChange = (fieldName: string) => (fieldValue: Date) => {
    if (fieldName === 'endDate') {
      onChange({
        startDate,
        endDate: fieldValue,
      });
      return;
    }
    const shouldResetEndDate = fieldValue && endDate && fieldValue.getTime() > endDate.getTime();

    onChange({
      startDate: fieldValue,
      endDate: shouldResetEndDate ? undefined : endDate,
    });
  };
  const handleReset = () => onChange({});

  return (
    <div className="tw-flex tw-items-center">
      <DatePickerInput
        isDisabled={disabled}
        selectsStart
        placeholder="Start Date"
        minDate={TODAY}
        className={classNames([className, 'tw-w-48'])}
        {...datePickerProps}
        value={startDate ?? new Date()}
        onChange={handleDateRangeChange('startDate')}
        endDate={endDate}
        hasError={startDateHasError}
      />
      <span className={classNames([disabled && 'tw-opacity-30', 'tw-mx-1.5 tw-block tw-text-blue-gray-900'])}>—</span>
      <DatePickerInput
        isDisabled={disabled}
        selectsEnd
        placeholder="End Date"
        minDate={startDate ?? TODAY}
        className={classNames([className, 'tw-w-48'])}
        {...datePickerProps}
        value={endDate ?? new Date()}
        onChange={handleDateRangeChange('endDate')}
        endDate={endDate}
        hasError={endDateHasError}
      />
      <div className="tw-ml-4 tw-w-6">
        {allowClear && !disabled && (startDate || endDate) && (
          <Button color="secondary" kind="clean" rounded="full" className="tw-w-5 tw-h-5" onClick={handleReset}>
            <IconCloseSmall className="tw-text-blue-gray-600" />
          </Button>
        )}
      </div>
    </div>
  );
};
