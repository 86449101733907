import { range } from 'lodash/fp';
import { StoreDateRangeDto } from '@goparrot/store-v2-sdk';

import { RangeDate } from '../constants';
import { getHour } from './getHour';
import { getMins } from './getMins';

export const getDisabledMinutes = (dataset?: StoreDateRangeDto[], index?: number, field?: string) => () => {
  let result: number[] = [];
  if (!dataset || index === undefined || field === undefined) return result;
  const currItem = dataset[index];
  const prevItem = dataset[index - 1];
  const nextItem = dataset[index + 1];
  const currToHour = getHour(currItem.to);
  const currFromHour = getHour(currItem.from);

  if (RangeDate.FROM === field) {
    if (currFromHour === currToHour) {
      result = result.concat(range(getMins(currItem.to) + 1, 60));
    }
    if (!!prevItem && currFromHour === getHour(prevItem.to)) {
      result = result.concat(range(0, getMins(prevItem.to) + 1));
    }
  }
  if (RangeDate.TO === field) {
    if (currFromHour === currToHour) {
      result = result.concat(range(0, getMins(currItem.from)));
    }
    if (!!nextItem && currToHour === getHour(nextItem.from)) {
      result = result.concat(range(getMins(nextItem.from), 60));
    }
  }
  return result;
};
