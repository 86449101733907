import React, { FC } from 'react';

export const IconToggle: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M11.3337 4H4.66699C2.46033 4 0.666992 5.79333 0.666992 8C0.666992 10.2067 2.46033 12 4.66699 12H11.3337C13.5403 12 15.3337 10.2067 15.3337 8C15.3337 5.79333 13.5403 4 11.3337 4ZM11.3337 10.6667H4.66699C3.19366 10.6667 2.00033 9.47333 2.00033 8C2.00033 6.52667 3.19366 5.33333 4.66699 5.33333H11.3337C12.807 5.33333 14.0003 6.52667 14.0003 8C14.0003 9.47333 12.807 10.6667 11.3337 10.6667ZM11.3337 6C10.227 6 9.33366 6.89333 9.33366 8C9.33366 9.10667 10.227 10 11.3337 10C12.4403 10 13.3337 9.10667 13.3337 8C13.3337 6.89333 12.4403 6 11.3337 6Z"
      fill="currentColor"
    />
  </svg>
);
