// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ab59O3crnq7P3MrlFGQ4{z-index:1001}", "",{"version":3,"sources":["webpack://./modules/shared-ui/src/components/toaster/Toaster.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toaster": "ab59O3crnq7P3MrlFGQ4"
};
module.exports = ___CSS_LOADER_EXPORT___;
