import * as React from 'react';
import { Group } from 'reakit/Group';
import classNames from 'classnames';
import { WeekDayEnum } from '@goparrot/common';
import { Button } from '../button/Button';

type WeekDayPickerProps = {
  value?: WeekDayEnum[];
  disabled?: boolean;
  disabledDays?: WeekDayEnum[];
  showSelectAll?: boolean;
  onChange?(values: WeekDayEnum[]): void;
  getIntlString(path: string): string;
  source?: typeof WeekDayEnum;
};

export const WeekDayPicker: React.FC<React.PropsWithChildren<WeekDayPickerProps>> = ({
  value = [],
  onChange,
  getIntlString,
  disabled,
  disabledDays = [],
  showSelectAll,
  source = WeekDayEnum,
}) => {
  const weekDays = Object.values(source);
  const allWeekSelected = value?.length === 7;

  const handleClick = (day: WeekDayEnum): void => {
    if (value.includes(day)) {
      onChange?.(value.filter((dayItem) => dayItem !== day));
    } else {
      onChange?.([...value, day]);
    }
  };

  const selectAll = () => {
    if (disabled) return;
    const week = allWeekSelected ? [] : weekDays;

    onChange?.(week);
  };
  return (
    <div className="tw-flex tw-items-center tw-space-x-3">
      <Group className="tw-flex tw-space-x-2 tw-select-none">
        {weekDays.map((day) => (
          <Button
            disabled={disabled || disabledDays.includes(day)}
            key={day}
            outline={!value.includes(day)}
            onClick={() => handleClick(day)}
            rounded="full"
            kind="clean"
            className={classNames([
              'tw-w-9 tw-h-9 tw-font-medium',
              disabled && !value.length && 'disabled:tw-bg-gray-100 disabled:tw-text-gray-400 disabled:tw-border-gray-100',
            ])}
          >
            {getIntlString(`week-day.short.${day}`.toLowerCase())}
          </Button>
        ))}
      </Group>
      {!disabled && showSelectAll && (
        <a className="tw-text-light-blue-400" onClick={selectAll}>
          {getIntlString(`week-day.${allWeekSelected ? 'unselect' : 'selectAll'}`)}
        </a>
      )}
    </div>
  );
};
