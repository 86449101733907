import React, { FC } from 'react';

export const IconLoyalty: FC<React.PropsWithChildren<unknown>> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M14.2735 7.72016L8.2735 1.72016C8.0335 1.48016 7.70016 1.3335 7.3335 1.3335H2.66683C1.9335 1.3335 1.3335 1.9335 1.3335 2.66683V7.3335C1.3335 7.70016 1.48016 8.0335 1.72683 8.28016L7.72683 14.2802C7.96683 14.5202 8.30016 14.6668 8.66683 14.6668C9.0335 14.6668 9.36683 14.5202 9.60683 14.2735L14.2735 9.60683C14.5202 9.36683 14.6668 9.0335 14.6668 8.66683C14.6668 8.30016 14.5135 7.96016 14.2735 7.72016ZM8.66683 13.3402L2.66683 7.3335V2.66683H7.3335V2.66016L13.3335 8.66016L8.66683 13.3402Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M4.3335 5.3335C4.88578 5.3335 5.3335 4.88578 5.3335 4.3335C5.3335 3.78121 4.88578 3.3335 4.3335 3.3335C3.78121 3.3335 3.3335 3.78121 3.3335 4.3335C3.3335 4.88578 3.78121 5.3335 4.3335 5.3335Z"
      fill="currentColor"
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.93359 8.36667C5.93359 8.74667 6.08693 9.08 6.33359 9.33333L8.66693 11.6667L11.0003 9.33333C11.2469 9.08667 11.4003 8.74 11.4003 8.36667C11.4003 7.61333 10.7869 7 10.0336 7C9.65359 7 9.31359 7.15333 9.06693 7.4L8.66693 7.8L8.26693 7.40667C8.02026 7.15333 7.67359 7 7.30026 7C6.54693 7 5.93359 7.61333 5.93359 8.36667Z"
      fill="currentColor"
    />
  </svg>
);
