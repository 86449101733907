import * as React from 'react';
import moment, { Moment } from 'moment';
import rangeFn from 'lodash/range';
import classNames from 'classnames';
import { TIME_FORMAT, TimePicker } from '../TimePicker';
import { IconArrowDownSmall, IconCloseSmall } from '../../icons';
import { Button } from '../../button/Button';

const OUTPUT_TIME_FORMAT = 'HH:mm';
const getMoment = (time?: string): Moment | undefined => (time ? moment(time, TIME_FORMAT) : undefined);

export type TimeRangeType = {
  from?: string;
  to?: string;
};

type RestrictionsType = {
  disabledHours(): number[];
  disabledMinutes(hour?: number): number[];
};

export type TimeRangePickerProps = {
  range: Partial<TimeRangeType>;
  format?: string;
  disabled?: boolean;
  hasError?: boolean;
  allowClear?: boolean;
  className?: string;
  inputClassName?: string;
  onTimeRangeChange(range?: Partial<TimeRangeType>): void;
  customRestrictions?: {
    from: RestrictionsType;
    to: RestrictionsType;
  };
};

export const TimeRangePicker: React.FC<React.PropsWithChildren<TimeRangePickerProps>> = ({
  range,
  onTimeRangeChange,
  disabled,
  hasError,
  allowClear,
  customRestrictions,
  className,
  inputClassName,
  format = OUTPUT_TIME_FORMAT,
}) => {
  const [from, to] = React.useMemo(() => [getMoment(range.from), getMoment(range.to)], [range]);

  const restrictions = React.useMemo(() => {
    if (customRestrictions) {
      return customRestrictions;
    }

    const fromHours = from?.get('hours') ?? null;
    const fromMinutes = from?.get('minutes') ?? null;
    const toHours = to?.get('hours') ?? null;
    const toMinutes = to?.get('minutes') ?? null;

    return {
      from: {
        disabledHours: () => (toHours !== null ? rangeFn(toHours + 1, 25) : []),
        disabledMinutes: (hour: number) => (hour === toHours && toMinutes !== null ? rangeFn(toMinutes, 61) : []),
      },
      to: {
        disabledHours: () => (fromHours !== null ? rangeFn(0, fromHours) : []),
        disabledMinutes: (hour: number) => (hour === fromHours && fromMinutes !== null ? rangeFn(0, fromMinutes + 1) : []),
      },
    };
  }, [customRestrictions, from, to]);

  const handleTimeSet = (field: keyof TimeRangeType) => (time: Moment | null): void => {
    onTimeRangeChange({
      ...range,
      [field]: time ? moment(time).format(format) : time,
    });
  };

  const handleReset = () => onTimeRangeChange();

  return (
    <div className={classNames([className, disabled && 'tw-opacity-50', 'tw-flex tw-items-center tw-space-x-4'])}>
      <div className="tw-flex tw-items-center">
        <TimePicker
          {...restrictions.from}
          isDisabled={disabled}
          hasError={hasError}
          value={from}
          placeholder="From"
          className={inputClassName}
          onChange={handleTimeSet('from')}
          suffixIcon={<IconArrowDownSmall className={classNames([disabled ? 'tw-text-cool-gray-400' : 'tw-text-blue-gray-900', 'tw-mt-px'])} />}
        />
        <span className={classNames([disabled && 'tw-opacity-30', 'tw-mx-1.5 tw-block tw-text-blue-gray-900'])}>—</span>
        <TimePicker
          {...restrictions.to}
          isDisabled={disabled}
          hasError={hasError}
          value={to}
          placeholder="To"
          className={inputClassName}
          onChange={handleTimeSet('to')}
          suffixIcon={<IconArrowDownSmall className={classNames([disabled ? 'tw-text-cool-gray-400' : 'tw-text-blue-gray-900', 'tw-mt-px'])} />}
        />
      </div>
      {allowClear && !disabled && (!!from || !!to) && (
        <Button color="secondary" kind="clean" rounded="full" className="tw-w-5 tw-h-5" onClick={handleReset}>
          <IconCloseSmall className="tw-text-blue-gray-600" />
        </Button>
      )}
    </div>
  );
};
